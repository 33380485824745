import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Flex, HelpIconCcqIcon, Text } from "@pancakeswap/uikit";
import { CustomTooltip } from "components/CustomTooltip";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import React, { useMemo, useState } from "react";
import { GetDataListOrder, UseCoreListOrder } from "state/Order";
import styled from "styled-components";
import { renderToken } from "utils/renderAddress";
import { CustomPaginate } from "views/MarketPlace/components";
import { PAYMENT } from "config";
import Row from "./components/Row";

interface Props {
    windowSize: number;
}
const pageSize = 7;
const TabsOrder: React.FC<React.PropsWithChildren<Props>> = ({ windowSize }) => {
    const { t } = useTranslation()
    const { chainId, account } = useActiveWeb3React();
    const [itemOffset, setItemOffset] = useState(0);
    // order
    const token = renderToken(chainId);
    UseCoreListOrder(token?.address, account, chainId);
    const { listAllowanc, listItemOrder, isLoading, balance } = GetDataListOrder();



    const listOrder = useMemo(() => {
        return [...listItemOrder];
    }, [listItemOrder])

    const sortList = [...listOrder].sort((a, b) => b?.dateTrading - a?.dateTrading);

    const total = sortList.length;

    return (
        <Flex width="100%" flexDirection="column" alignItems="flex-end">
            {windowSize > 1000 ? (
                <>
                    <WrapperTable>
                        <WrapperTableHeader>
                            <CsItemSmall>{t("Command type")}</CsItemSmall>
                            <CsItemSmall>{t("Transaction date")}</CsItemSmall>
                            <CsItemSmall>
                                <Text mr='0.2rem'>{t("Period received")}</Text>
                                <CustomTooltip title={t("Period received/total period")}>
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </CustomTooltip>
                            </CsItemSmall>
                            <CsItemSmall>
                                <Text mr='0.2rem'>{t("Quantity")}</Text>
                                <CustomTooltip title={t("Quantity transactions")}>
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </CustomTooltip>
                            </CsItemSmall>
                            <CsItemSmall  >
                                <Text mr='0.2rem'>{t("Total value")}</Text>
                                <CustomTooltip title={`${t("Total transaction value")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </CustomTooltip>
                            </CsItemSmall>
                            <CsItem>{t("Status")}</CsItem>
                            <CsItemSmall  >
                                <Text mr='0.2rem'>{t("Address")}</Text>
                                <CustomTooltip title={t("Transaction wallet address")}>
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </CustomTooltip>
                            </CsItemSmall>
                            <CsItemSmall>{t("Action")}</CsItemSmall>
                        </WrapperTableHeader>
                        <WrapperTableContent>
                            {isLoading ?
                                <Flex width="100%" justifyContent="center" mt="3rem">
                                    <AutoRenewIcon spin color="textSubtle" />
                                </Flex>
                                :
                                <>
                                    {sortList.length > 0 ? (
                                        <>
                                            {sortList.splice(itemOffset, pageSize).map((item, key) => {
                                                return (
                                                    <Row
                                                        item={item}
                                                        account={account}
                                                        chainId={chainId}
                                                        windowSize={windowSize}
                                                        indexRow={key}
                                                        listAllowance={listAllowanc}
                                                        balance={balance}
                                                        tokenAddress={token?.address}
                                                    />
                                                );
                                            })}
                                        </>
                                    ) : (
                                        <>
                                            <Text width="100%" textAlign="center" mt="2rem">
                                                {t("No data")}
                                            </Text>
                                        </>
                                    )}
                                </>
                            }

                        </WrapperTableContent>
                    </WrapperTable>
                </>
            ) : (
                <WrapperTableMobile>
                    {
                        isLoading ?
                            <Flex width="100%" justifyContent="center" mt="3rem">
                                <AutoRenewIcon spin color="textSubtle" />
                            </Flex>
                            :
                            <>
                                {sortList.length > 0 ? (
                                    <>
                                        {sortList.splice(itemOffset, pageSize).map((item, key) => {
                                            return (
                                                <Row
                                                    item={item}
                                                    account={account}
                                                    chainId={chainId}
                                                    windowSize={windowSize}
                                                    indexRow={key}
                                                    listAllowance={listAllowanc}
                                                    balance={balance}
                                                    tokenAddress={token?.address}
                                                />
                                            )
                                        })}
                                    </>
                                ) : (
                                    <>
                                        <Text width="100%" textAlign="center" mt="2rem">
                                            {t("No data")}
                                        </Text>
                                    </>
                                )}
                            </>
                    }

                </WrapperTableMobile>
            )}
            <CustomFlex>
                {sortList.length !== 0 &&
                    <CustomPaginate
                        total={total}
                        pageSize={pageSize}
                        setItemOffset={setItemOffset}
                    />
                }
            </CustomFlex>
        </Flex>
    );
};

const WrapperTable = styled.div`
    width: 100%;
`;
const WrapperTableMobile = styled(Flex)`
    width: 100%;
    padding-bottom: 20px;
    flex-direction: column;
`;

const WrapperTableHeader = styled.div`
    position: relative;
    border-radius: 20px;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(8, minmax(0, 1fr));
    margin-top: 20px;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
const WrapperTableContent = styled(Flex)`
    flex-direction: column;
`;
const CustomFlex = styled(Flex)`
    margin-bottom: 1.5rem;
    width: 100%;
    justify-content: center;
    margin-top:1rem;
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        * {
            list-style-type: none;
        }
    }
    .page-item {
        margin: 0 0.375rem;
        &:first-child,
        &:last-child {
            a {
                &:hover {
                    color: #13486b;
                    border: none !important;
                }
            }
        }
        &:first-child,
        &:last-child {
            a {
                border: none;
                background: none !important;
            }
        }
        &:last-child.disabled,
        &:first-child.disabled {
            cursor: not-allowed;
        }
    }
    .page-link {
        width: 32px;
        height: 32px;
        display: flex;
        background: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;
        color: #02111b;
        border: 1px solid #efefef;
        @media screen and (max-width: 768px) {
            padding: 12px;
        }
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background: #13486b;
            color: #fff;
            border: 1px solid #13486b !important;
        }
    }
    .page-item.disabled .page-link {
        background: ${({ theme }) => theme.colors.disabled};
        cursor: not-allowed !important;
        opacity: 0.7;
        pointer-events: none;
    }
    .page-item.active .page-link {
        background: #13486b;
        color: #fff;
        border: none;
    }
`;

const CsItem = styled(Flex)`
    width: 20%;
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 21px;
    color:${({ theme }) => theme.colors.textSubtle};
    font-weight:bold;
    font-size: 14px;
    white-space: nowrap;
    ${Text}{
        font-size: 14px;
        white-space: nowrap;
    }
`
const CsItemSmall = styled(Flex)`
    color:${({ theme }) => theme.colors.textSubtle};
    font-weight:bold;
    font-size: 14px;
    display: flex;
    white-space: nowrap;
    align-items: center;
    ${Text}{
        font-size: 14px;
        white-space: nowrap;
    }
`
const WrapperTotal = styled(Flex)`
    justify-content: flex-end;
    align-items: center;
    margin-top: 60px;
    width: 50%;
    @media screen and (max-width: 1200px){
        ${Text}{
            font-size: 22px;
        }
    }
    @media screen and (max-width: 1000px){
        margin-top: 30px;
        margin-bottom: 20px;
        ${Text}{
            font-size: 18px;
        }
    }
    @media screen and (max-width: 767px){
        width: 100%;
        margin-top: 30px;
        margin-bottom: 20px;
        ${Text}{
            font-size: 14px;
        }
    }
    h6 {
        font-size: 1.25rem;
        color: #8c8d8e;
        font-weight: 500;
    }
    p {
        color: #014235;
        font-size: 1.75rem;
        font-weight: 500;
        span {
            color: #02111b;
            font-weight: 600;
        }
    }
    ${Flex} {
        justify-content: space-between;
    }
`;

export default TabsOrder;
