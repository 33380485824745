import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, HelpIconCcqIcon2, IconSearchNft, NoticeIcon, Text, useTooltip } from "@pancakeswap/uikit";
import { CustomTooltip } from "components/CustomTooltip";
import { PAYMENT, UNIT_DATE, formatNumberPayment } from "config";
import { useModal } from "hooks/use-modal";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";
import { CsItemWarning } from "views/MarketPlace/style";

interface optionsProps {
    handleChoose: (id: number, addresses: string, buys) => void;
    addresses?: string
}
type HandleProps = Items & optionsProps;

const ItemMobile: React.FC<HandleProps> = ({
    amount,
    dayDiff,
    id,
    unitPrice,
    handleChoose,
    price,
    interVestTerm,
    interVestTermRate,
    vestingMap,
    paused,
    addresses,
    buys,
    isBlacklists,
    nftAddress
}) => {
    const { t } = useTranslation();
    const { ModalTypeEnum, toggleModal } = useModal();

    const totalVested = useMemo(() => {
        return vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [vestingMap])
    const totalVestingMap = useMemo(() => {
        return vestingMap?.length
    }, [vestingMap])

    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        <CsTextTootip style={{ color: "red" }}>{t("Go Global Franchise Fund is temporarily locked, please come back later!")}</CsTextTootip>,
        { placement: "top-end", tooltipOffset: [20, 10] }
    );

    return (
        <WrapperBuyItem>
            <CsFlex>
                <CsItem>
                    <CsFlexHeader>
                        <CustomTooltip title={t("Quantity")}>
                            <Flex style={{ cursor: 'pointer' }}>
                                <Flex color="#C7C9CA" mr="4px">
                                    {t("Quantity")}
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </CustomTooltip>
                        <CsText mr='4px' ml="2px" className="relative">
                            {formatNumberPayment(amount)}
                        </CsText>

                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <CustomTooltip title={t("Yield payment period")}>
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    {t("Payment period")}
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </CustomTooltip>
                        <CsTextMonth>
                            {interVestTerm} {interVestTerm > 1 ? t("months") : t("month")}
                        </CsTextMonth>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <CustomTooltip title={t("Remaining day yield")}>
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    {t("Remaining yield")}
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </CustomTooltip>
                        <CsText mr='4px' ml="2px">
                            {interVestTermRate}%
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <CustomTooltip title={`${t("Unit price")} (${PAYMENT})`}>
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    {t("Unit price")}
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </CustomTooltip>
                        <CsText mr='4px' ml="2px">
                            {formatNumberPayment(unitPrice)}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItemWarning>
                    {paused &&
                        <>
                            <ReferenceElement ref={targetRef}>
                                <NoticeIcon />
                            </ReferenceElement>
                            <>{tooltipVisible && tooltip}</>
                        </>
                    }
                </CsItemWarning>
            </CsFlex>
            <CsFlex>
                <CsItem>
                    <CsFlexHeader>
                        <CustomTooltip title={t("Face value")}>
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    {t("Face value")}
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </CustomTooltip>
                        <CsText>
                            {formatNumberPayment(price)}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <CustomTooltip title={t("Remaining days")}>
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    {t("Remaining day")}
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </CustomTooltip>
                        <CsText>
                            {`${dayDiff} ${t(UNIT_DATE)}`}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CsFlexHeader>
                        <CustomTooltip title={t("Period received")}>
                            <Flex>
                                <Flex color="#C7C9CA" mr="4px">
                                    {t("Period received")}
                                </Flex>
                                <HelpIconCcqIcon2 />
                            </Flex>
                        </CustomTooltip>
                        <CsText mr='2px'>
                            {totalVested}/{totalVestingMap}
                        </CsText>
                    </CsFlexHeader>
                </CsItem>
                <CsItem justifyContent="center">
                    <CustomTooltip
                        color="Red"
                        title={isBlacklists ? t("Feature locked, please contact admin!") : ""}
                        trigger={["hover"]}
                    >
                        <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                            <CsButton disabled={paused || isBlacklists} onClick={() => handleChoose(id, addresses, buys)}>{t("Select")}</CsButton>
                        </Flex>
                    </CustomTooltip>
                </CsItem>
            </CsFlex>
            <p
                aria-hidden="true"
                className="cursor-pointer absolute bottom-1 left-2"
                onClick={() => {
                    toggleModal({
                        type: ModalTypeEnum.MODAL_SEARCH_CONTRACT,
                        title: "",
                        data: {
                            contract: nftAddress.toLowerCase()
                        }
                    })
                }}
            >
                <IconSearchNft className="w-3 h-3" />
            </p>
        </WrapperBuyItem>
    );
};

export default ItemMobile;

const CsTextMonth = styled(Text)`
    font-size: 10px;
    font-weight: 600;
    border-radius: 4px;
    letter-spacing: 0.04em;
    text-align: center;
`;
const CsFlex = styled(Flex)`
    width: 100%;
    padding: 6px 0;
    align-items: center;
    justify-content: space-between;
`;
const CsText = styled(Text)`
    font-size: 11px;
    font-weight: 600;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    &:first-child {
        text-align: left;
    }
`;

const CsItem = styled(Flex)`
    /* width: 25%; */
    height: 42px;
    align-items: center;
    align-items: flex-start;
    font-size:12px;
`
const CsButton = styled(Button)`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};

    border-radius: 4px;
    min-width: 90px;
    height: 32px;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
    @media screen and (max-width: 475px) {
        min-width: 75px;
    }

    &:hover {
        border: 1px solid transparent;
        background: "#447290";
        /* color: ${({ theme }) => theme.colors.white}; */
    }
`;

const WrapperBuyItem = styled.div`
    position: relative;
    padding: 8px 16px;
    overflow: hidden;
    background: ${({ theme }) => theme.colors.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.textDisabled};
`;

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    gap: 2px;
`
const CsTextTootip = styled(Text)`
    font-size: 14px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;
const ReferenceElement = styled.div`
    display: inline-block;
    cursor: pointer;
`;