/* eslint-disable react-hooks/rules-of-hooks */
import { useTranslation } from "@pancakeswap/localization"
import { Text } from "@pancakeswap/uikit"
import styled from "styled-components"


export const renderProfitPercent = (holdingDate: number) => {
    if (holdingDate < 15) {
        return "6.0%"
    } if (holdingDate >= 15 && holdingDate < 30) {
        return "7.0%"
    } if (holdingDate >= 30 && holdingDate < 92) {
        return "8.0%"
    } if (holdingDate >= 92 && holdingDate < 184) {
        return "9.2%"
    }
    return ""
}

export const renderType = (isSeller: boolean, eventType) => {
    const { currentLanguage, setLanguage, t } = useTranslation()
    if (!isSeller) {
        return <CsText color="success3">{t("Buy")}</CsText>
    }
    if (isSeller) {
        if (eventType === "_eventSellNow")
            return <CsText color="red">{t("Sell Now")}</CsText>
        return <CsText color="red">{t("Sell Deal")}</CsText>
    }
    return null
}

export const renderStatus = (status: string) => {
    const { currentLanguage, setLanguage, t } = useTranslation()
    if (status?.toLowerCase() === "success") {
        return <CsText color="success3">{t("Success")}</CsText>
    }
    if (status?.toLowerCase() === "cancel") {
        return <CsText color="red">{t("Cancelled")}</CsText>
    }
    return null
}

const CsText = styled(Text)`
    font-weight: 600;
    font-size: 16px;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
    }
`
export const renderAddressByType = (isSeller: boolean, buyer: string, seller: string) => {
    if (isSeller) {
        return seller
    }
    if (!isSeller) {
        return buyer
    }
    return null
}

