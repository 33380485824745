

import { Flex, Text } from "@pancakeswap/uikit";
import styled from "styled-components";

export const CsItem = styled(Flex)`
    width: 16%;
    height: 100%;
    gap:4px;
    align-items: center;
    color: ${({ theme }) => theme.colors.text} !important;;
    justify-content: flex-start;
    white-space: nowrap;
    text-align: center;
    ${Text}{
        line-height:21px;
        font-size: 13px;
        white-space: nowrap;
    }
`
export const CsItemWarning = styled(Flex)`
    /* width: 4%; */
    height: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
`

export const CsItemSmall = styled(Flex)`
    align-items: center;
    color: ${({ theme }) => theme.colors.text} !important;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    width: 100%;
    position: relative;
`