import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Box, Flex, HelpIconCcqIcon, HelpIconCcqIcon2, OpenNewIconFundgo, Skeleton, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import BigNumber from "bignumber.js";
import { CustomTooltip } from "components/CustomTooltip";
import { PAYMENT, UNIT_DATE, formatNumberPayment } from "config";
import React, { useMemo } from "react";
import { AllowanceItem, ItemWithVestingMap } from "state/Order/type";
import styled from "styled-components";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import { useDownload } from "../../TabsAsset/hook/useDownload";
import { GetNftInfo } from "../hook/fetchData";
import { useApprove } from "../hook/useApprove";
import { useBuyP2P } from "../hook/useBuyP2P";
import { useDelistp2p } from "../hook/useDelist";
import { ItemTransaction } from "./ItemTransaction";
import {
    Container,
    CsButtonCancel,
    CsButtonConfirm,
    CsFlexAgree,
    CsFlexHeader,
    CsLink,
    CsModal,
    CsMonth,
    CsTitleModal,
    CustomLink,
    WrapItemMobile,
    WrapQuantity,
    WrapperButton,
    WrapperCard,
    WrapperItemHeader
} from "./styles";

interface ModalProps {
    title: React.ReactNode;
    hideCloseButton?: boolean;
    onBack?: () => void;
    onDismiss?: () => void;
    bodyPadding?: string;
    headerBackground?: string;
    listOrder?: any;
    sAccount?: (seller) => string;
    handleOpenLink?: (seller) => void;
    item: ItemWithVestingMap,
    account: string,
    chainId: number,
    itemAllowance: AllowanceItem,
    balance: string,
    tokenAddress: string
}

const ConfirmModalPC: React.FC<React.PropsWithChildren<ModalProps>> = ({
    handleOpenLink,
    sAccount,
    title,
    onDismiss,
    item,
    account,
    chainId,
    itemAllowance,
    balance,
    tokenAddress,
    ...props
}) => {
    const { t, currentLanguage } = useTranslation();
    const windowSize = GetWindowSize();
    const { nftInfo, isLoading } = GetNftInfo(item?.nftAddress, item?.addressTrading, item?.nftId, account, chainId)
    const isNotBalance = new BigNumber(balance).isLessThan(item?.amountTrading)
    const isNotAllowance = new BigNumber(itemAllowance?.allowance).isLessThan(item?.amountTrading)
    // for download
    const raw = JSON.stringify({
        lang: currentLanguage?.code,
        "buyerWalletAddress": account,
        "sellerWalletAddress": item?.addressTrading,
        "pendingEtf": [
            {
                "numericalOrder": "1",
                "etfId": `${item?.nftId}`,
                "parValue": `${formatNumberPayment(nftInfo?.price)} ${PAYMENT}`,
                "interest": `${Number(nftInfo?.profit) * 100}%`,
                "incomingInterestDate": convertDate(nftInfo?.nextInterestPaymentDate * 1000),
                "releaseDate": convertDate(Number(nftInfo?.issueDate) * 1000),
                "maturityDate": convertDate(Number(nftInfo?.expireDate) * 1000),
                "transferValue": `${formatNumberPayment(item?.price)} ${PAYMENT}`
            }
        ],
        "transferedEtf": [
            {
                "numericalOrder": "1",
                "etfId": `${item?.nftId}`,
                "transferDate": convertDate(Date.now())
            }
        ],
        "transferDate": convertDate(Date.now()),
        "totalTransferValue": `${formatNumberPayment(item?.price)} ${PAYMENT}`
    });
    const { penddingDowload, handleDownload } = useDownload(raw)
    // for reject-confirm
    const { handleDelistp2p, pendingDelistp2p } = useDelistp2p(account, chainId, item?.nftAddress, item?.buys, onDismiss)
    const { handleBuyP2P, pendingBuyP2P } = useBuyP2P(account, chainId, item?.nftAddress, item?.buys, onDismiss)
    const { handleApprove, pendingTx } = useApprove(item?.nftAddress, tokenAddress, account, chainId)

    const totalVested = useMemo(() => {
        return item?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.vestingMap])
    const totalVestingMap = useMemo(() => {
        return item?.vestingMap?.length
    }, [item?.vestingMap?.length])


    return (
        <CsModal title={title} onDismiss={onDismiss} {...props}>
            <Flex width="100%" flexDirection="column" alignItems="center" mt="1rem">
                <CsTitleModal color="text" fontSize="28px" fontWeight="700">
                    {t("Transaction confirmation")}
                </CsTitleModal>
            </Flex>
            <Box>
                {windowSize > 1000 ? (
                    <>
                        <WrapperItemHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Face value")}</Text>
                                <CustomTooltip title={`${t("Face value")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Yield")}</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Period received")}</Text>
                                <CustomTooltip title={t("Period received/total period")}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Payment period")}</Text>
                                <CustomTooltip title={t("Yield payment period")}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Payment date")}</Text>
                                <CustomTooltip title={t("Next yield payment date")}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Remaining days")}</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Value")}</Text>
                                <CustomTooltip title={`${t("Value")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                        </WrapperItemHeader>
                        <Container>
                            <WrapperCard>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        formatNumberPayment(nftInfo?.price)
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${formatNumberPayment(nftInfo?.profit)}%`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {totalVested}/{totalVestingMap}
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${nftInfo?.intervestTerm} ${nftInfo?.intervestTerm > 1 ? t("months") : t("month")}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${convertDate(nftInfo?.nextInterestPaymentDate * 1000)}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)} ${t(UNIT_DATE)}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        <>
                                            {new BigNumber(item?.price).isNaN() ?
                                                `0`
                                                :
                                                formatNumberPayment(item?.price)
                                            }
                                        </>
                                    }
                                </Text>
                            </WrapperCard>
                        </Container>
                        <ItemTransaction
                            titleOrderQuantity={t("Quantity transactions")}
                            valueOrderQuantity={item?.amountTrading?.toString()}
                            valueTransactionMethod={t("Buy Deal")}
                            dateTransaction={convertDate(item?.dateTrading * 1000)}
                            addressBuy={item?.addressTrading}
                            totalMoney={Number(item?.price)}
                            utiOrderQuantity={t("Fund certificates")}
                            textAlert={t("Seller wallet address")}
                        />
                    </>
                ) : (
                    <>
                        <WrapItemMobile>
                            <WrapQuantity>
                                <Flex
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                                        gap: "8px"
                                    }}
                                >
                                    <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <CustomTooltip title={`${t("Face value")} (${PAYMENT})`}>
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        formatNumberPayment(nftInfo?.price)
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <CustomTooltip title={t("Period received/total period")}>
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {totalVested}/{totalVestingMap}
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                    {/* <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <Text color="textDisabled">{t(Quantity)}: {' '}</Text>
                                        <CustomTooltip title={t("Number of transactions")}>
                                            <Flex style={{cursor: 'pointer', gap: '4px'}}>
                                                <Text ml="4px">
                                                { isLoading ?
                                                    `0`
                                                :
                                                    `${item?.amountTrading}`
                                                }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb> */}
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <CustomTooltip title={t("Yield")}>
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        `${nftInfo?.profit}%`
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                    {/* <CsFlexHeaderMb width="100%" justifyContent="flex-end">
                                        <CustomTooltip title={t("Value (VNDC)")}>
                                            <Flex style={{cursor: 'pointer', gap: '4px'}}>
                                                <Text>
                                                    { isLoading ?
                                                        <Skeleton width="42px"/>
                                                    :
                                                        Number(item?.price).toLocaleString("en", {
                                                            minimumFractionDigits: 0,
                                                            maximumFractionDigits: 0
                                                        })
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb> */}
                                    {/* <Text style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px" }}>
                                        { isLoading ?
                                            <Skeleton width="42px"/>
                                        :
                                            Number(item?.price).toLocaleString("en", {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                            })
                                        }
                                    </Text> */}
                                </Flex>
                                <Flex
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
                                        gap: "8px",
                                        marginTop: "8px"
                                    }}
                                >
                                    <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <CustomTooltip title={t("Yield payment period")}>
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <CsMonth>
                                                    <span>
                                                        {isLoading ?
                                                            <Skeleton width="42px" />
                                                            :
                                                            `${nftInfo?.intervestTerm} ${nftInfo?.intervestTerm > 1 ? t("months") : t("month")}`
                                                        }
                                                    </span>
                                                </CsMonth>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <CustomTooltip title={t("Remaining days")}>
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                {isLoading ?
                                                    <Skeleton width="42px" />
                                                    :
                                                    <Text bold>{getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)} {t(UNIT_DATE)}</Text>
                                                }
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <CustomTooltip title={t("Next yield payment date")}>
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text>
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        `${convertDate(nftInfo?.nextInterestPaymentDate * 1000)}`
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                </Flex>
                            </WrapQuantity>
                            <Text style={{ color: "#02111b", fontWeight: 700, fontSize: "0.875rem" }}>
                                {t("Fund Certificate Information")}
                            </Text>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1rem">
                                <Text color="textSubtle">{t("Transaction date")}</Text>
                                <Text bold>{convertDate(item?.dateTrading * 1000)}</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">{t("Seller wallet address")}</Text>
                                <CustomLink
                                    style={{ textAlign: "right" }}
                                    onClick={() => handleOpenLink(item?.addressTrading)}
                                >
                                    <Text style={{ color: "#02111b", fontWeight: "bold" }}>
                                        {sAccount(item?.addressTrading)}
                                    </Text>
                                    <OpenNewIconFundgo />
                                </CustomLink>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">{t("Quantity transactions")}</Text>
                                <Text>{item?.amountTrading}</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">{t("Sell methods")} </Text>
                                <Text>{t("Sell Deal")}</Text>
                            </Flex>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" mt="1.313rem">
                                <Text color="textSubtle">{t("Total agreement amount")}</Text>
                                <Flex style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Text mb='4px'>
                                            {isLoading ?
                                                <Skeleton width="42px" />
                                                :
                                                formatNumberPayment(item?.price)
                                            }
                                            <span style={{ marginLeft: "5px" }}>{PAYMENT}</span>
                                        </Text>
                                        {/* {!isLoading && Number(item?.price) !== 0 && <CsUnit style={{ color: "#8C8D8E" }}> */}
                                        {/*     {` (${t("Equivalent")} ${Number(item?.price).toLocaleString("en", { */}
                                        {/*         minimumFractionDigits: 0, */}
                                        {/*         maximumFractionDigits: 0 */}
                                        {/*     })} ${t("point")} VNDC)`} */}
                                        {/* </CsUnit>} */}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </WrapItemMobile>
                    </>
                )}
            </Box>
            <WrapperButton>
                {
                    !item.isBuyP2P &&
                    <Flex
                        style={{
                            textAlign: "center",
                            color: "red",
                            fontSize: "13px",
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                        }}
                    >{t("Fund Certificate's yield period is over, can't make trades, please confirm cancellation!")}</Flex>
                }
                <Flex width="100%" justifyContent="center" style={{ gap: "1.5rem" }}>
                    {isNotAllowance ?
                        <CsButtonConfirm
                            onClick={handleApprove}
                            disabled={pendingTx || isNotBalance || pendingDelistp2p || pendingBuyP2P || item?.paused || !item.isBuyP2P}
                            endIcon={pendingTx ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                        >
                            {t("Approve")}
                        </CsButtonConfirm>
                        :
                        <CsButtonConfirm
                            disabled={pendingBuyP2P || isNotBalance || pendingDelistp2p || item?.paused || !item.isBuyP2P}
                            onClick={handleBuyP2P}
                            endIcon={pendingBuyP2P ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                        >
                            {t("Confirm")}
                        </CsButtonConfirm>
                    }
                    <CsButtonCancel
                        disabled={pendingDelistp2p || pendingBuyP2P || item?.paused}
                        onClick={handleDelistp2p}
                        endIcon={pendingDelistp2p ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                    >
                        {t("Cancellation confirmation")}
                    </CsButtonCancel>
                </Flex>
            </WrapperButton>
            {isNotBalance &&
                <Flex width='100%' justifyContent="center" mt="10px">
                    <Text color="primaryBright">{t("%payment% balance is not enough", { payment: PAYMENT })}</Text>
                </Flex>
            }
            {item?.paused &&
                <Flex width="100%" justifyContent="center" mt={["1rem", , , "1rem"]}>
                    <Text fontSize={["12px", , , "14px"]} color="red">{t("Go Global Franchise Fund is temporarily locked, please come back later!")}</Text>
                </Flex>
            }
            {
                item.isBuyP2P &&
                <CsFlexAgree>
                    <Text>{t("By “Confirm” I agree to")}</Text>
                    <CsLink disabled={penddingDowload} onClick={handleDownload}>{t("Fund Certificate Transfer Contract")}</CsLink>
                </CsFlexAgree>
            }
        </CsModal>
    );
};

const CsFlexHeaderMb = styled(Flex)`
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
`
const CsUnit = styled.span`
    font-size: 12px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`
export default ConfirmModalPC;
