import { MaxUint256 } from "@ethersproject/constants";
import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { ToastDescriptionWithTx } from "components/Toast";
import { UseActiveCCQ } from "config/constants/ccqSupport/constants";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useERC20 } from "hooks/useContract";
import { useUserRejected } from "hooks/useUserRejected";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionDispatchMessage, getAllowance } from "state/Order/actions";
import { fetchDataUser } from "state/Order/fetchData";

export const useApprove = (contractAddress: string, tokenAddress: string, account: string, chainId: number) => {
    const [requestedApproval, setRequestedApproval] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const { t } = useTranslation();
    const address = useERC20(tokenAddress);
    const [pendingTx, setPendingTx] = useState(false);
    const { handleRejected } = useUserRejected();
    const dispatch = useDispatch<AppDispatch>();

    const handleApprove = useCallback(async () => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(address, "approve", [contractAddress, MaxUint256]);
            const receipt = await tx.wait();

            if (receipt.status) {
                toastSuccess(t("Hợp đồng đã kích hoạt"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestedApproval(true);
                setPendingTx(false);
                dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
            } else {
                // user rejected tx or didn't go thru
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
                setPendingTx(false);
            }
        } catch (e) {
            console.error(e);
            handleRejected(e);
            setPendingTx(false);
        } finally {
            try {
                const activeCCQ = await UseActiveCCQ(chainId)
                const resultAllowance = await fetchDataUser(tokenAddress, activeCCQ, account, chainId)
                dispatch(getAllowance(resultAllowance))
            } catch (e) {
                console.log(e);
            }
        }
    }, [account, address, callWithMarketGasPrice, chainId, contractAddress, dispatch, handleRejected, t, toastError, toastSuccess, tokenAddress]);

    return { handleApprove, requestedApproval, pendingTx };
};
