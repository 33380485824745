import { MaxUint256 } from "@ethersproject/constants";
import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useERC20 } from "hooks/useContract";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { fetchDataUserAction } from "state/MarketPlace/actions";
import { fetchDataAllowance } from "state/MarketPlace/fetchData";

import { ToastDescriptionWithTx } from "components/Toast";
import { useUserRejected } from "hooks/useUserRejected";

export const useApprove = (contractAddress, tokenAddress, account, chainId) => {
    const [requestedApproval, setRequestedApproval] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const { t } = useTranslation();
    const address = useERC20(tokenAddress);
    const [pendingTx, setPendingTx] = useState(false);
    const dispatch = useDispatch<AppDispatch>();
    const { handleRejected } = useUserRejected();
    const handleApprove = useCallback(async () => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(address, "approve", [contractAddress, MaxUint256]);
            const receipt = await tx.wait();

            if (receipt.status) {
                toastSuccess(t("Contract activated"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestedApproval(true);
                setPendingTx(false);
            } else {
                // user rejected tx or didn't go thru
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
                setRequestedApproval(false);
                setPendingTx(false);
            }
        } catch (e) {
            console.error(e);
            handleRejected(e)
            setPendingTx(false);
        } finally {
            try {
                const dataAllowance = await fetchDataAllowance(contractAddress, tokenAddress, account, chainId);
                dispatch(fetchDataUserAction({ dataUser: dataAllowance }));
            } catch (e) {
                console.log(e);
            }
        }
    }, [
        callWithMarketGasPrice,
        address,
        contractAddress,
        toastSuccess,
        t,
        toastError,
        tokenAddress,
        account,
        chainId,
        dispatch
    ]);

    return { handleApprove, requestedApproval, pendingTx };
};
