import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { ToastDescriptionWithTx } from "components/Toast";
import { PAYMENT, PAYMENT_TOKEN } from "config";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useWrapped } from "hooks/useContract";
import { useUserRejected } from "hooks/useUserRejected";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionDispatchAssetMessage } from "state/asset/actions";
import { BuysType } from "state/asset/type";

interface BuysProps {
    nftBalance: number,
    nftId: number
}

export const useListp2p = (
    nftAddress: string,
    buyer: string,
    buys: BuysType[],
    amount: number,
    price: string,
    account: string,
    chainId: number,
    onDismiss: any
) => {
    const { t } = useTranslation();
    const [requestedListp2p, setRequestListp2p] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const [pendingListp2p, setPenddingListp2p] = useState(false);
    const contractWrapped = useWrapped(nftAddress);
    const dispatch = useDispatch<AppDispatch>();
    const { handleRejected } = useUserRejected();

    const handleListp2p = useCallback(async () => {
        setPenddingListp2p(true);
        try {
            const buysSort = buys.sort((a, b) => a.nftBalance - b.nftBalance);
            const newData: BuysProps[] = [...buysSort];
            let amountBuy = amount;
            const resultFilter: BuysProps[] = [];
            for (let i = 0; i < newData.length && amountBuy > 0; i++) {
                const element = newData[i];
                const quantityToBuy = Math.min(amountBuy, element.nftBalance);
                resultFilter.push({
                    nftId: element.nftId,
                    nftBalance: quantityToBuy
                });
                newData[i].nftBalance -= quantityToBuy;
                amountBuy -= quantityToBuy;
            }
            const tokenIds = resultFilter.map(item => (item.nftId));
            const amounts = resultFilter.map(item => (item.nftBalance));
            const priceWei = PAYMENT === PAYMENT_TOKEN.VND ? price : new BigNumber(price).multipliedBy(1E18).toString()
            const tx = await callWithMarketGasPrice(contractWrapped, "listP2P", [buyer, tokenIds, amounts, priceWei]);
            const receipt = await tx.wait();
            if (receipt.status) {
                dispatch(actionDispatchAssetMessage({ messageHash: receipt.transactionHash }));
                setPenddingListp2p(false);
                toastSuccess(t("Successful sell deal"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestListp2p(true);
                onDismiss()
            } else {
                setPenddingListp2p(false);
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
            }
        } catch (e) {
            console.error(e);
            handleRejected(e);
            setPenddingListp2p(false);
        }
    }, [amount, buyer, buys, callWithMarketGasPrice, contractWrapped, dispatch, handleRejected, onDismiss, price, t, toastError, toastSuccess]);
    return { handleListp2p, requestedListp2p, pendingListp2p };
};
