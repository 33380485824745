
import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { CONTENT_ERROR } from "config";

export const useUserRejected = () => {
    const { toastError } = useToast();
    const { t } = useTranslation()

    const handleRejected = (error) => {
        const stringifyError = JSON.stringify(error);
        const parseError = JSON.parse(stringifyError);
        console.log('parseError', parseError)
        if (parseError?.code === "ACTION_REJECTED") toastError(t(CONTENT_ERROR));
        else if (parseError?.code === "CALL_EXCEPTION") toastError(t("The remaining amount of CCQ is not enough to make a transaction!"));
        else toastError(t(`Something went wrong. Confirm the transaction and make sure you have enough funds in your wallet to process the transaction!`))
    };

    return { handleRejected }
};