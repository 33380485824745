import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, Text, useModal } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { fetchCartItems } from "state/MarketPlace/actions";
import { CartItem, Items } from "state/MarketPlace/type";
import styled from "styled-components";

import { totalItems, totalPrice } from "utils/sumTotalItems";

import { PAYMENT, PRICE_CCQ_MINIMUM, formatNumberPayment } from "config";
import ItemBuyMobile from "./ItemBuyMobile";
import ItemBuy from "./ItemBuyPC";
import BuyModal from "./ItemBuyPC/BuyModal";

interface PropsCart {
    listItem: Items[];
    windowSize: number;
    listCart: CartItem[];
}

const Cart: React.FC<PropsCart> = ({ listItem, windowSize, listCart }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch<AppDispatch>();
    const { account, chainId } = useActiveWeb3React();
    const handleCancel = (id) => {
        const dataFilter = listCart.filter((item) => Number(item?.id) !== Number(id));
        dispatch(fetchCartItems({ listCart: dataFilter }));
    };

    const handleChangeAmountBuy = (amount, id, addresses, buys) => {
        const data = [...listCart];
        const index = listCart.findIndex((item) => item.id === id);
        data.splice(index, 1, { buyAmount: Number(amount), id, addresses, buys });
        dispatch(fetchCartItems({ listCart: data }));
    };

    const total = useMemo(() => {
        return totalPrice(listCart, listItem);
    }, [listCart, listItem]);

    const totalAmountBuy = useMemo(() => {
        return totalItems(listCart);
    }, [listCart]);
    const convertTotal = formatNumberPayment(total);
    const isGreaterthan1M = new BigNumber(total).isLessThan(PRICE_CCQ_MINIMUM);
    const [onBuyModal] = useModal(
        <BuyModal
            chainId={chainId}
            listItem={listItem}
            listCart={listCart}
            windowSize={windowSize}
            title={t("Confirm")}
            account={account}
            totalPrice={(convertTotal.toString())}
        />,
        true,
        true,
        `buy_item_${account}`
    );
    const isNaNTotal = new BigNumber(total).isNaN();

    return (
        <>
            {windowSize > 1000 ? (
                <WrapperChose>
                    <CsListBuy>
                        {listCart?.length !== 0 ? (
                            <>
                                <CsChoseBuy>
                                    <Flex>
                                        <Text color="#C7C9CA">{t("Add to cart")}:</Text>
                                        <Text fontWeight={700} ml="1rem">
                                            {formatNumberPayment(totalAmountBuy)}
                                        </Text>
                                    </Flex>
                                    <Flex flexDirection="column" width={["100%", "100%", "42%"]} pr={["0px", , "25px"]}>
                                        <TotalAndBuy alignItems="center">
                                            <Text color="textDisabled" fontSize="18px">{t("Total investment amount")}:</Text>
                                            <Flex alignItems="center" style={{ gap: "12px" }}>
                                                <Flex alignItems="center">
                                                    <Text fontWeight="700" fontSize="18px" color="primaryBright">
                                                        {convertTotal}
                                                    </Text>
                                                    <Text fontWeight="700" fontSize="18px" style={{ marginLeft: "5px" }}>{PAYMENT}</Text>
                                                </Flex>
                                                <CsButtonBuy
                                                    disabled={isGreaterthan1M || !account || isNaNTotal}
                                                    style={{ minWidth: "90px", height: "40px", borderRadius: "4px" }}
                                                    onClick={onBuyModal}
                                                >
                                                    {t("Buy")}
                                                </CsButtonBuy>
                                            </Flex>
                                        </TotalAndBuy>
                                        {!account &&
                                            <Flex width="100%" justifyContent="flex-end" mt="3px">
                                                <Text color="#EE4437" small>{t("Connect wallet to continue")}</Text>
                                            </Flex>
                                        }
                                    </Flex>
                                </CsChoseBuy>
                                <div className="bg-white">
                                    {listCart.map((item, index) => (
                                        <ItemBuy
                                            key={`${item.id}`}
                                            index={index}
                                            handleCancel={handleCancel}
                                            handleChangeAmountBuy={handleChangeAmountBuy}
                                            {...item}
                                            listItem={listItem.find((data) => Number(data.id) === Number(item.id))}
                                        />
                                    ))}
                                </div>
                            </>
                        ) : (
                            <></>
                        )}
                    </CsListBuy>
                </WrapperChose>
            ) : (
                <WrapperChoseMobile>
                    <CsListBuyMobile>
                        {listCart?.length !== 0 ? (
                            <>
                                <CsFlexBuyMobile>
                                    {listCart.map((item, index) => (
                                        <ItemBuyMobile
                                            key={`${item.id}`}
                                            index={index}
                                            handleCancel={handleCancel}
                                            handleChangeAmountBuy={handleChangeAmountBuy}
                                            {...item}
                                            listItem={listItem.find((data) => Number(data.id) === Number(item.id))}
                                        />
                                    ))}
                                </CsFlexBuyMobile>
                                <Flex flexDirection="column">
                                    <CsChoseBuy>
                                        <TotalAndBuy alignItems="center">
                                            <Text color="textDisabled">{t("Total amount")}:</Text>
                                            <Flex alignItems="center">
                                                <Text bold color="primary">
                                                    {convertTotal}
                                                </Text>
                                                <Text style={{ marginLeft: "5px" }}>{PAYMENT}</Text>
                                            </Flex>
                                            <CsButtonBuy disabled={isGreaterthan1M || !account} onClick={onBuyModal}>
                                                {t("Buy")}
                                            </CsButtonBuy>
                                        </TotalAndBuy>
                                    </CsChoseBuy>
                                    {!account &&
                                        <Flex width="100%" justifyContent="flex-end" mt="3px" pr="10px">
                                            <Text color="primaryBright" small>{t("Connect wallet to continue")}</Text>
                                        </Flex>
                                    }
                                </Flex>

                            </>
                        ) : (
                            <></>
                        )}
                    </CsListBuyMobile>
                </WrapperChoseMobile>
            )}
        </>
    );
};

export default Cart;

const CsFlexBuyMobile = styled(Flex)`
    width: 100%;
    flex-direction: column;
`;

const WrapperChoseMobile = styled.div`
`;

const CsListBuyMobile = styled.div`
`;

const CsFlex = styled(Flex)`
    flex-direction: column;
`;

const TotalAndBuy = styled(Flex)`
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        margin-top: 20px;
        width: 100%;
    }
    ${Text} {
        &:first-child {
            line-height: 21px;
            @media screen and (min-width: 601px) and (max-width: 1000px) {
                font-size: 14px;
            }
            @media screen and (max-width: 768px) {
                font-size: 12px;
            }
        }
        &:last-child {
            margin-left: 0.25rem;
            font-weight: bold;
            font-size: 16px;
            line-height: 21px;
            @media screen and (min-width: 601px) and (max-width: 1000px) {
                font-size: 14px;
            }
            @media screen and (max-width: 768px) {
                font-size: 12px;
            }
        }
    }
`;
const CsButtonBuy = styled(Button) <{ disabled: boolean }>`
    transition: 0.5s;
    @media screen and (max-width: 1024px) {
        border-radius: 4px;
        font-size: 18px;
        height: 40px;
        width: 90px;
    }
    @media screen and (max-width: 768px) {
        border-radius: 4px;
        font-size: 12px;
        height: 32px;
        width: 70px;
    }
`;
const CsChoseBuy = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    padding: 0 0px;
    margin: 1rem 0;
    width: 100%;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0.5rem 0 0.5rem;
    }
    @media screen and (max-width: 600px) {
        padding:0px 10px 0px 10px;
    }
`;
const CsListBuy = styled.div``;

const WrapperChose = styled.div`
    padding: 0px 6px 0px 6px;
    width: 100%;
`;

const WraperAmountCard = styled(Flex)`
    width: 50%;
    @media screen and (max-width: 768px) {
        justify-content: space-between;
    }
    ${Text} {
        &:first-child {
            font-size: 18px;
            font-weight: 400;
            line-height: 21px;
            color: ${({ theme }) => theme.colors.textDisabled};
            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
        &:last-child {
            /* margin-left: 2rem; */
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: ${({ theme }) => theme.colors.text};
            @media screen and (max-width: 768px) {
                font-size: 14px;
            }
        }
    }
`;
