import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { ToastDescriptionWithTx } from "components/Toast";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useWrapped } from "hooks/useContract";
import { useUserRejected } from "hooks/useUserRejected";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionDispatchMessage } from "state/Order/actions";


export const useBuyP2P = (
    account: string,
    chainId: number,
    nftAddress: string,
    buys: {
        amountTrading: number,
        orderId: number,
    }[],
    onDismiss: any,
) => {
    const { t } = useTranslation();
    const [requestedBuyP2P, setRequestBuyP2P] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const [pendingBuyP2P, setPenddingBuyP2P] = useState(false);
    const contractWrapped = useWrapped(nftAddress);
    const dispatch = useDispatch<AppDispatch>();
    const { handleRejected } = useUserRejected();

    const handleBuyP2P = useCallback(async () => {
        try {
            const orderIds = buys.map((item) => (item.orderId));
            setPenddingBuyP2P(true);
            const tx = await callWithMarketGasPrice(contractWrapped, "buyP2P", [orderIds]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Xác nhận mua thành công"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestBuyP2P(true);
                onDismiss();
                dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
                setPenddingBuyP2P(false);
            } else {
                // user rejected tx or didn't go thru
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
                setPenddingBuyP2P(false);
            }
        } catch (e) {
            setPenddingBuyP2P(false);
            handleRejected(e);
        }
    }, [buys, callWithMarketGasPrice, contractWrapped, dispatch, handleRejected, onDismiss, t, toastError, toastSuccess]);
    return { handleBuyP2P, requestedBuyP2P, pendingBuyP2P };
};
