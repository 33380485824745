import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Box, Button, Flex, HelpIconCcqIcon, Modal, OpenNewIconFundgo, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import React from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { GetDataMarketplace } from "state/MarketPlace";
import { fetchCartItems } from "state/MarketPlace/actions";
import { CartItem, Items } from "state/MarketPlace/type";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";

import ConnectWalletButton from "components/ConnectWalletButton";

import { convertDate } from "utils/converDate";
import { renderMarketplaceAddress, renderToken } from "utils/renderAddress";

import { CustomTooltip } from "components/CustomTooltip";
import { PAYMENT, formatNumberPayment } from "config";
import { useApprove, useBuyItem, useDownloadPDF } from "../hooks";
import RowForDesktop from "./Row";
import RowMobile from "./RowMobile";

interface ModalProps {
    title: React.ReactNode;
    hideCloseButton?: boolean;
    onBack?: () => void;
    onDismiss?: () => void;
    bodyPadding?: string;
    headerBackground?: string;
    windowSize: number;
    listItem: Items[];
    listCart: CartItem[];
    account: string;
    totalPrice: string;
    chainId: number;
}

const BuyModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
    title,
    onDismiss,
    windowSize,
    listItem,
    listCart,
    account,
    totalPrice,
    chainId,
    ...props
}) => {
    const { t, currentLanguage } = useTranslation();

    const { loading, handleDownload } = useDownloadPDF();
    const dispatch = useDispatch<AppDispatch>();
    const marketplaceAddress = renderMarketplaceAddress(chainId);
    const token = renderToken(chainId);
    const { dataUser } = GetDataMarketplace();
    const isNotEnoughBalance = new BigNumber(dataUser?.balance).isLessThan(totalPrice.replace(/,/g, ''));
    const isAllowance = new BigNumber(dataUser?.allowance).isLessThan(totalPrice.replace(/,/g, ''));
    const { handleBuyItems, pendingTx: loadingBuyItems } = useBuyItem(marketplaceAddress, account, chainId, listCart, onDismiss)
    const { handleApprove, pendingTx } = useApprove(marketplaceAddress, token.address, account, chainId);

    const handleCancel = () => {
        dispatch(fetchCartItems({ listCart: [] }));
        onDismiss();
    };
    const sAccount = account?.length ? `${account.substring(0, 6)}...${account.substring(account.length - 6)}` : "";

    // for download
    const handleDownloadPDF = async () => {
        const filterData = listCart.map(item => {
            const checkItem = listItem.find((data) => Number(data.id) === Number(item.id));
            return {
                ...checkItem,
                ...item
            }
        });

        const pendingEtf = filterData.map((item, index) => {
            const buyAmount = Number(item.buyAmount) * Number(item?.unitPrice);
            const STT = index + 1;
            return {
                numericalOrder: STT < 10 ? `0${STT}` : STT,
                etfId: item.nftId,
                parValue: `${formatNumberPayment(item.unitPrice)} ${PAYMENT}`,
                interest: `${item.interVestTermRate} %`,
                incomingInterestDate: item.paydayNextProfit,
                releaseDate: convertDate(item.issueDate * 1000),
                maturityDate: convertDate(item.expireDate * 1000),
                transferValue: `${formatNumberPayment(buyAmount)} ${PAYMENT}`,
            }
        });
        const transferedEtf = filterData.map((item, index) => {
            const STT = index + 1;
            return {
                numericalOrder: STT < 10 ? `0${STT}` : STT,
                etfId: `${item?.nftId}`,
                transferDate: convertDate(Date.now())
            }
        });

        const payload = {
            lang: currentLanguage?.code,
            buyerWalletAddress: account,
            sellerWalletAddress: marketplaceAddress,
            pendingEtf,
            transferedEtf,
            transferDate: convertDate(Date.now()),
            totalTransferValue: `${totalPrice} ${PAYMENT}`,
        };
        handleDownload(payload)
    };
    function handleOpenLink(address) {
        window.open(getBlockExploreLink(address, "address", chainId));
    }

    const totalTransactions = listCart.reduce((total, item) => {
        return total + Number(item.buyAmount);
    }, 0);

    return (
        <>
            <CsModal title={title} onDismiss={onDismiss} {...props}>
                <Flex width="100%" flexDirection="column" alignItems="center">
                    <CsTitleModal color="text" fontWeight="700">
                        {t("Transaction confirmation")}
                    </CsTitleModal>
                </Flex>
                <Box>
                    {windowSize > 768 ? (
                        <>
                            <WrapperItemHeader>
                                <Text width="14%">{t("Quantity")}</Text>
                                <CsFlexHeader>
                                    <Text mr='0.2rem'>{t("Payment period")}</Text>
                                    <CustomTooltip title={t("Yield payment period")}>
                                        <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                    </CustomTooltip>
                                </CsFlexHeader>
                                <CsFlexHeader>
                                    <Text mr='0.2rem'>{t("Face value")}</Text>
                                    <CustomTooltip title={`${t("Face value")} (${PAYMENT})`}>
                                        <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                    </CustomTooltip>
                                </CsFlexHeader>
                                <Text width="14%">{t("Yield")}</Text>
                                <CsFlexHeader>
                                    <Text mr='0.2rem'>{t("Payment date")}</Text>
                                    <CustomTooltip title={t("Next yield payment date")}>
                                        <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                    </CustomTooltip>
                                </CsFlexHeader>
                                <Text width="14%">{t("Remaining days")}</Text>
                                {/* <Text width="14%">
                                    Đơn giá <br />
                                    (đồng)
                                </Text> */}
                                <CsFlexHeader>
                                    <Text mr='0.2rem'>{t("Unit price")}</Text>
                                    <CustomTooltip title={`${t("Unit price")} (${PAYMENT})`}>
                                        <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                    </CustomTooltip>
                                </CsFlexHeader>
                            </WrapperItemHeader>
                            {listCart.map((item) => (
                                <RowForDesktop
                                    key={`${item.id}`}
                                    {...item}
                                    itemSell={listItem.find((data) => Number(data.id) === Number(item.id))}
                                />
                            ))}
                            <CsFlexWrapInfo>
                                <CSInfoSide>
                                    <TextAddr fontSize="14px">
                                        {t("Wallet address to cut payment")} <br />
                                        {t("Fund certificates")}
                                    </TextAddr>
                                    {account?.length && (
                                        <WrapAddress>
                                            <Text color="primary">{sAccount}</Text>
                                        </WrapAddress>
                                    )}
                                </CSInfoSide>
                                <CSInfoSide>
                                    <Text color="textSubtle" fontWeight="700">
                                        {t("Total investment amount")}
                                    </Text>
                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Flex alignItems="center">
                                            <Text fontSize="14px" color="primaryBright" fontWeight="700">
                                                {totalPrice}
                                            </Text>
                                            <Text fontSize="14px" color="text" fontWeight="700" ml="5px">{PAYMENT}</Text>
                                        </Flex>
                                        {/* {Number(totalPrice) !== 0 && <Flex mt="4px"> */}
                                        {/*     <span style={{ color: "#8C8D8E", fontSize: '12px' }}> */}
                                        {/*         {` (Tương đương ${totalPrice} điểm VNDC)`} */}
                                        {/*     </span> */}
                                        {/* </Flex>} */}
                                    </Flex>
                                </CSInfoSide>
                            </CsFlexWrapInfo>
                        </>
                    ) : (
                        <>
                            {listCart.map((item) => (
                                <RowMobile
                                    key={`${item.id}`}
                                    {...item}
                                    itemSell={listItem.find((data) => Number(data.id) === Number(item.id))}
                                />
                            ))}
                            <CsFlexWrapInfo>
                                <Text
                                    style={{
                                        color: "#02111b",
                                        fontWeight: 700,
                                        fontSize: "0.875rem",
                                        width: "100%",
                                        textAlign: "left",
                                        // marginBottom: "21px"
                                    }}
                                >
                                    {t("Fund Certificate Information")}
                                </Text>
                                {/* <CSInfoSide>
                                    <TextAddr fontSize="14px">{t("Transaction date")}</TextAddr>
                                    <Text style={{ fontWeight: "600", color: "#02111B" }}>
                                        {convertDate(issueDate * 1000)}
                                    </Text>
                                </CSInfoSide> */}
                                <CSInfoSide>
                                    <TextAddr fontSize="14px">{t("Buyer's wallet address")}</TextAddr>
                                    {account?.length && (
                                        <Text
                                            onClick={() => handleOpenLink(account)}
                                            style={{ fontWeight: "600", color: "#02111B", cursor: "pointer" }}
                                        >
                                            {sAccount}
                                            <OpenNewIconFundgo />
                                        </Text>
                                    )}
                                </CSInfoSide>
                                <CSInfoSide>
                                    <TextAddr fontSize="14px">{t("Quantity transactions")}</TextAddr>
                                    <Text style={{ fontWeight: "600", color: "#02111B" }}>{totalTransactions}</Text>
                                </CSInfoSide>
                                <CSInfoSide>
                                    <Text fontWeight="700">
                                        {t("Total investment amount")}
                                    </Text>
                                    <Flex alignItems="flex-end" flexDirection="column">
                                        <Flex>
                                            <Text fontSize="14px" fontWeight="700" color="primaryBright">
                                                {totalPrice}
                                            </Text>
                                            <Text color="text" fontWeight="700" ml="5px">{PAYMENT}</Text>
                                        </Flex>
                                        {/* <Flex mt="4px"> */}
                                        {/*     <span style={{ color: "#8C8D8E", fontSize: '10px' }}> */}
                                        {/*         {` (Tương đương ${totalPrice} điểm VNDC)`} */}
                                        {/*     </span> */}
                                        {/* </Flex> */}
                                    </Flex>
                                </CSInfoSide>
                            </CsFlexWrapInfo>
                        </>
                    )}
                </Box>

                <Flex
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    mt={["1.5rem", , "2rem"]}
                    flexDirection="column"
                    style={{ gap: "10px" }}
                >
                    {account?.length > 0 ? (
                        <>
                            {isAllowance ? (
                                <>
                                    <CsButtonConfirm
                                        disabled={pendingTx || isNotEnoughBalance}
                                        onClick={handleApprove}
                                        endIcon={pendingTx ? <AutoRenewIcon spin color="textSubtle" /> : null}
                                    >
                                        {t("Approve")}
                                    </CsButtonConfirm>
                                </>
                            ) : (
                                <WrapperButton>
                                    <CsButtonConfirm
                                        disabled={loadingBuyItems}
                                        endIcon={loadingBuyItems ? <AutoRenewIcon spin color="textSubtle" /> : null}
                                        onClick={handleBuyItems}>{t("Confirm")}
                                    </CsButtonConfirm>
                                </WrapperButton>
                            )}
                        </>
                    ) : (
                        <>
                            <ConnectWalletButton />
                        </>
                    )}
                    <Flex width="100%" flexDirection="column" alignItems="center">
                        {isNotEnoughBalance && account && (
                            <Text color="red">{t("Your %symbol% ​​balance is not enough", { symbol: PAYMENT })}</Text>
                        )}
                        {!account && <Text color="red">{t("Connect your wallet to continue")}</Text>}
                    </Flex>
                </Flex>
                <CsFlexAgree>
                    <Text>{t("By “Confirm” I agree to")}</Text>
                    <CsLink
                        onClick={handleDownloadPDF}
                        disabled={loading || isNotEnoughBalance}
                    >
                        {t("Fund Certificate Transfer Contract")}
                    </CsLink>
                </CsFlexAgree>
            </CsModal>
        </>
    );
};

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
    width:14%;
`
const WrapperButton = styled.div`
    gap: 1.5rem;
    display: flex;
    flex-direction: row-reverse;
    button {
        min-width: 200px;
    }
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        width: 100%;
        gap: 1rem;
        button {
            min-width: 100%;
        }
    }
`;

const CsTextVndc = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    display: block;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;
const CsImgCoin = styled.img`
    width: 22px;
    height: 22px;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;
const CsTitleModal = styled(Text)`
    font-size: 28px;
    @media screen and (max-width: 586px) {
        font-size: 24px;
    }
`;
const CsFlexAgree = styled(Flex)`
    width: 100%;
    margin-top: 1.5rem;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 1024px) {
        display: none;
    }
    @media screen and (max-width: 600px) {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
    ${Text} {
        font-size: 16px;
        font-weight: 700;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.text};
        &:last-child {
            text-decoration: underline;
        }
        @media screen and (min-width: 601px) and (max-width: 1000px) {
            font-size: 14px;
        }
        @media screen and (max-width: 600px) {
            font-size: 14px;
        }
    }
`;
const CsButtonConfirm = styled(Button) <{ disabled: boolean }>`
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    transition: 0.5s;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 40px;
        font-size: 14px;
        min-width: 230px;
        border-radius: 4px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 1.5;
        height: 40px;
        min-width: 50%;
        border-radius: 4px;
    }
`;
const CsLink = styled(Button).attrs({ variant: "text" })`
    text-decoration: underline;
    font-size: 16px;
    padding: 0px;
    height: fit-content;
    font-weight: 700;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 14px;
    }
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
    cursor: pointer;
`;
const TextAddr = styled(Text)`
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.textSubtle};
`;
const WrapAddress = styled.div`
    height: 40px;
    min-width: 220px;
    margin-left: 40px;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.colors.border};
    @media screen and (max-width: 1024px) {
        min-width: 120px;
    }
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        color: ${({ theme }) => theme.colors.primary};
    }
`;
const CsFlexWrapInfo = styled(Flex)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 2rem;
    @media screen and (max-width: 1024px) {
        gap: 1rem;
        flex-direction: column;
        display: flex;
    }
`;
const CSInfoSide = styled(Flex)`
    justify-content: space-between;
    align-items: center;
    ${Text} {
        font-size: 14px;
        @media screen and (max-width: 1000px) {
            font-size: 12px;
        }
    }
    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 12px 0;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
const CsModal = styled(Modal)`
    padding: 24px 0;
    min-width: 990px;
    @media screen and (max-width: 768px) {
        min-width: 90%;
        padding: 0;
    }
`;
export const CsButtonCancel = styled(Button)`
    background: transparent;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    min-width: 230px;
    border-radius: 12px;
    color: ${({ theme }) => theme.colors.primary};
    transition: 0.5s;
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 40px;
        font-size: 14px;
        min-width: 230px;
        border-radius: 4px;
    }
    @media screen and (max-width: 600px) {
        font-size: 12px;
        line-height: 1.5;
        height: 40px;
        min-width: 50%;
        border-radius: 4px;
    }
`;
export default BuyModal;
