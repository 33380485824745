import { Tooltip } from 'antd';
import React from 'react';

interface TooltipType {
    title: string;
    children: React.ReactNode,
    color?: string,
    trigger?: any,
}

export const CustomTooltip = ({ title, children, color, trigger = ["click"] }: TooltipType) => {
    return (
        <Tooltip placement="top" title={title} trigger={trigger} color={color}>
            {children}
        </Tooltip>
    )
};