import BigNumber from "bignumber.js";
import ccqWrappedAbi from 'config/abi/ccqWrappedAbi.json';
import multicall from "utils/multicall";

export const getDataEtfType = async (nftAddress: string, chainId: number) => {
    try {
        const result = await multicall(ccqWrappedAbi, [{ address: nftAddress, name: 'etfType', params: [] }], chainId);
        const payload = { ...result[0] }
        return {
            isInvest: payload.isInvest,
            isTrade: payload.isTrade,
            mortgageStatus: new BigNumber(payload.mortgageStatus.toString()).toNumber(),
        };
    } catch (error) {
        return {
            isTrade: true,
            isInvest: true,
            mortgageStatus: 0
        };
    }
};