import { Button, Flex, IconSearchNft, NoticeIcon, Text, useTooltip } from "@pancakeswap/uikit";

import { useTranslation } from "@pancakeswap/localization";
import { CustomTooltip } from "components/CustomTooltip";
import { UNIT_DATE, formatNumberPayment } from "config";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";
import { useModal } from "hooks/use-modal";
import { CsItem, CsItemSmall, CsItemWarning } from "../style";

interface optionsProps {
    handleChoose: (id: number, nftAddress: string, buys) => void;
}

type HandleProps = Items & optionsProps;

const Item: React.FC<HandleProps> = ({
    amount,
    price,
    interVestTerm,
    handleChoose,
    id,
    interVestTermRate,
    paydayNextProfit,
    unitPrice,
    dayDiff,
    vestingMap,
    paused,
    nftAddress,
    buys,
    isBlacklists,
}) => {
    const { t } = useTranslation();
    const { toggleModal, ModalTypeEnum } = useModal();

    const totalVested = useMemo(() => {
        return vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [vestingMap]);

    const totalVestingMap = useMemo(() => {
        return vestingMap?.length
    }, [vestingMap]);

    const { targetRef, tooltip, tooltipVisible } = useTooltip(
        <CsTextTootip style={{ color: "red" }}>{t("Fund Certificate is temporarily locked, please come back later!")}</CsTextTootip>,
        { placement: "top-end", tooltipOffset: [20, 10] }
    );


    return (
        <Container>
            <WrapperCard>
                <CsItemSmall>
                    <p
                        aria-hidden="true"
                        className="absolute left-0 cursor-pointer bottom-0"
                        onClick={() => {
                            toggleModal({
                                type: ModalTypeEnum.MODAL_SEARCH_CONTRACT,
                                title: "",
                                data: {
                                    contract: nftAddress.toLowerCase()
                                }
                            })
                        }}
                    >
                        <IconSearchNft />
                    </p>
                    {formatNumberPayment(amount)}
                </CsItemSmall>
                <CsItemSmall className="line">
                    {formatNumberPayment(price)}
                </CsItemSmall>
                <CsItemSmall>{totalVested}/{totalVestingMap}</CsItemSmall>
                <CsItemSmall>{`${interVestTerm} ${interVestTerm > 1 ? t("months") : t("month")}`}</CsItemSmall>
                <CsItemSmall style={{ display: "flex", justifyContent: "center" }}>
                    {paydayNextProfit}
                </CsItemSmall>
                <CsItemSmall>{dayDiff} {t(UNIT_DATE)}</CsItemSmall>
                <CsItemSmall className="line">{interVestTermRate}%</CsItemSmall>
                <CsItemSmall>
                    {formatNumberPayment(unitPrice)}
                </CsItemSmall>
                <CsItem />
                <>
                    <CustomTooltip
                        trigger={["hover"]}
                        color="red"
                        title={isBlacklists ? t("Feature locked, please contact admin!") : ""}
                    >
                        <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                            <CsButtonChoose
                                disabled={paused || isBlacklists}
                                className='buttonChoose'
                                onClick={() => {
                                    handleChoose(id, nftAddress, buys)
                                }}
                            >{t("Select")}
                            </CsButtonChoose>
                            <CsItemWarning>
                                {paused &&
                                    <>
                                        <ReferenceElement ref={targetRef}>
                                            <NoticeIcon />
                                        </ReferenceElement>
                                        <>{tooltipVisible && tooltip}</>
                                    </>
                                }
                            </CsItemWarning>
                        </Flex>
                    </CustomTooltip>
                </>
            </WrapperCard>
        </Container>
    );
};

export default Item;

const CsButtonChoose = styled(Button) <{ disable: boolean }>`
    border: 1px solid ${({ theme }) => theme.colors.primary};
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    min-width: 90px;
    height: 40px;
    &.buttonChoose{
        &:hover:not(:disabled){
            background: #447290;
            color: ${({ theme }) => theme.colors.white};
        }
        &:hover{
            background-color: var(--colors-backgroundDisabled);
            border-color: var(--colors-backgroundDisabled);
            color: ${({ theme }) => theme.colors.textSubtle};
        }
    }
`;

const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
    border-bottom: 1px solid rgba(197, 214, 232, 0.30);
`;

const WrapperCard = styled(Flex)`
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    place-items: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.62);
    &:hover{
        background: rgba(197, 214, 232, 0.30);
    }
    button {
        transition: 0.5s;
        &:hover {
            background: ${({ theme }) => theme.colors.primary};
            color: ${({ theme }) => theme.colors.white};
            border: 1px solid transparent;
        }
    }
    ${CsItemSmall} {
        height: 100%;
        position: relative;
        width: 100%;
        padding: 22px 0;
    }
`;
const CsTextTootip = styled(Text)`
    font-size: 14px;
    @media screen and (max-width: 768px) {
        font-size: 12px;
    }
`;
const ReferenceElement = styled.div`
    display: inline-block;
    cursor: pointer;
`;