import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Box, Flex, HelpIconCcqIcon, HelpIconCcqIcon2, OpenNewIconFundgo, Skeleton, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import BigNumber from "bignumber.js";
import { CustomTooltip } from "components/CustomTooltip";
import { PAYMENT, UNIT_DATE, formatNumberPayment } from "config";
import React, { useMemo } from "react";
import { ItemWithVestingMap } from "state/Order/type";
import styled from "styled-components";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import { GetNftInfo } from "../hook/fetchData";
import { useDelistp2p } from "../hook/useDelist";
import { ItemTransaction } from "./ItemTransaction";
import {
    CsButtonConfirm,
    CsFlexHeader,
    CsModal,
    CsTextMobile,
    CsTextValue,
    CustomLink,
    WrapItemMobile,
    WrapQuantity,
    WrapperButton,
    WrapperCard
} from "./styles";

interface ModalProps {
    onDismiss?: () => void;
    listOrder?: any;
    sAccount?: (seller) => string;
    handleOpenLink?: (seller) => void;
    title: string,
    item: ItemWithVestingMap,
    account: string,
    chainId: number
}

const CancelModal: React.FC<React.PropsWithChildren<ModalProps>> = ({
    sAccount,
    handleOpenLink,
    onDismiss,
    item,
    title,
    account,
    chainId,
    ...props
}) => {
    const { t } = useTranslation();

    const windowSize = GetWindowSize();
    const { nftInfo, isLoading } = GetNftInfo(item?.nftAddress, account, item?.nftId, account, chainId)
    const { handleDelistp2p, pendingDelistp2p } = useDelistp2p(account, chainId, item?.nftAddress, item?.buys, onDismiss)

    const totalVested = useMemo(() => {
        return item?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.vestingMap])
    const totalVestingMap = useMemo(() => {
        return item?.vestingMap?.length
    }, [item?.vestingMap?.length])


    return (
        <CsModal title={title} onDismiss={onDismiss} {...props}>
            <Flex width="100%" flexDirection="column" alignItems="center" mt="1rem">
                <CsTitle>{t("Cancellation confirmation")}</CsTitle>
            </Flex>
            <Box>
                {windowSize > 768 ? (
                    <>
                        <WrapperItemHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Face value")}</Text>
                                <CustomTooltip title={`${t("Face value")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Yield")}</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Period received")}</Text>
                                <CustomTooltip title={t("Period received/total period")}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Payment period")}</Text>
                                <CustomTooltip title={t("Yield payment period")}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Payment date")}</Text>
                                <CustomTooltip title={t("Next yield payment date")}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Remaining days")}</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Value")}</Text>
                                <CustomTooltip title={`${t("Value")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                        </WrapperItemHeader>
                        <Container>
                            <WrapperCard>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        formatNumberPayment(nftInfo?.price)
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${formatNumberPayment(nftInfo?.profit)}%`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${totalVested}/${totalVestingMap}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        `${nftInfo?.intervestTerm} ${nftInfo?.intervestTerm > 1 ? t("months") : t("month")}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                        `0`
                                        :
                                        `${convertDate(nftInfo?.nextInterestPaymentDate * 1000)}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                        `0 ${t(UNIT_DATE)}`
                                        :
                                        `${getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)} ${t(UNIT_DATE)}`
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {isLoading ?
                                        <Skeleton width="42px" />
                                        :
                                        <>
                                            {new BigNumber(item?.price).isNaN() ?
                                                `0`
                                                :
                                                formatNumberPayment(item?.price)
                                            }
                                        </>
                                    }
                                </Text>
                            </WrapperCard>
                        </Container>
                        <ItemTransaction
                            titleOrderQuantity={t("Quantity transactions")}
                            valueOrderQuantity={item?.amountTrading?.toString()}
                            valueTransactionMethod={t("Sell Deal")}
                            dateTransaction={convertDate(item?.dateTrading * 1000)}
                            addressBuy={item?.addressTrading}
                            totalMoney={Number(item?.price)}
                            textAlert={t("Buyer's wallet address")}
                            utiOrderQuantity={t("Fund certificates")}
                        />
                    </>
                ) : (
                    <>
                        <WrapItemMobile>
                            <WrapQuantity>
                                <Flex
                                    justifyContent="space-between"
                                    alignItems="center"
                                    width="100%"
                                >
                                    <CsFlexHeaderMb width="23.3%">
                                        <CustomTooltip title={t("Face value")}>
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        <Text bold>
                                                            {formatNumberPayment(nftInfo?.price)}
                                                        </Text>
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="23.3%" justifyContent="center">
                                        <CustomTooltip title={t("Yield")}>
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        `${nftInfo?.profit}%`
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="23.3%" justifyContent="flex-end">
                                        <CustomTooltip title={t("Period received/total period")}>
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {totalVested}/{totalVestingMap}
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                </Flex>
                                <Flex
                                    mt="8px"
                                    style={{
                                        display: "grid",
                                        gridTemplateColumns: "repeat(3, minmax(0, 1fr))"
                                    }}
                                >

                                    <CsFlexHeaderMb width="100%" justifyContent="flex-start">
                                        <CustomTooltip title={t("Yield payment period")}>
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {isLoading ?
                                                        <Skeleton width="42px" />
                                                        :
                                                        <Flex style={{ gap: '4px' }} justifyContent="center">
                                                            <Text style={{ color: '#02111b', fontWeight: '700' }}>{nftInfo?.intervestTerm}</Text>
                                                            <Text>{nftInfo?.intervestTerm > 1 ? t("months") : t("month")}</Text>
                                                        </Flex>
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="100%" justifyContent="center">
                                        <CustomTooltip title={t("Remaining days")}>
                                            <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                                <Text ml="4px">
                                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                                        `0`
                                                        :
                                                        <Text color='#02111b' style={{ fontWeight: "700" }}>
                                                            {
                                                                getDateBetweenTwoDates(nftInfo?.nextInterestPaymentDate * 1000)
                                                            }
                                                            {t(UNIT_DATE)}
                                                        </Text>
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>

                                    <CsFlexHeaderMb width="100%" justifyContent="flex-end">
                                        <CustomTooltip title={t("Next yield payment date")}>
                                            <Flex style={{ cursor: 'pointer', gap: '4px' }}>
                                                <Text ml="4px">
                                                    {new BigNumber(nftInfo?.nextInterestPaymentDate * 1000).isNaN() ?
                                                        `0`
                                                        :
                                                        convertDate(nftInfo?.nextInterestPaymentDate * 1000)
                                                    }
                                                </Text>
                                                <HelpIconCcqIcon2 />
                                            </Flex>
                                        </CustomTooltip>
                                    </CsFlexHeaderMb>
                                </Flex>
                            </WrapQuantity>
                            <Flex width="100%" height="auto" flexDirection="column" style={{ gap: "10px" }}>
                                <Text style={{ color: "#02111b", fontWeight: 700, fontSize: "0.875rem" }}>
                                    {t("Fund Certificate Information")}
                                </Text>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">{t("Transaction date")}</CsTextMobile>
                                    <CsTextMobile bold>{convertDate(item?.dateTrading * 1000)}</CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">{t("Transaction wallet address")}</CsTextMobile>
                                    <CsTextMobile bold>
                                        <CustomLink
                                            style={{ textAlign: "right" }}
                                            onClick={() => handleOpenLink(item?.addressTrading)}
                                        >
                                            <Text style={{ fontWeight: "600", color: '#02111b' }}>
                                                {sAccount(item?.addressTrading)}
                                            </Text>
                                            <OpenNewIconFundgo />
                                        </CustomLink>
                                    </CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">{t("Quantity")}</CsTextMobile>
                                    <CsTextMobile bold>{item?.amountTrading}</CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">{t("Payment methods")}</CsTextMobile>
                                    <CsTextMobile fontSize="12px" bold>{t("Sell Deal")}</CsTextMobile>
                                </Flex>
                                <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
                                    <CsTextMobile color="textSubtle">{t("Total agreement amount")}</CsTextMobile>

                                    <Flex flexDirection="column" alignItems="flex-end">
                                        <Flex flexDirection="row">
                                            <CsTextValue>
                                                {formatNumberPayment(item?.price)}
                                            </CsTextValue>
                                            <Text bold color="text" ml="5px">{PAYMENT}</Text>
                                        </Flex>
                                        {/* {Number(item?.price) !== 0 &&
                                            <CsUnit style={{ color: "#8C8D8E" }}>
                                                {` (${t("Equivalent")} ${formatNumberPayment(item?.price)} ${t("point")} ${PAYMENT})`}
                                            </CsUnit>
                                        } */}
                                    </Flex>

                                </Flex>
                            </Flex>
                        </WrapItemMobile>
                    </>
                )}
            </Box>
            <WrapperButton>
                {
                    !item.isBuyP2P &&
                    <Flex
                        style={{
                            color: "red",
                            fontSize: "14px",
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center",
                            marginBottom: "10px",
                        }}
                    >{t("Fund Certificate's yield period is over, can't make trades, please confirm cancellation!")}</Flex>
                }
                <Flex style={{ gap: "10px" }} width="100%" justifyContent="center">
                    <CsButtonConfirm
                        disabled={pendingDelistp2p || item?.paused}
                        onClick={handleDelistp2p}
                        endIcon={pendingDelistp2p ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                    >
                        {t("Cancellation confirmation")}
                    </CsButtonConfirm>
                </Flex>
            </WrapperButton>
            {item?.paused === true &&
                <Flex width="100%" justifyContent="center" mt={["1rem", , , "1rem"]}>
                    <Text fontSize={["12px", , , "14px"]} color="red">{t("Fund Certificate is temporarily locked, please come back later!")}</Text>
                </Flex>
            }
        </CsModal>
    );
};
const CsImgCoin = styled.img`
    width: 22px;
    height: 22px;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
    }
`;
const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 24px 0;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;

const CsTitle = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    font-size: 28px;
    font-weight: 700;
    @media screen and (max-width: 768px) {
        font-size: 24px;
    }
`;
const CsFlexHeaderMb = styled(Flex)`
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
`
const CsUnit = styled.span`
    font-size: 12px;
    margin-top: 4px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`

export default CancelModal;