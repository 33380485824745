import { useTranslation } from "@pancakeswap/localization";
import React from 'react';
import MetaTags from 'react-meta-tags';
import styled from 'styled-components';
// import { useLocation } from 'react-router'
import { DEFAULT_META, getCustomMeta } from 'config/constants/meta';
import { useRouter } from 'next/router';
import { Flex } from "@pancakeswap/uikit";
import { MENU_HEIGHT } from "@pancakeswap/uikit/src/widgets/Menu/config";

const PageMeta = () => {
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()
  const { pathname } = useRouter()
  const pageMeta = getCustomMeta(pathname, t, locale) || {}
  const { title, description, image } = { ...DEFAULT_META, ...pageMeta }
  const pageTitle = title

  return (
    <MetaTags>
      <title>{pageTitle}</title>
      <meta name="description" content={t(description)}/>
      <meta property="og:title" content={t(title)} />
      <meta property="og:image" content={image} />
    </MetaTags>
  )
}

const PageFullWidth: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
        <Wrapper>
            <PageMeta />
          <Row>
            <ColImageLeft>
              <WrapImg>
                <ImgIconLeft src="/images/ccqFundgo/bg_left.png?version=1.2" alt="background" />
              </WrapImg>
            </ColImageLeft>
            <ColImageRight>
              <WrapImg>
                <ImgIconRight src="/images/ccqFundgo/bg_right.png?version=1.3" alt="background" />
              </WrapImg>
            </ColImageRight>
          </Row>
            {children}
        </Wrapper>
  )
}

export default PageFullWidth

const Row = styled.div`
  height: 100%;
  /* @media screen and (max-width: 1280px) {
    display: none;
  } */
`
const WrapImg = styled.div``

const ImgIconLeft = styled.img`
  width: 212px;
  @media screen and (max-width: 1000px){
    width: 120px;
  }
`
const ImgIconRight = styled.img`
  width: 190px;
  @media screen and (max-width: 1000px){
    width: 120px;
  }
  @media screen and (max-width: 600px){
    width: 100px;
  }
`
const Wrapper = styled.div`
    background-color: green;
    width:100%;
    height: auto;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    /* margin-top: ${MENU_HEIGHT}px; */
    background-color: ${({ theme }) => theme.colors.background};
    &::before{
      content: '';
      position: absolute;
      width: 28px;
      height: 470px;
      left: -14px;
      top: 1226px;
      background: #014235;
      border-radius: 56px;
      @media screen and (max-width: 1000px){
        display: none;
      }
    }
`
const ColImageLeft = styled(Flex)`
  position: absolute;
  left: -35px;
  top: 21px;
  @media screen and (max-width: 1000px){
    left: -72px;
  }
`
const ColImageRight = styled(Flex)`
  position: absolute;
  right: -28px;
  top: 58px;
  @media screen and (max-width: 1000px){
    right: -72px;
    display: none;
  }
`