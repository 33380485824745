import { useSlowRefreshEffect } from 'hooks/useRefreshEffect';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, AppState } from '../index';
import { fetchDataOnMarketplace, fetchDataUserAction, fetchStatusLoading } from './actions';
import { fetchDataAllowance, fetchGetTotalItems, fetchItems, fetchNftAddressItem } from './fetchData';



export const GetValueSearch = () => {
    const data = useSelector<AppState, AppState['marketplace']>((state) => state.marketplace)
    const valueSearch = data
    return valueSearch
}

export const UseCoreMarketplace = (contractMarketplaceAddress: string, tokenAddress: string, chainId: number, account: string) => {
    const dispatch = useDispatch<AppDispatch>();
    const { listCart } = GetDataMarketplace(); // run dispatch buy item request

    useSlowRefreshEffect(() => {
        const getDataItem = async () => {
            try {
                dispatch(fetchStatusLoading({ isLoading: true }));
                const dataAllowance = await fetchDataAllowance(contractMarketplaceAddress, tokenAddress, account, chainId);
                const getTotalItems = await fetchGetTotalItems(contractMarketplaceAddress, chainId);
                const items = await fetchItems(contractMarketplaceAddress, chainId, getTotalItems);
                const nftAddressItem = await fetchNftAddressItem(items, contractMarketplaceAddress, chainId, account);
                dispatch(fetchDataOnMarketplace({ listItem: nftAddressItem }));
                dispatch(fetchDataUserAction({ dataUser: dataAllowance }));
            } catch (e) {
                console.log(e)
            }
        }
        getDataItem()
    }, [account, chainId, contractMarketplaceAddress, dispatch, tokenAddress, listCart.length])

};

export const GetDataMarketplace = () => {
    const dataMarketplace = useSelector<AppState, AppState['marketplace']>((state) => state.marketplace);
    const { listItem } = dataMarketplace;
    const checkBlacklists = listItem.filter((i) => !i?.isBlacklists);
    const sortAmount = [...checkBlacklists]?.sort((a, b) => a?.amount - b?.amount);
    const filterDataAddress = sortAmount.reduce((acc, pre) => {
        const index = acc.findIndex((item) => item?.nftAddress?.toLowerCase() === pre?.nftAddress?.toLowerCase());
        if (index === -1) {
            acc.push({
                ...pre,
                buys: [
                    {
                        amount: pre.amount,
                        id: pre.id,
                    },
                ]
            });
        } else {
            const item = [...acc[index].buys];
            item.push({
                amount: pre.amount,
                id: pre.id,
            })
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                amount: acc[index].amount + pre.amount,
                buys: item
            }
        }
        return acc;
    }, []);
    return {
        ...dataMarketplace,
        listItem: filterDataAddress
    }
}