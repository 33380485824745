import { useSelector, useDispatch } from 'react-redux'
import { UseActiveCCQ } from 'config/constants/ccqSupport/constants'
import { useSlowRefreshEffect } from 'hooks/useRefreshEffect'
import React, { useEffect } from 'react'
import { AppDispatch, AppState } from '../index'
import { fetchStatusLoading, listItemOrder, getBalance, getAllowance } from './actions'
import { fetchDataBalance, fetchDataUser, fetchListOrder, fetchVestingMap } from './fetchData'

export const UseCoreListOrder = (tokenSupport: string, account: string, chainId: number) => {
    const dispatch = useDispatch<AppDispatch>();
    const { messageHash } = GetDataListOrder();

    const getDataListOrder = React.useCallback(async () => {
        try {
            if (account) {

                const activeCCQ = await UseActiveCCQ(chainId)
                const [result, resultAllowance, resultBalance] = await Promise.all([
                    fetchListOrder(activeCCQ, account, chainId),
                    fetchDataUser(tokenSupport, activeCCQ, account, chainId),
                    fetchDataBalance(tokenSupport, account, chainId)
                ])
                const resultVestingmap = await fetchVestingMap(result?.listItemOrder, chainId, account)

                dispatch(listItemOrder(resultVestingmap))
                dispatch(getBalance(resultBalance))
                dispatch(getAllowance(resultAllowance))

            } else {
                dispatch(listItemOrder({ listItemOrder: [] }))
            }
        } catch (e) {
            console.log(e)
        }
    }, [account, chainId, dispatch, tokenSupport]);

    useEffect(() => {
        (async () => {
            dispatch(fetchStatusLoading({ isLoading: true }))
            await getDataListOrder();
            dispatch(fetchStatusLoading({ isLoading: false }))
        })()
    }, [dispatch, getDataListOrder, messageHash]);

    useEffect(() => {
        const timer = setInterval(() => {
            getDataListOrder();
        }, 30000);
        return () => clearTimeout(timer);
    }, [getDataListOrder]);
}

export const GetItemOrder = () => {
    const data = useSelector<AppState, AppState['order']>((state) => state.order)
    const itemOrderValue = data?.itemOrder
    return itemOrderValue
}

export const GetDataListOrder = () => {
    const dataListOrder = useSelector<AppState, AppState['order']>((state) => state.order);
    const { isLoading, listAllowanc, balance, messageHash } = dataListOrder;

    const filterDataAddress = dataListOrder.listItemOrder.reduce((acc, pre: any) => {
        const index = acc.findIndex((item) => item?.nftAddress?.toLowerCase() === pre?.nftAddress?.toLowerCase() && item?.dateTrading === pre?.dateTrading);
        if (index === -1) {
            acc.push({
                ...pre,
                buys: [
                    {
                        amountTrading: pre.amountTrading,
                        orderId: pre.orderId,
                    },
                ]
            });
        } else {
            const item = [...acc[index].buys];
            item.push({
                amountTrading: pre.amountTrading,
                orderId: pre.orderId,
            })
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                amountTrading: acc[index].amountTrading + pre.amountTrading,
                buys: item
            }
        }
        return acc;
    }, []);
    return {
        listItemOrder: filterDataAddress,
        isLoading,
        listAllowanc,
        balance,
        messageHash
    }
}


