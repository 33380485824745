import { useTranslation } from '@pancakeswap/localization';
import { Button, Flex, Text } from '@pancakeswap/uikit';
import { MINIMUM, PAYMENT, PAYMENT_TOKEN, PRICE_CCQ_MINIMUM_STRING, PRICE_CCQ_STRING, formatNumberPayment } from 'config';
import { LINK_VERICHAINS } from 'config/constants';
import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'state';
import { actionIsShowCCQ, fetchCartItems } from 'state/MarketPlace/actions';
import { CartItem, Items, UserItems } from 'state/MarketPlace/type';
import styled from 'styled-components';

interface PropHeader {
    listItem: Items[],
    dataUser: UserItems,
    account: string,
}

const SubHeader: React.FC<PropHeader> = ({
    listItem,
    dataUser,
    account,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch<AppDispatch>()
    const handleActionIsShowCCQ = React.useCallback((isShow) => dispatch(actionIsShowCCQ(isShow)), [dispatch])
    const [fillPrice, setFillPice] = useState<string>();

    const sortListItem = [...listItem]?.sort((a, b) => b?.unitPrice - a?.unitPrice);
    const addThousandPrice = sortListItem.map((item) => {
        const buysThousandPrice = item.buys.map((b) => ({
            ...b,
            amount: b.amount * item.unitPrice
        }));
        return {
            buys: buysThousandPrice,
            id: item.id,
            addresses: item.nftAddress,
            unitPrice: item.unitPrice
        }
    });


    const handleSearch = () => {
        const resultFilter: CartItem[] = [];
        let amountBuy = Number(fillPrice);
        addThousandPrice.forEach(obj => {
            const buys = obj.buys.map((b) => ({
                ...b,
                amount: b.amount / obj.unitPrice,
            }));
            obj.buys.forEach(item => {
                if (item.amount > 0 && amountBuy > 0) {
                    const quantityToBuy = Math.min(amountBuy, item.amount);
                    resultFilter.push({
                        addresses: obj.addresses,
                        buys,
                        id: item.id,
                        buyAmount: quantityToBuy / obj.unitPrice,
                    });
                    const updatedAmount = item.amount - quantityToBuy;
                    const updatedBuyAmount = amountBuy - quantityToBuy;
                    // eslint-disable-next-line no-param-reassign
                    obj.buys = obj.buys.map(buy =>
                        buy.id === item.id ? { ...buy, amount: updatedAmount } : buy
                    );
                    // eslint-disable-next-line no-param-reassign
                    amountBuy = updatedBuyAmount;
                }
            });
        });
        const result = resultFilter.reduce((acc: CartItem[], pre: CartItem) => {
            const index = acc.findIndex(item => item?.addresses?.toLowerCase() === pre?.addresses?.toLowerCase());
            if (index === -1) {
                acc.push({
                    ...pre,
                    buyAmount: Math.floor(pre.buyAmount)
                })
            }
            else {
                const { buyAmount } = acc[index];
                // eslint-disable-next-line no-param-reassign
                acc[index] = {
                    ...acc[index],
                    buyAmount: Math.floor(buyAmount + pre.buyAmount)
                }
            }
            return acc;
        }, []);
        dispatch(fetchCartItems({ listCart: result }))
        handleActionIsShowCCQ(true)
    }
    return (
        <SearchHeading>
            <CsFirstHeading>{t("I want to buy")}</CsFirstHeading>
            <CsSecondHeading>
                <CsNumericFormat
                    value={fillPrice}
                    decimalScale={PAYMENT === PAYMENT_TOKEN.VND ? 0 : 5}
                    thousandSeparator={!false}
                    placeholder={PRICE_CCQ_MINIMUM_STRING}
                    // eslint-disable-next-line no-return-assign, no-param-reassign
                    onFocus={(e) => e.target.placeholder = ""}
                    onValueChange={(values) => {
                        const { value } = values;
                        if (Number(value) >= 0) {
                            setFillPice(value)
                        }
                    }}
                    isAllowed={() => {
                        // const { value } = values;
                        if (!account)
                            return false;
                        return true;
                    }}
                    maxLength={35}
                    allowNegative={false}
                />
                <Text color='textSubtle'>{`${t('Fund certificates')} (${PAYMENT})`}</Text>
            </CsSecondHeading>
            <CsThirstHeading>
                <Text>{t('Account balance')}</Text>
                <Text style={{ color: "#F9A138", fontWeight: 500 }}>
                    {
                        account ? formatNumberPayment(dataUser?.balance) : "0"
                    }
                    <span style={{ fontWeight: 600, marginLeft: "6px" }}>{PAYMENT}</span>
                </Text>
            </CsThirstHeading>
            <CsThirstHeading>
                <Text>{t('Face value of Fund Certificates')}</Text>

                <Text style={{ color: "#F9A138", fontWeight: 500 }}>{PRICE_CCQ_STRING} <span style={{ fontWeight: 600, marginLeft: "0px" }}>{PAYMENT}</span></Text>
            </CsThirstHeading>
            <CsThirstHeading>
                <Text>{t('Minimum transaction amount')}</Text>                <Text style={{ color: "#F9A138", fontWeight: 500 }}>
                    {PRICE_CCQ_MINIMUM_STRING}
                    <span style={{ fontWeight: 600, marginLeft: "0px" }}> {PAYMENT}</span>
                </Text>
            </CsThirstHeading>
            <WrapButton>
                <CsButtonSearch onClick={handleSearch} disabled={Number(fillPrice) < MINIMUM || !fillPrice}> {t("Search to purchase")}</CsButtonSearch>
            </WrapButton>
            <CsLinkVerichains
                href={LINK_VERICHAINS} target="_blank" rel="noreferrer"
            >
                Audit by Verichains
                <img src="/images/ccqFundgo/export.png?version=1.1" alt="export" />
            </CsLinkVerichains>
        </SearchHeading>
    )
}

export default SubHeader

const CsLinkVerichains = styled.a`
    display:flex;
    color: #13486B;
    font-size: 24px;
    font-weight: 600;
    align-items: center;
    justify-content: center;
    gap: 12px;
    margin-top: 24px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
    @media screen and (max-width: 1024px){
        font-size: 14px;
        gap: 8px;
        margin-top: 14px;
    }
    img{
        width: 24px;
        height: 24px;
        @media screen and (max-width: 1024px){
            width: 14px;
            height: 14px;
        }
    }
`

const CsNumericFormat = styled(NumberFormat)`
    background-color: transparent;
    outline: none;
    &:focus-visible {
        outline: none;
    }
    ::placeholder {
        color:${({ theme }) => theme.colors.text};
        opacity: 1;
    }
`

const WrapButton = styled(Flex)`
    width: 100%;
    justify-content: center;
    margin-top: 34px;
    @media screen and (max-width: 768px){
        margin-top: 17px;
    }
    @media screen and (max-width: 600px){
        margin-top: 10px;
    }
`
const CsButtonSearch = styled(Button)`
    width: 100%;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    height: 75px;
    border-radius: 12px;
    @media screen and (max-width: 768px){
        border-radius: 4px;
        font-size: 14px;
        height: 50px;
        padding: 0 16px;
    }
    @media screen and (max-width: 600px){
        padding: 0 16px;
        font-size: 12px;
        line-height: 14px;
        height: 40px;
    }
`

// start search Heading
const SearchHeading = styled.div`
    text-align: center;
    width: 100%;
    max-width: 620px;
    @media screen and (max-width: 768px){
        padding: 0 36px;
    }
`
const CsFirstHeading = styled(Text)`
    margin-top: 34px;
    letter-spacing: 0.04em;
    font-weight: 700;
    font-size: 40px;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.primary};
    @media screen and (max-width: 600px){
        font-size: 24px;
    }
`
const CsSecondHeading = styled(Flex)`
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 12px 36px;
    margin: 34px auto 0;
    border-radius: 4px;
    @media screen and (max-width: 768px){
        margin: 16px 0;
    }
    @media screen and (max-width: 600px){
        padding: 18px 16px;
    }
    input{
        padding-right: 16px;
        width: 75%;
        border: none;
        color: ${({ theme }) => theme.colors.primary};
        font-weight: 700;
        font-size: 32px;
        line-height: 49px;
        letter-spacing: 0.04em;
        &::placeholder{
            color: ${({ theme }) => theme.colors.textDisabled};
        }
        @media screen and (max-width: 768px){
            font-size: 20px;
            line-height: 49px;
        }
        @media screen and (max-width: 600px){
            font-size: 14px;
            line-height: 16px;
        }
    }
    ${Text}{
        white-space: nowrap;
        font-weight: 400;
        font-size: 24px;
        line-height: 28px;
        @media screen and (max-width: 600px){
            font-size: 10px;
            line-height: 12px;
        }
    }
`
const CsThirstHeading = styled(Flex)`
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    margin-top: 34px;
    @media screen and (max-width: 768px){
        margin-top: 17px;
        max-width: 100%;
    }
    @media screen and (max-width: 600px){
        margin-top: 6px;
    }
    ${Text}{
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: ${({ theme }) => theme.colors.textSubtle};
        @media screen and (max-width: 768px){
            font-size: 18px;
            line-height: 1.5;
        }
        @media screen and (max-width: 600px){
            font-size: 12px;
            line-height: 1.5;
        }
    }
    span{
        color: ${({ theme }) => theme.colors.text};
        margin-left: 4px;
    }
`