import { useTranslation } from "@pancakeswap/localization";
import { Flex, IconDown, Text } from "@pancakeswap/uikit";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionIsOpenCartCCQ, fetchCartItems } from "state/MarketPlace/actions";
import { CartItem, Items } from "state/MarketPlace/type";
import styled from "styled-components";

import { GetDataMarketplace } from "state/MarketPlace";
import { CustomPaginate } from "../components";
import ItemMobile from "./ItemMobile";
import Item from "./ItemPC";

interface PropsMarketplace {
    listItem: Items[];
    windowSize: number;
    listCart: CartItem[];
}

const pageSize = 7;

const Ccq: React.FC<PropsMarketplace> = ({ listItem, windowSize, listCart }) => {
    const { isOpenCartCCQ } = GetDataMarketplace();
    const { t } = useTranslation();
    const [itemOffset, setItemOffset] = useState(0);
    const dispatch = useDispatch<AppDispatch>();

    const handleChoose = (id, addresses, buys) => {
        dispatch(fetchCartItems({ listCart: [...listCart, { buyAmount: 1, id, addresses, buys }] }));
    };
    const handleActionIsOpenCartCCQ = React.useCallback((type) => dispatch(actionIsOpenCartCCQ(type)), [dispatch])

    const filterData = listItem.filter((item) => !listCart.find((data) => Number(data.id === Number(item.id))));

    const total = filterData.length;
    return (
        <>
            {windowSize > 1000 ? (
                <>
                    {listCart?.length > 0 &&
                        <Flex mt="32px" mb="32px" alignItems="center" style={{ gap: "10px" }}>
                            <Text color="#C7C9CA">{t("View more Fund Certificates")}</Text>
                            <Text
                                style={{
                                    cursor: "pointer",
                                    transform: isOpenCartCCQ ? "rotate(180deg)" : "rotate(0deg)"
                                }}
                                onClick={() => handleActionIsOpenCartCCQ(!isOpenCartCCQ)}
                            >
                                <IconDown />
                            </Text>
                        </Flex>
                    }
                    {listItem?.length !== 0 && isOpenCartCCQ && (
                        filterData.splice(itemOffset, pageSize).map((item) => (
                            <Item {...item} handleChoose={handleChoose} />
                        ))
                    )}
                </>
            ) : (
                <>
                    {listItem?.length !== 0 && (
                        <>
                            <FlexAnother>
                                <Flex alignItems="center">
                                    {/* <ListIcon /> */}
                                    {listCart?.length > 0 &&
                                        <Flex mt="32px" mb="32px" alignItems="center" style={{ gap: "10px" }}>
                                            <TextAnother>{t("View more Fund Certificates")}</TextAnother>
                                            <Text
                                                style={{
                                                    cursor: "pointer",
                                                    transform: isOpenCartCCQ ? "rotate(180deg)" : "rotate(0deg)"
                                                }}
                                                onClick={() => handleActionIsOpenCartCCQ(!isOpenCartCCQ)}
                                            >
                                                <IconDown />
                                            </Text>
                                        </Flex>
                                    }
                                </Flex>
                                <Flex alignItems="center">
                                    <TextAddToCart>{t("Add to cart")}</TextAddToCart>
                                </Flex>
                            </FlexAnother>
                            {
                                isOpenCartCCQ &&
                                <CsFlexMobile>
                                    {filterData.splice(itemOffset, pageSize).map((item) => (
                                        <ItemMobile {...item} handleChoose={handleChoose} />
                                    ))}
                                </CsFlexMobile>
                            }
                        </>
                    )}
                </>
            )}
            {listItem?.length > 7 && <CustomPaginate total={total} pageSize={pageSize} setItemOffset={setItemOffset} />}
        </>
    );
};

export default Ccq;

const FlexAnother = styled(Flex)`
    width: 100%;
    align-items: center;
    height: 40px;
    padding: 0 16px;
    background: #efefef;
    justify-content: space-between;
`;

const TextAnother = styled(Text)`
    color: ${({ theme }) => theme.colors.text};
    margin-left: 14px;
    font-size: 12px;
`;
const TextAddToCart = styled(Text)`
    color: ${({ theme }) => theme.colors.textDisabled};
    margin-right: 6px;
    font-size: 10px;
`;


const CsFlexMobile = styled(Flex)`
    flex-direction: column;
`;
