import { useTranslation } from "@pancakeswap/localization";
import { Text } from "@pancakeswap/uikit";
import clsx from 'clsx';
import { dataUser } from 'config/auth';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actionSearchFundMember } from 'state/MarketPlace/actions';
import styled from 'styled-components';

const activeAccount = dataUser.find((item) => item.type === 2);
export const FundMember = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const handleSearchFundMember = React.useCallback((member: string) => dispatch(actionSearchFundMember(member)), [dispatch]);
    const [account, setAccount] = React.useState<string>(() => activeAccount.account)

    return (
        <div>
            <Text fontWeight={600} ml="16px">{t("Publisher")}</Text>
            <CsFundMember>
                {
                    dataUser.map((item) => (
                        <div
                            className={clsx("item-fund-member", item.nickName)}
                            key={item.account}
                            onClick={() => {
                                handleSearchFundMember(item.type === 1 ? null : item.account);
                                setAccount(item.account);
                            }}
                            aria-hidden="true"
                        >
                            {
                                item.nickName === "FUNDGO" ? <Text style={{ cursor: "pointer", position: "relative", top: "20px" }} /> : <img src={`${item.image}?version=1.8`} alt={item.nickName} style={{
                                    opacity: account === item.account ? '1' : "0.3",
                                    filter: account === item.account ? 'blur(0px)' : "blur(0.5px)",
                                }} />
                            }
                        </div>
                    ))
                }
            </CsFundMember>
        </div>
    )
}

const CsFundMember = styled.div`
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    @media screen and (max-width: 768px) {
        margin: 0 16px;
    }
    .item-fund-member.Trust.Card,.item-fund-member.DTS{
        position: relative;
        top: 11px;
    }
    .item-fund-member.JadeLabs{
        top: 9px;
    }
    .item-fund-member{
        position: relative;
        img{
            width: 140px;
            cursor: pointer;
            height: 40px;
            object-fit: scale-down;
            @media screen and (max-width: 768px) {
                width: 70px;
                height: 40px;
            }
        }
    }
`