import { UseActiveCCQ } from 'config/constants/ccqSupport/constants'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchListOrder } from 'state/Order/fetchData'
import { AppDispatch, AppState } from '../index'
import { fetchAssetItems, fetchStatusLoading, getBalanceAccount, getUserBalance } from './actions'
import { fetchDataAssetItems, fetchDataBalanceAccount, fetchIntervestTermRate, fetchUserAssest, fetchUserBalance } from './fetchData'
import { ListAssestItem } from './type'


export const GetItemAsset = () => {
    const data = useSelector<AppState, AppState['asset']>((state) => state.asset)
    const itemAssetValue = data?.itemAsset
    return itemAssetValue
}

export const UseCoreAsset = (tokenSupport: string, account: string, chainId: number) => {
    const dispatch = useDispatch<AppDispatch>()
    const { messageHash } = GetDataAsset();

    const getDataAsset = React.useCallback(async () => {
        try {
            if (chainId && account) {
                const activeCCQ = await UseActiveCCQ(chainId)

                const { listItemOrder } = await fetchListOrder(activeCCQ, account, chainId);
                const resultWrappedInfo = await fetchDataAssetItems(activeCCQ, account, chainId)
                const resultAsset = await fetchUserAssest(activeCCQ, account, chainId)
                const resultInvertRate = await fetchIntervestTermRate(activeCCQ, chainId)
                const resultBalance = await fetchUserBalance(resultAsset?.listUserAsset, resultWrappedInfo?.listWrappedItem, resultInvertRate?.listIntervestRate, account, chainId, listItemOrder)
                const resultBalanceAccount = await fetchDataBalanceAccount(tokenSupport, account, chainId)

                dispatch(fetchAssetItems(resultWrappedInfo))
                dispatch(getUserBalance(resultBalance))

                dispatch(getBalanceAccount(resultBalanceAccount))
            } else {
                dispatch(fetchAssetItems({ listWrappedItem: [] }))
                dispatch(getUserBalance({ listUserBalance: [] }))
            }
        } catch (e) {
            console.log(e)
        }
    }, [account, chainId, dispatch, tokenSupport])

    useEffect(() => {
        (async () => {
            dispatch(fetchStatusLoading({ isLoading: true }))
            await getDataAsset();
            dispatch(fetchStatusLoading({ isLoading: false }))
        })();
    }, [dispatch, getDataAsset, messageHash]);


    useEffect(() => {
        const timer = setInterval(() => {
            getDataAsset();
        }, 30000);
        return () => clearTimeout(timer);
    }, [getDataAsset]);
}


export const GetDataAsset = () => {
    const dataAsset = useSelector<AppState, AppState['asset']>((state) => state.asset);
    const { listUserBalance, isLoading, messageHash } = dataAsset;


    const filterDataAddress = listUserBalance.reduce((acc, pre: ListAssestItem) => {
        const index = acc.findIndex((item) => item?.nftAddress?.toLowerCase() === pre?.nftAddress?.toLowerCase() && item?.holdingDate === pre?.holdingDate);
        if (index === -1) {
            acc.push({
                ...pre,
                buys: [
                    {
                        nftBalance: pre.nftBalance,
                        nftId: pre.nftId,
                        fullVestingMap: pre.fullVestingMap
                    },
                ]
            });
        } else {
            const item = [...acc[index].buys];
            item.push({
                nftBalance: pre.nftBalance,
                nftId: pre.nftId,
                fullVestingMap: pre.fullVestingMap
            })
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                nftBalance: acc[index].nftBalance + pre.nftBalance,
                buys: item
            }
        }
        return acc;
    }, []);
    return {
        listUserBalance: [...filterDataAddress].sort((a, b) => b?.holdingDate - a?.holdingDate),
        isLoading,
        messageHash
    }
}