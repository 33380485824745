import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { ToastDescriptionWithTx } from "components/Toast";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useWrapped } from "hooks/useContract";
import { useUserRejected } from "hooks/useUserRejected";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { actionDispatchMessage } from "state/Order/actions";

interface BuysType {
    amountTrading: number,
    orderId: number,
}

export const useDelistp2p = (
    account: string,
    chainId: number,
    nftAddress: string,
    buys: BuysType[],
    onDismiss: any
) => {
    const { t } = useTranslation();
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const [pendingDelistp2p, setPenddingDelistp2p] = useState(false);
    const contractWrapped = useWrapped(nftAddress);
    const dispatch = useDispatch<AppDispatch>();
    const { handleRejected } = useUserRejected();

    const handleDelistp2p = useCallback(async () => {
        setPenddingDelistp2p(true);
        try {
            const orderIds = buys.map((item) => (item.orderId));
            const tx = await callWithMarketGasPrice(contractWrapped, "deListingP2P", [orderIds]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Cancellation confirmation successful"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setPenddingDelistp2p(false);
                onDismiss()
                dispatch(actionDispatchMessage({ messageHash: receipt.transactionHash }));
            } else {
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
                setPenddingDelistp2p(false);
            }
        } catch (e) {
            handleRejected(e);
            setPenddingDelistp2p(false);
        }
    }, [buys, callWithMarketGasPrice, contractWrapped, dispatch, handleRejected, onDismiss, t, toastError, toastSuccess]);
    return { handleDelistp2p, pendingDelistp2p };
};
