import { useTranslation } from "@pancakeswap/localization";
import { Button, CloseIcon, Flex, IconSearchNft, Input, MinusIcon, PlusIcon, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { UNIT_DATE, formatNumberPayment } from "config";
import { useModal } from "hooks/use-modal";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";

interface PropsItem {
    index: number;
    buyAmount: number;
    listItem: Items;
    handleChangeAmountBuy: (amount, id, addresses, buys) => void;
    handleCancel: (id) => void;
}

const ItemBuyMobile: React.FC<PropsItem> = ({ buyAmount, listItem, handleChangeAmountBuy, handleCancel }) => {
    const { toggleModal, ModalTypeEnum } = useModal();
    const { t } = useTranslation()
    const handleChangeInput = (e) => {
        const { value } = e.target;
        if (/^\d+$/.test(value) || value === "") {
            let convertNumber = value;
            if (Number(convertNumber) < 1) {
                convertNumber = '';
            } else if (convertNumber > Number(listItem?.amount)) {
                convertNumber = listItem?.amount;
            }
            handleChangeAmountBuy(Number(convertNumber), listItem.id, listItem.nftAddress, listItem.buys);
        }
    };

    const totalVested = useMemo(() => {
        return listItem?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [listItem?.vestingMap])

    const totalVestingMap = useMemo(() => {
        return listItem?.vestingMap?.length
    }, [listItem?.vestingMap])

    const total = new BigNumber(listItem?.unitPrice).multipliedBy(buyAmount).toString();
    return (
        <WrapperBuyItem>
            <CsWrapIcon width="10%">
                <CsCloseIcon onClick={() => handleCancel(listItem.id)} color="red" />
            </CsWrapIcon>
            <CsFlex2>
                <CsItemCardMobile>
                    <Flex>
                        <Text fontSize="12px" color="textSubtle">
                            {t("Quantity")}:
                        </Text>
                        <CsText className="relative">
                            &nbsp;
                            {formatNumberPayment(listItem.amount)}
                        </CsText>
                    </Flex>
                </CsItemCardMobile>
                <CsItemCardSmall alignItems="center" justifyContent="center">
                    <CsText>
                        {listItem.interVestTerm} {listItem?.interVestTerm > 1 ? t("months") : t("month")}
                    </CsText>
                </CsItemCardSmall>
                <CsItemCardSmall alignItems="center" justifyContent="center">
                    <CsText>
                        {listItem?.interVestTermRate}%
                    </CsText>
                </CsItemCardSmall>
                <CsItemCardMobile alignItems="center" justifyContent="flex-start">
                    <CsText>
                        {formatNumberPayment(listItem.price)}
                    </CsText>
                </CsItemCardMobile>
            </CsFlex2>
            <CsFlex2>
                <CsItemCardMobile>
                    <Flex alignItems="center">
                        <Text fontSize="12px" color="textSubtle">
                            {t("Unit price")}:
                        </Text>
                        <CsText>
                            &nbsp;
                            {formatNumberPayment(listItem.unitPrice)}
                        </CsText>
                    </Flex>
                </CsItemCardMobile>
                <CsItemCardSmall alignItems="center" justifyContent="center">
                    <CsText>
                        {listItem?.dayDiff} {t(UNIT_DATE)}
                    </CsText>
                </CsItemCardSmall>
                <CsItemCardSmall alignItems="center" justifyContent="center">
                    <CsText>
                        {totalVested}/{totalVestingMap}
                    </CsText>
                </CsItemCardSmall>
                <CsItemCardMobile alignItems="center" justifyContent="flex-start">
                    <CsText>
                        {listItem?.paydayNextProfit}
                    </CsText>
                </CsItemCardMobile>
            </CsFlex2>

            <CsFlex3>
                <Flex width="60%" alignItems="center" justifyContent="space-between">
                    <Text width="60%" fontSize="12px">
                        {t("Select quantity")}
                    </Text>
                    <WrapCount>
                        <ButtonQuanlity
                            disabled={Number(buyAmount) <= 1}
                            onClick={() => handleChangeAmountBuy(Number(buyAmount) - 1, listItem.id, listItem.nftAddress, listItem.buys)}
                        >
                            <MinusIcon />
                        </ButtonQuanlity>

                        <CustomInput
                            type="text"
                            scale="lg"
                            inputMode="numeric"
                            value={buyAmount}
                            placeholder="0"
                            onChange={handleChangeInput}
                        />
                        <ButtonQuanlity
                            onClick={() => handleChangeAmountBuy(Number(buyAmount) + 1, listItem.id, listItem.nftAddress, listItem.buys)}
                            disabled={Number(buyAmount) >= Number(listItem?.amount)}
                        >
                            <PlusIcon />
                        </ButtonQuanlity>
                    </WrapCount>
                </Flex>
                <CsText width="50%">
                    {formatNumberPayment(total)}
                </CsText>
            </CsFlex3>
            <p
                aria-hidden="true"
                className="cursor-pointer absolute left-2 bottom-1"
                onClick={() => {
                    toggleModal({
                        type: ModalTypeEnum.MODAL_SEARCH_CONTRACT,
                        title: "",
                        data: {
                            contract: listItem?.nftAddress.toLowerCase()
                        }
                    })
                }}
            >
                <IconSearchNft className="w-3 h-3" />
            </p>
        </WrapperBuyItem>
    );
};

const CsText = styled(Text)`
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    &:first-child {
        text-align: left;
    }
`;

const CsCloseIcon = styled(CloseIcon)`
    padding: 2px;
    width: 22px;
    cursor: pointer;
    border-radius: 4px;
    background: rgba(238, 68, 55, 0.12);
`;

const CsWrapIcon = styled(Flex)`
    width: 10%;
    justify-content: flex-end;
    position: absolute;
    top:22px;
    right: 22px;
`;

const CsFlex2 = styled(Flex)`
    width: 100%;
    align-items: center;
    /* grid-template-columns: repeat(5, minmax(0, 1fr));
    display: grid; */
`;
const CsFlex3 = styled(Flex)`
    background: rgba(239, 239, 239, 0.8);
    border-radius: 4px;
    padding: 6px 15px;
    align-items: center;
`;

const CustomInput = styled(Input)`
    background: none;
    color: ${({ theme }) => theme.colors.white};
    text-align: center;
    box-shadow: none;
    border: none;
    height: 100%;
    width: 100%;
    padding: 0;
    font-size: 16px;
    border-radius: 0;
    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
    }
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
`;

const ButtonQuanlity = styled(Button)`
    border: 1px solid transparent;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    box-shadow: none;
    padding: 8px;
    height: 16px;
    width: 16px;
    cursor: pointer;
    &:hover {
        background: ${({ theme }) => theme.colors.white};
    }
`;

const CsItemCardMobile = styled(Flex)`
    width: 25%;
    height: 42px;
    align-items: center;
    font-size: 12px;
`

const CsItemCardSmall = styled(Flex)`
    width: 22.5%;
    height: 42px;
    align-items: center;
    font-size: 12px;
`


const WrapperBuyItem = styled.div`
    width: 100%;
    margin-top: 2px;
    overflow: hidden;
    padding: 8px 16px;
    background: ${({ theme }) => theme.colors.white};
    position: relative;
`;
const WrapCount = styled(Flex)`
    gap: 6px;
    align-items: center;
    background: ${({ theme }) => theme.colors.success3};
    border-radius: 4px;
    width: 86px;
    height: 28px;
    padding: 4px;
`;
export default ItemBuyMobile;
