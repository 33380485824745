import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import {
    selectCurrentYield,
    selectDenominations,
    selectNextInterestPaymentDate,
    selectPaymentPeriod
} from "state/MarketPlace/actions";
import styled from "styled-components";

import Select, { OptionProps } from "components/Select/Select";
import { PRICE_CCQ, formatNumberPayment } from "config";

const HeaderSort = () => {
    const { t } = useTranslation();
    const windowSize = GetWindowSize();
    const dispatch = useDispatch<AppDispatch>();
    // select mệnh giá
    const handleSelectDenominations = (option: OptionProps): void => {
        dispatch(selectDenominations({ denominations: Number(option?.value) }));
    };

    // select Kỳ trả lợi suất
    const handeleSelectPaymentPeriod = (option: OptionProps): void => {
        dispatch(selectPaymentPeriod({ paymentPeriod: Number(option?.value) }));
    };

    // Số ngày đến kì trả lợi suất
    const handleSelectNextInterestPaymentDate = (option: OptionProps): void => {
        dispatch(selectNextInterestPaymentDate({ nextInterestPaymentDate: Number(option?.value) }));
    };

    // select Lợi suất kì này`
    const handleSelectCurrentYield = (option: OptionProps): void => {
        dispatch(selectCurrentYield({ currentYield: Number(option?.value) }));
    };
    return (
        <>
            {windowSize > 1000 && (
                <Flex
                    padding=" 0 12px"
                    mt={["0", , "1rem"]}
                    mb="1rem"
                    width="100%"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="space-between"
                    flexDirection={["column", , , "row"]}
                    
                >
                    <CsFlexSort
                        justifyContent="flex-start"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "auto"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "13px"]} mr="8px">
                            {t(`Face value`)}
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("All"),
                                    value: 0
                                },
                                {
                                    label: `${formatNumberPayment(PRICE_CCQ)}`,
                                    value: PRICE_CCQ
                                }
                            ]}
                            onOptionChange={handleSelectDenominations}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>

                    <CsFlexSort
                        justifyContent="flex-start"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "auto"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "13px"]} mr="8px">
                            {t(`Yield payment period`)}
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("All"),
                                    value: 0
                                },
                                {
                                    label: `3 ${t("months")}`,
                                    value: 3
                                },
                                {
                                    label: `6 ${t("months")}`,
                                    value: 6
                                }
                            ]}
                            onOptionChange={handeleSelectPaymentPeriod}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>

                    <CsFlexSort
                        justifyContent="center"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "auto"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "13px"]} mr="8px">
                            {t("Number of days")}<br />{t("to yield payment")}
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("All"),
                                    value: 0
                                },
                                {
                                    label: t("Less than 10 days"),
                                    value: 1
                                },
                                {
                                    label: t("10 - 30 days"),
                                    value: 2
                                },
                                {
                                    label: t("30 - 60 days"),
                                    value: 3
                                },
                                {
                                    label: t("Over 60 days"),
                                    value: 4
                                }
                            ]}
                            onOptionChange={handleSelectNextInterestPaymentDate}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>

                    <CsFlexSort
                        justifyContent="flex-end"
                        padding={["12px 0", , , "0"]}
                        alignItems="center"
                        width={["100%", , , "auto"]}
                    >
                        <Text textAlign="right" fontSize={["12px", , "13px"]} mr="8px">
                            {t(`Current period yield`)}
                        </Text>
                        <Select
                            options={[
                                {
                                    label: t("All"),
                                    value: 0
                                },
                                {
                                    label: t("8%"),
                                    value: 8
                                },
                                {
                                    label: t("9.2%"),
                                    value: 9.2
                                }
                            ]}
                            onOptionChange={handleSelectCurrentYield}
                            defaultOptionIndex={0}
                        />
                    </CsFlexSort>
                </Flex>
            )}
        </>
    );
};

export default HeaderSort;

const CsFlexSort = styled(Flex)`
    /* justify-content: center; */
`;
