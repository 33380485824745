
import { useTranslation } from "@pancakeswap/localization";
import { DatePicker } from "antd";
import dayjs from 'dayjs';

import {
    ArrowLeftIcon,
    ArrowRIghtIcon,
    AutoRenewIcon,
    Flex,
    HelpIconCcqIcon,
    Input,
    InputGroup,
    OpenNewIconFundgo,
    PlayIcon,
    SearchIcon,
    Text,
    useModal
} from "@pancakeswap/uikit";
import { CustomTooltip } from "components/CustomTooltip";
import SelectCustom from "components/Select/SelectCustom";
import { PAYMENT, formatNumberPayment } from "config";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import React, { useMemo, useState } from "react";
import ReactPaginate from "react-paginate";
import { useAppDispatch } from "state";
import { GetListHistory, UseCoreListHistory } from "state/history";
import {
    fetchNumberPageCurrent,
    itemHistory,
    selectEndDay,
    selectStartDay,
    selectType
} from "state/history/actions";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";
import { convertDate } from "utils/converDate";
import { renderAddressByType, renderStatus, renderType } from "utils/renderProfit";
import DetailModal from "./components/ModalDetail";
import { CustomLink } from "./styles";

interface OptionProps {
    label: string;
    value: number;
}

const pageSize = 18;
const TabsHistory = ({ windowSize }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { chainId, account } = useActiveWeb3React();
    // history
    const [searchQuery, setSearchQuery] = useState("")
    const dataHistory = GetListHistory();
    const { isLoading } = dataHistory
    const convertStartDate = new Date(dataHistory?.startDay).toISOString()
    const convertEndDate = new Date(dataHistory?.endDay).toISOString()

    UseCoreListHistory(account, pageSize, dataHistory?.pagenumbermkl, convertStartDate, convertEndDate, searchQuery, dataHistory?.type, chainId);

    const listHistory = dataHistory?.listItemHistory;

    const filterDataHistory = listHistory.reduce((acc, pre) => {
        const index = acc.findIndex(item =>
            item?.transaction?.toLowerCase() === pre?.transaction?.toLowerCase() &&
            item?.etfContract?.toLowerCase() === pre?.etfContract?.toLowerCase()
        );
        if (index === -1) {
            acc.push(pre);
        } else {
            // eslint-disable-next-line no-param-reassign
            acc[index] = {
                ...acc[index],
                jointsOrderQuantity: acc[index].jointsOrderQuantity + pre.jointsOrderQuantity,
                totalTxValue: acc[index].totalTxValue + pre.totalTxValue
            }
        }
        return acc;
    }, []);

    function handleOpenLink(address, type) {
        window.open(getBlockExploreLink(address, type, chainId));
    }

    function csAddress(dataAddress) {
        if (dataAddress) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }

    const [openDetailModal] = useModal(<DetailModal handleOpenLink={handleOpenLink} csAddress={csAddress} />);

    // select sort value
    const handleSelectType = async (option: OptionProps) => {
        await dispatch(fetchNumberPageCurrent({ pagenumbermkl: 0 }))
        await dispatch(selectType({ type: option.value?.toString() }));
    };

    const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(fetchNumberPageCurrent({ pagenumbermkl: 0 }))
        setSearchQuery(event.target.value)
    };

    const handleOpenModalDetail = async (id) => {
        const findItconfirm = filterDataHistory?.find((item) => item?.historyId === id);
        await dispatch(itemHistory({ itemHistory: findItconfirm }));
        openDetailModal();
    };


    const handlePageClick = (event) => {
        dispatch(fetchNumberPageCurrent({ pagenumbermkl: event.selected }))
    };

    const pageCount = useMemo(() => {
        return Math.ceil(dataHistory?.totalItem / pageSize)
    }, [dataHistory?.totalItem]);

    return (
        <>
            <WrapperTable>
                <CsWrapFilter>
                    <FlexLeft>
                        <CsFlexSort alignItems="center">
                            <Text color="textSubtle" fontSize={["12px", , "14px"]} mb="5px">
                                {t(`Command type`)}
                            </Text>
                            <SelectCustom
                                options={[
                                    {
                                        label: t("All"),
                                        value: ""
                                    },
                                    {
                                        label: t("Buy"),
                                        value: "buy"
                                    },
                                    {
                                        label: t("Sell Now"),
                                        value: "sellNow"
                                    },
                                    {
                                        label: t("Sell Deal"),
                                        value: "sellP2P"
                                    },
                                ]}
                                onOptionChange={handleSelectType}
                                defaultOptionIndex={0}
                            />
                        </CsFlexSort>
                        <CustomFlex flexDirection="column">
                            <Text mb="5px" color="textSubtle" fontSize={["12px", , "14px"]}>
                                {t("From date")}
                            </Text>
                            <CsDatePicker>
                                <DatePicker
                                    onChange={(event) => {
                                        dispatch(selectStartDay({ startDay: event.toISOString() }));
                                    }}
                                    disabledDate={(current) => {
                                        return current && current.valueOf() > Date.now();
                                    }}
                                    size="large"
                                    allowClear={false}
                                    bordered={false}
                                    style={{ width: "100%" }}
                                    defaultValue={dayjs(dayjs(), "DD/MM/YYYY")} format="DD/MM/YYYY"
                                />
                            </CsDatePicker>
                        </CustomFlex>
                        <CustomFlex flexDirection="column">
                            <Text mb="5px" color="textSubtle" fontSize={["12px", , "14px"]}>
                                {t("To date")}
                            </Text>
                            <CsDatePicker>
                                <DatePicker
                                    onChange={(event) => {
                                        dispatch(selectEndDay({ endDay: event.toISOString() }));
                                    }}
                                    size="large"
                                    allowClear={false}
                                    bordered={false}
                                    style={{ width: "100%" }}
                                    defaultValue={dayjs(dayjs(), "DD/MM/YYYY")} format="DD/MM/YYYY"
                                    disabledDate={(current) => {
                                        return current && current.valueOf() > Date.now();
                                    }}
                                />
                            </CsDatePicker>
                        </CustomFlex>
                    </FlexLeft>
                    <FilterControls>
                        <CustomInputGroup startIcon={<SearchIcon color="textDisabled" width="24px" />}>
                            <Input
                                value={searchQuery}
                                placeholder={t("Search by transaction code")}
                                onChange={handleChangeSearchQuery}
                            />
                        </CustomInputGroup>
                    </FilterControls>
                </CsWrapFilter>
                {windowSize > 1000 ? (
                    <>
                        <WrapperTableHeader>
                            <Text width="15%">{t(`Command type`)}</Text>
                            <Text width="15%">{t("Transaction code")}</Text>
                            <Text width="15%">{t("Transaction date")}</Text>
                            <Text width="15%">{t("Quantity")}</Text>
                            <CsFlexHeader width="15%">
                                <Text mr='0.2rem'>{t("Total value")}</Text>
                                <CustomTooltip title={`${t("Total transaction value")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <CsFlexHeader width="15%">
                                <Text mr='0.2rem'>{t("Address")}</Text>
                                <CustomTooltip title={t("Transaction wallet address")}>
                                    <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <Text width="20%">{t("Status")}</Text>
                        </WrapperTableHeader>
                        <WrapperTableContent>
                            {filterDataHistory?.length > 0 ? (
                                <>
                                    {filterDataHistory.map((item) => {
                                        const {
                                            jointsOrderQuantity,
                                            transaction,
                                            dayTrading,
                                            status,
                                            isSeller,
                                            seller,
                                            buyer,
                                            eventType,
                                            totalTxValue
                                        } = item;
                                        return (
                                            <WrapItem>
                                                <Flex justifyContent="center" width="15%">
                                                    {renderType(isSeller, eventType)}
                                                </Flex>
                                                <CustomLink width="15%"
                                                    onClick={() => handleOpenLink(transaction, "transaction")}
                                                >
                                                    <Text>{csAddress(transaction)}</Text>
                                                    <OpenNewIconFundgo />
                                                </CustomLink>
                                                <Text width="15%">{convertDate(dayTrading)}</Text>
                                                <Text width="15%">{jointsOrderQuantity}</Text>
                                                <Text width="15%">
                                                    {formatNumberPayment(totalTxValue)}
                                                </Text>
                                                <CustomLink
                                                    width="15%"
                                                    onClick={() =>
                                                        handleOpenLink(renderAddressByType(isSeller, isSeller ? buyer : seller, isSeller ? buyer : seller), "address")
                                                    }
                                                >
                                                    <CustomTooltip title={renderAddressByType(isSeller, isSeller ? buyer : seller, isSeller ? buyer : seller)}>
                                                        <Text>{csAddress(renderAddressByType(isSeller, isSeller ? buyer : seller, isSeller ? buyer : seller))}</Text>
                                                    </CustomTooltip>
                                                    <OpenNewIconFundgo />
                                                </CustomLink>
                                                <WraperBtnSell width="15%">
                                                    {renderStatus(status)}
                                                </WraperBtnSell>
                                            </WrapItem>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <Text width="100%" textAlign="center" mt="2rem">
                                        {t("No data")}
                                    </Text>
                                </>
                            )}
                        </WrapperTableContent>
                    </>
                ) : (
                    <>
                        <WrapperTableMobile>
                            {filterDataHistory?.length > 0 ? (
                                <>
                                    {filterDataHistory?.map((item) => {
                                        const {
                                            historyId,
                                            jointsOrderQuantity,
                                            transaction,
                                            dayTrading,
                                            status,
                                            isSeller,
                                            eventType,
                                            totalTxValue
                                        } = item;
                                        return (
                                            <WrapItemMobile>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    mt="0.5rem"
                                                    alignItems="center"
                                                    flexWrap="wrap"
                                                >
                                                    <Flex justifyContent="left" width="25%" style={{
                                                        position: "relative",
                                                        right: "18px"
                                                    }}>
                                                        <PlayIcon
                                                            onClick={() => handleOpenModalDetail(historyId)}
                                                            width="20px"
                                                            style={{ cursor: "pointer" }}
                                                        />
                                                        {renderType(isSeller, eventType)}
                                                    </Flex>
                                                    <Text width="25%">
                                                        <CustomLink
                                                            onClick={() => handleOpenLink(transaction, "transaction")}
                                                        >
                                                            <Text style={{ width: "65px", marginRight: "5px" }}>{csAddress(transaction)}</Text>
                                                            <OpenNewIconFundgo />
                                                        </CustomLink>
                                                    </Text>
                                                    <Text width="25%" style={{ textAlign: "right" }}>{convertDate(dayTrading)}</Text>
                                                </Flex>
                                                <Flex
                                                    width="100%"
                                                    justifyContent="space-between"
                                                    mt="0.5rem"
                                                    alignItems="center"
                                                    flexWrap="wrap"
                                                >
                                                    <Text width="30%">{jointsOrderQuantity}</Text>
                                                    <Text width="30%" style={{ textAlign: "center" }}>
                                                        {formatNumberPayment(totalTxValue)}
                                                    </Text>
                                                    <Flex width="30%" justifyContent="flex-end">
                                                        {renderStatus(status)}
                                                    </Flex>
                                                </Flex>
                                            </WrapItemMobile>
                                        );
                                    })}
                                </>
                            ) : (
                                <>
                                    <Text width="100%" textAlign="center" mt="2rem">
                                        {t("No data")}
                                    </Text>
                                </>
                            )}
                        </WrapperTableMobile>
                    </>
                )}
            </WrapperTable>
            {
                isLoading ?
                    <Flex width="100%" justifyContent="center" mt="3rem">
                        <AutoRenewIcon spin color="textSubtle" />
                    </Flex>
                    :
                    <>
                        {
                            filterDataHistory.length !== 0 ?
                                <CustomPagination width="100%" mt="1rem" justifyContent="center" height="62px">
                                    <ReactPaginate
                                        nextLabel={<ArrowRIghtIcon />}
                                        previousLabel={<ArrowLeftIcon />}
                                        onPageChange={handlePageClick}
                                        pageCount={pageCount}
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakLabel="..."
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                        forcePage={dataHistory?.pagenumbermkl}
                                    />
                                </CustomPagination>
                                : ''
                        }
                    </>
            }
        </>
    );
};

const CsDatePicker = styled.div`
    border: 1px solid #014235;
    padding: 2px 0;
    border-radius: 8px;
    input{
        font-size: 13px !important;
    }
`

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
`

const WrapItemMobile = styled.div`
    padding: 0 12px 12px;
    margin-top: 16px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.background};
    ${Text} {
        font-size: 12px;
        font-weight: 600;
        &:last-child {
            text-align: left;
        }
    }
`;

const WrapperTableMobile = styled(Flex)`
    width: 100%;
    padding-bottom: 20px;
    flex-direction: column;
`;

const CsWrapFilter = styled(Flex)`
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 2rem;
    @media only screen and (max-width: 1000px) {
        width: 100%;
        margin-top: 1rem;
        flex-direction: column;
    }
`;
const FlexLeft = styled(Flex)`
    width: 50%;
    gap: 10px;
    justify-content: space-between;
    @media only screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const CustomFlex = styled(Flex)`
    width: 33%;
    @media only screen and (max-width: 1000px) {
    }
`;
const WrapperTable = styled.div`
    width: 100%;
`;

const WrapperTableHeader = styled(Flex)`
    width: 100%;
    height: 100%;
    position: relative;
    flex-direction: row;
    border-radius: 20px;
    margin: 40px 0 0px 0;
    padding: 0 26px;
    align-items: center;
    justify-content: space-between;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;
const WrapperTableContent = styled(Flex)`
    flex-direction: column;
`;

const WrapItem = styled(Flex)`
    width: 100%;
    height: 100%;
    height: 71px;
    padding: 26px;
    align-items: center;
    position: relative;
    flex-direction: row;
    border-radius: 20px;
    margin-top: 24px;
    justify-content: space-between;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    ${Text} {
        text-align: center;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
    }
    button {
        color: ${({ theme }) => theme.colors.red};
        background: transparent;
        border-radius: 4px;
        font-weight: 700;
        height: 40px;
        width: 143px;
        padding: 0 11px;
        font-size: 14px;
    }
`;

const WraperBtnSell = styled(Flex)`
    justify-content: center;
    width: 20%;
`;
const CsFlexSort = styled(Flex)`
    align-items: flex-start;
    flex-direction: column;
    flex: 1;
`;
const FilterControls = styled(Flex)`
    width: 100%;
    flex-wrap: wrap;
    max-width: 450px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 1000px) {
        margin-top: 1rem;
        max-width: 100%;
    }
`;
const CustomInputGroup = styled(InputGroup)`
    border-radius: 12px;
    border: 1px solid transparent;
    background: transparent !important;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    > input {
        background: ${({ theme }) => theme.colors.white} !important;
        border: none;
        border-radius: 0;
        height: 48px;
        font-size: 16px;
        @media screen and (max-width: 1000px) {
            background: transparent !important;
            height: 42px;
            font-size: 14px;
        }
        @media screen and (max-width: 600px) {
            font-size: 12px;
            height: 42px;
            background: transparent !important;
        }
        ::placeholder {
            color: ${({ theme }) => theme.colors.textDisabled};
        }
    }
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;

const CustomPagination = styled(Flex)`
    margin-bottom: 1.5rem;
    .pagination {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        @media screen and (max-width: 600px) {
            width: 100%;
        }
        * {
            list-style-type: none;
        }
    }
    .page-item {
        margin: 0 0.375rem;
        &:first-child,
        &:last-child {
            a {
                &:hover {
                    color: #13486b;
                    border: none !important;
                }
            }
        }
        &:first-child,
        &:last-child {
            a {
                border: none;
                background: none !important;
            }
        }
        &:last-child.disabled,
        &:first-child.disabled {
            cursor: not-allowed;
        }
    }
    .page-link {
        width: 32px;
        height: 32px;
        display: flex;
        background: #fff;
        align-items: center;
        justify-content: center;
        border-radius: 8px !important;
        color: #02111b;
        border: 1px solid #efefef;
        @media screen and (max-width: 768px) {
            padding: 12px;
        }
        &:focus {
            box-shadow: none !important;
        }
        &:hover {
            background: #13486b;
            color: #fff;
            border: 1px solid #13486b !important;
        }
    }
    .page-item.disabled .page-link {
        background: ${({ theme }) => theme.colors.disabled};
        cursor: not-allowed !important;
        opacity: 0.7;
        pointer-events: none;
    }
    .page-item.active .page-link {
        background: #13486b;
        color: #fff;
        border: none;
    }
`;


export default TabsHistory;
