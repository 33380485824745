
import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, Text } from "@pancakeswap/uikit";
import React, { useMemo } from "react";
import { ListAssestItem } from "state/asset/type";
import styled from "styled-components";
import { PAYMENT, UNIT_DATE, formatNumberPayment } from "config";
import BigNumber from "bignumber.js";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import { CsModal, CsTextModalDetail } from "../styles";

interface DetailProps {
    onDismiss?: () => void;
    onSellModal?: any;
    item: ListAssestItem;
    yieldsReceived?: string;
    totalVested?: number;
    totalVestingMap?: number;
}

const DetailModal: React.FC<DetailProps> = ({ item, yieldsReceived, totalVested, totalVestingMap, onDismiss }) => {
    const { t } = useTranslation();
    const totalPrice = useMemo(() => {
        return Number(new BigNumber(item?.nftBalance).multipliedBy(item?.denominations))
    }, [item])
    return (
        <CsModal title="" onDismiss={onDismiss}>
            <Flex width="100%" height="auto" flexDirection="column" style={{ gap: "10px" }}>
                <Flex width="100%" justifyContent="center">
                    <TextTitle bold>{t("Details")}</TextTitle>
                </Flex>
                <ItemDetail title={t("Quantity")} value={item?.nftBalance} isFormatNumber />
                <ItemDetail title={`${t("Face value")} (${PAYMENT})`} value={item?.denominations} isFormatNumber minimum={0} maximum={0} />
                <ItemDetail title={t("Yield payment period")} value={item?.intervestTerm} uti={t("months")} />
                <ItemDetail title={t("Period received/total period")} value={`${totalVested}/${totalVestingMap}`} />
                <ItemDetail title={t("Yield")} value={Number(item?.profit)} uti="%" isFormatNumber />
                <ItemDetail title={t("Next yield payment date")} value={convertDate(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)} />
                <ItemDetail
                    title={t("Remaining days")}
                    value={getDateBetweenTwoDates(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)}
                    uti={t(UNIT_DATE)}
                />
                <ItemDetail
                    title={t("Interest will receive")}
                    value={yieldsReceived}
                    isFormatNumber
                    minimum={0}
                    maximum={0}
                    uti={PAYMENT}
                />
                <ItemDetail title={`${t("Total face value")} (${PAYMENT})`} value={totalPrice} isFormatNumber minimum={0} maximum={0} />
                <Flex mt="1rem" width="100%" justifyContent="center">
                    <CsButton onClick={() => onDismiss()}>{t("Exit")}</CsButton>
                </Flex>
            </Flex>
        </CsModal>
    );
};

interface ItemDetailProps {
    title: string;
    value?: any;
    isFormatNumber?: boolean;
    minimum?: number;
    maximum?: number;
    uti?: string;
}

const ItemDetail = ({ title, value, minimum = 0, maximum = 0, isFormatNumber, uti }: ItemDetailProps) => {
    const { t } = useTranslation();
    return (
        <Flex width="100%" justifyContent="space-between" alignItems="center" mt="0.5rem">
            <CsTextModalDetail color="textSubtle">{t(title)}</CsTextModalDetail>
            <CsTextModalDetail bold>
                {isFormatNumber ? formatNumberPayment(value, minimum, maximum) : value} {uti}
            </CsTextModalDetail>
        </Flex>
    );
};

const TextTitle = styled(Text)`
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.04em;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 1000px) {
        font-size: 24px;
        letter-spacing: 0;
    }
`;

const CsButton = styled(Button)`
    height: 40px;
    font-size: 12px;
    min-width: 100%;
    border-radius: 4px;
    color: ${({ theme }) => theme.colors.white};
    background: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    transition: 0.5s;
    &:hover {
        background: transparent;
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
    @media screen and (max-width: 768px) {
        min-width: 100%;
        height: 42px;
        padding: 0;
    }
`;
export default DetailModal;
