import React, { useCallback, useState } from "react";
import { axiosClient } from "config/htttp";
import fileDownload from "js-file-download";
import { useToast } from "@pancakeswap/uikit";
import { useTranslation } from "@pancakeswap/localization";

export const useDownloadPDF = () => {
    const [loading, setLoading] = useState(false)
    const { toastError, toastSuccess } = useToast();
    const { t } = useTranslation();
    const handleDownload = useCallback(async (raw) => {
        try {
            setLoading(true);
            const response = await axiosClient.post("/pdf-generator/etf-transfer", raw, {
                responseType: "arraybuffer",
                headers: { "Content-Type": "application/json", 'Accept': '*' }
            });
            fileDownload(response.data, "transfer_contract.pdf");
            toastSuccess(t("Download successful."));
            setLoading(false);
        } catch (error) {
            toastError(t("Download failed. Please try again."));
            setLoading(false);
        }
    }, [toastError, toastSuccess, t])
    return { loading, handleDownload }
}