import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useWrapped } from "hooks/useContract";
// import { usePiBMarketplace } from 'hooks/useContract'
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";

import { ToastDescriptionWithTx } from "components/Toast";
import { useUserRejected } from "hooks/useUserRejected";
import { actionDispatchAssetMessage } from "state/asset/actions";


export const useRedeem = (
    nftAddress: string,
    buys: {
        nftBalance: number,
        nftId: number
    }[],
    account: string,
    chainId: number,
    onDismiss: any
) => {
    const { t } = useTranslation();
    const [requestedRedeem, setRequestRedeem] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const [pendingRedeem, setPendingRedeem] = useState(false);
    const contractWrapped = useWrapped(nftAddress);
    const dispatch = useDispatch<AppDispatch>();
    const { handleRejected } = useUserRejected();

    const handleRedeem = useCallback(async () => {
        setPendingRedeem(true);
        const tokenIds = buys.map((item) => (item.nftId));
        const amounts = buys.map((item) => (item.nftBalance));

        try {
            const tx = await callWithMarketGasPrice(contractWrapped, "redeem", [tokenIds, amounts]);
            const receipt = await tx.wait();
            if (receipt.status) {
                setPendingRedeem(false);
                dispatch(actionDispatchAssetMessage({ messageHash: receipt.transactionHash }));
                toastSuccess(t("Successful expiration"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestRedeem(true);
                onDismiss()
            } else {
                setPendingRedeem(false);
                // user rejected tx or didn't go thru
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
            }
        } catch (e) {
            handleRejected(e);
            setPendingRedeem(false);
        }
    }, [buys, callWithMarketGasPrice, contractWrapped, dispatch, handleRejected, onDismiss, t, toastError, toastSuccess]);
    return { handleRedeem, requestedRedeem, pendingRedeem };
};
