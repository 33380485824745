import { useTranslation } from "@pancakeswap/localization";
import { Flex, HelpIconCcqIcon2, Text } from "@pancakeswap/uikit";
import { CustomTooltip } from "components/CustomTooltip";
import { PAYMENT, UNIT_DATE, formatNumberPayment } from "config";
import React from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";

interface RowProps {
    buyAmount: number;
    itemSell: Items;
}

const RowMobile: React.FC<React.PropsWithChildren<RowProps>> = ({ buyAmount, itemSell }) => {
    const { t } = useTranslation()
    return (
        <WrapItemMobile>
            <Flex justifyContent="space-between" alignItems="center">

                <CsFlexHeader>
                    <CustomTooltip title={t("Face value")}>
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px'>
                                {formatNumberPayment(itemSell?.price)}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </CustomTooltip>
                </CsFlexHeader>

                <CsFlexHeader>
                    <CustomTooltip title={t("Next yield payment date")}>
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {itemSell.paydayNextProfit}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </CustomTooltip>
                </CsFlexHeader>

                <CsFlexHeader>
                    <CustomTooltip title={`${t("Unit price")} (${PAYMENT})`}>
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {formatNumberPayment(itemSell?.unitPrice)}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </CustomTooltip>
                </CsFlexHeader>
            </Flex>

            <Flex justifyContent="space-between" alignItems="center" mt="1rem">

                <CsFlexHeader>
                    <CustomTooltip title={t("Remaining days")}>
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {`${itemSell.dayDiff} ${t(UNIT_DATE)}`}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </CustomTooltip>
                </CsFlexHeader>

                <CsFlexHeader>
                    <CustomTooltip title={t("Yield payment period")}>
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {itemSell?.interVestTerm} {t("months")}
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </CustomTooltip>
                </CsFlexHeader>

                <CsFlexHeader>
                    <CustomTooltip title={t("Yield")}>
                        <Flex style={{ cursor: 'pointer' }}>
                            <Text mr='4px' ml="2px">
                                {itemSell?.interVestTermRate}%
                            </Text>
                            <HelpIconCcqIcon2 />
                        </Flex>
                    </CustomTooltip>
                </CsFlexHeader>
            </Flex>
        </WrapItemMobile>
    );
};

export default RowMobile;

const WrapItemMobile = styled.div`
    padding: 12px;
    margin-top: 16px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.background};
    ${Text} {
        font-size: 12px;
        font-weight: 600;
    }
`;

const CsFlexHeader = styled(Flex)`
    max-width: 100%;
    align-items: center;
    justify-content: center;
`
