import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useWrapped } from "hooks/useContract";
// import { usePiBMarketplace } from 'hooks/useContract'
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";

import { ToastDescriptionWithTx } from "components/Toast";
import { useUserRejected } from "hooks/useUserRejected";
import { actionDispatchAssetMessage } from "state/asset/actions";
import { BuysType } from "state/asset/type";

interface BuysProps {
    nftBalance: number,
    nftId: number
}

export const useSellnow = (
    nftAddress: string,
    buys: BuysType[],
    amount: number,
    account: string,
    chainId: number,
    onDismiss: any
) => {
    const { t } = useTranslation();
    const [requestedSellnow, setRequestSellnow] = useState(false);
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();
    const [pendingSellNow, setPendingSellNow] = useState(false);
    const contractWrapped = useWrapped(nftAddress);
    const dispatch = useDispatch<AppDispatch>();
    const { handleRejected } = useUserRejected();


    const handleSellnow = useCallback(async () => {
        setPendingSellNow(true);
        try {
            const buysSort = buys.sort((a, b) => a.nftBalance - b.nftBalance);
            const newData: BuysProps[] = [...buysSort];
            let amountBuy = amount;
            const resultFilter: BuysProps[] = [];
            for (let i = 0; i < newData.length && amountBuy > 0; i++) {
                const element = newData[i];
                const quantityToBuy = Math.min(amountBuy, element.nftBalance);
                resultFilter.push({
                    nftId: element.nftId,
                    nftBalance: quantityToBuy
                });
                newData[i].nftBalance -= quantityToBuy;
                amountBuy -= quantityToBuy;
            }
            const tokenIds = resultFilter.map(item => (item.nftId));
            const amounts = resultFilter.map(item => (item.nftBalance));
            const tx = await callWithMarketGasPrice(contractWrapped, "sellNow", [tokenIds, amounts]);
            const receipt = await tx.wait();
            if (receipt.status) {
                dispatch(actionDispatchAssetMessage({ messageHash: receipt.transactionHash }));
                toastSuccess(t("Successful sell now"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestSellnow(true);
                onDismiss()
                setPendingSellNow(false);
            } else {
                // user rejected tx or didn't go thru
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
                setPendingSellNow(false);
            }
        } catch (e) {
            console.error(e);
            handleRejected(e);
            setPendingSellNow(false);
        }
    }, [amount, buys, callWithMarketGasPrice, contractWrapped, dispatch, handleRejected, onDismiss, t, toastError, toastSuccess]);
    return { handleSellnow, requestedSellnow, pendingSellNow };
};
