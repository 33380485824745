import { useTranslation } from "@pancakeswap/localization";

export const ExchangeToken = ({ price }) => {
    const { currentLanguage, setLanguage, t } = useTranslation()
    return (
        <>
            {/* <span style={{ color: "#8C8D8E", fontSize: "12px", }}>
            {` (${t("Equivalent")} ${price} ${t("point")} VNDC)`}
        </span> */}
        </>
    )
};
