import { LINK_VERICHAINS } from 'config/constants';
import { Flex } from '@pancakeswap/uikit';
import styled from 'styled-components'
import { useTranslation } from '@pancakeswap/localization';

export const Footer = () => {
    return (
        <CsWrapperFooter>
            <CsLayoutFooter>
                <About />
                <Headquarters />
            </CsLayoutFooter>
        </CsWrapperFooter>
    )
}

const About = () => {
    const { t } = useTranslation();
    return (
        <CsAbout>
            <a href="https://tss.org.vn/chung-chi-quy" target="_blank" rel="noreferrer">
                <img src="/images/ccqFundgo/logo_footer.webp?version=1.0" alt="logo-tss" className='logo-tss' />
            </a>
            <a href={LINK_VERICHAINS} target="_blank" rel="noreferrer">
                <img src="/images/ccqFundgo/verichain.png?version=1.0" alt="verichain" className='verichain' />
            </a>
            <h4>{t("About")}</h4>
            <p>{t("Go Global Franchise Fund Certificate is a valuable paper issued by Go Global Franchise Fund in accordance with the Fund's charter as stipulated in the Law on Supporting Small and Medium Enterprises, Go Global Franchise Fund Certificate valuable as an investment in the fund, profitable every day, safe, easy to transfer as soon as money is needed to receive attractive yield for each holding year for members of the fund.")}</p>
        </CsAbout>
    )
};

const Headquarters = () => {
    const { t } = useTranslation();
    return (
        <CsHeadquarters>
            <CsFlex mb="2rem" alignItems="center">
                <a href={LINK_VERICHAINS} target="_blank" rel="noreferrer">
                    <img src="/images/ccqFundgo/verichain.png?version=1.2" alt="verichain" className='logo' />
                </a>
            </CsFlex>
            <h4>{t("Headquarters")}</h4>
            <div className='description'>
                <p>{t("222 Dien Bien Phu, Vo Thi Sau Ward, District 3, Ho Chi Minh City.")}</p>
                <p>{t("Hotline: 0903003325")}</p>
                <p>{t("Email: support@goglobal.org.vn")}</p>
                <div className="social">
                    <a href="https://www.facebook.com/GoGlobalHoldings" target="_blank" rel="noreferrer">
                        <img src="/images/ccqFundgo/facebook.png" alt="fb" style={{width:"32px"}} />
                    </a>
                    <a href="https://www.linkedin.com/company/go-global-holdings" target="_blank" rel="noreferrer">
                        <img src="/images/ccqFundgo/linkedin.png" alt="linkedin" style={{width:"32px"}} />
                    </a>
                    {/* <a href="https://t.me/TTQLTSS" target="_blank" rel="noreferrer">
                        <img src="/images/ccqFundgo/telegram.png" alt="telegram" />
                    </a> */}
                </div>
            </div>
        </CsHeadquarters>
    )
}

const CsHeadquarters = styled.div`
    img.logo{
            width: 330px;
            margin-bottom: 40px;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    h4{
        margin-bottom: 28px;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
            margin-bottom: 14px;
        }
    }
    .description{
        display: flex;
        flex-direction: column;
        gap: 14px;
        @media screen and (max-width: 1024px) {
            gap: 8px;
        }
        .social{
            display: flex;
            gap: 24px;
        }
    }
    p{
        text-align: justify;
        color: #ffff;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 1024px) {
            font-size: 13px;
            max-width: 100%;
        }
    }
`

const CsAbout = styled.div`
    height: 100px;
    .verichain{
        display: none;
        @media screen and (max-width: 1024px) {
            width: 230px;
            display: block;
            margin-bottom: 20px;
        }
    }
    img.logo-tss{
        width: 232px;
        margin-bottom: 20px;
        @media screen and (max-width: 1024px) {
            width: 232px;
            margin-bottom: 24px;
        }
    }
    h4{
        margin-bottom: 28px;
        color: #fff;
        font-size: 24px;
        font-weight: 500;
        @media screen and (max-width: 1024px) {
            font-size: 16px;
            margin-bottom: 14px;
        }
    }
    p{
        max-width: 560px;
        text-align: justify;
        color: #ffff;
        font-size: 16px;
        line-height: 24px;
        @media screen and (max-width: 1024px) {
            font-size: 13px;
            max-width: 100%;
        }
    }
    @media screen and (max-width:1280px) {
        height: auto;
    }
`

const CsWrapperFooter = styled.div`
    background:#000;
    width: 100%;
    padding: 35px 82px;
    @media screen and (max-width: 1024px) {
        padding: 20px;
    }
`

const CsLayoutFooter = styled.div`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 36px;
    @media screen and (max-width: 1024px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
`
const CsFlex = styled(Flex)`
    height: 100px;
    @media screen and (max-width:1280px) {
        height: auto;
    }
`