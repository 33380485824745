import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";


import { useTranslation } from "@pancakeswap/localization";
import { useToast } from "@pancakeswap/uikit";
import { ToastDescriptionWithTx } from "components/Toast";
import { useCallWithMarketGasPrice } from "hooks/useCallWithMarketGasPrice";
import { useCoreMarketplace } from "hooks/useContract";
import { useUserRejected } from "hooks/useUserRejected";
import { fetchCartItems } from "state/MarketPlace/actions";
import { CartItem } from "state/MarketPlace/type";
import { actionDispatchAssetMessage } from "state/asset/actions";

interface Buy {
    amount: number;
    id: number;
}

interface Cart {
    addresses?: string;
    buyAmount?: number;
    buys?: Buy[];
}

export const useBuyItem = (marketplaceAddress: string, account: string, chainId: number, listCart: CartItem[], onDismiss: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const { t } = useTranslation();
    const [requestedApproval, setRequestedApproval] = useState(false);
    const [pendingTx, setPendingTx] = useState(false);
    const address = useCoreMarketplace(marketplaceAddress);
    const { handleRejected } = useUserRejected();
    const { toastSuccess, toastError } = useToast();
    const { callWithMarketGasPrice } = useCallWithMarketGasPrice();

    const newListCart: Cart[] = listCart.map(obj => ({
        ...obj,
        buys: [...obj.buys].sort((a, b) => a.amount - b.amount),
    }));

    const resultFilter: Buy[] = [];
    newListCart.forEach(obj => {
        obj.buys.forEach(item => {
            if (item.amount > 0 && obj.buyAmount > 0) {
                const quantityToBuy = Math.min(obj.buyAmount, item.amount);
                resultFilter.push({ id: item.id, amount: quantityToBuy });
                const updatedAmount = item.amount - quantityToBuy;
                const updatedBuyAmount = obj.buyAmount - quantityToBuy;
                // eslint-disable-next-line no-param-reassign
                obj.buys = obj.buys.map(buy =>
                    buy.id === item.id ? { ...buy, amount: updatedAmount } : buy
                );
                // eslint-disable-next-line no-param-reassign
                obj.buyAmount = updatedBuyAmount;
            }
        });
    });

    const payload = resultFilter.map((item) => ([item.id, item.amount]));

    const handleBuyItems = useCallback(async () => {
        setPendingTx(true);
        try {
            const tx = await callWithMarketGasPrice(address, "buyItem", [payload]);
            const receipt = await tx.wait();
            if (receipt.status) {
                toastSuccess(t("Successfully purchase"), <ToastDescriptionWithTx txHash={receipt.transactionHash} />);
                setRequestedApproval(true);
                setPendingTx(false);
                onDismiss();
                dispatch(fetchCartItems({ listCart: [] }));
                dispatch(actionDispatchAssetMessage({ messageHash: receipt.transactionHash }));

            } else {
                // user rejected tx or didn't go thru
                toastError(
                    t("An error has occurred"),
                    t("Confirm the transaction, and make sure you have enough tokens in your wallet to process the transaction!")
                );
                setRequestedApproval(false);
                setPendingTx(false);
            }
        } catch (e) {
            handleRejected(e);
            setPendingTx(false);
        }
    }, [address, callWithMarketGasPrice, dispatch, handleRejected, onDismiss, payload, t, toastError, toastSuccess]);

    return { handleBuyItems, requestedApproval, pendingTx };
};
