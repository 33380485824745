import BigNumber from "bignumber.js";
import { ERC20_ABI } from "config/abi/erc20";
import moment from "moment";
import multicall from "utils/multicall";

import { UNITDIFF } from "config";
import ccqWrappedAbi from "config/abi/ccqWrappedAbi.json";
import marketplaceAbi from "config/abi/marketplaceAbi.json";
import { dataUser } from "config/auth";
import { renderToken } from "utils/renderAddress";
import { typeFetchItems } from "./type";
import { getDataEtfType } from "./getDataPhase2";

export const fetchGetTotalItems = async (contractAddress: string, chainId: number) => {
    try {
        const calls = [
            {
                address: contractAddress,
                name: 'getTotalItems',
                params: []
            }
        ];
        const [request] = await multicall(marketplaceAbi, calls, chainId);
        return Number(new BigNumber(request).toJSON())
    } catch (error) {
        return 0
    }
};

export const fetchItems = async (contractAddress: string, chainId: number, getTotalItems: number) => {
    try {
        const calls = [];
        for (let index = 0; index < getTotalItems; index++) {
            calls.push({
                address: contractAddress,
                name: 'items',
                params: [index]
            })
        }
        const request = await multicall(marketplaceAbi, calls, chainId);
        const parseData = request.map((item) => {
            return {
                amount: Number(new BigNumber(item.amount.toString())),
                id: Number(new BigNumber(item.id.toString())),
                nftAddress: item.nftAddress,
                nftContract: item.nftContract,
                nftId: Number(new BigNumber(item.nftId.toString())),
                seller: item.seller
            }
        });
        return parseData;
    } catch (error) {
        return []
    }
};

export const fetchNftAddressItem = async (items: typeFetchItems[], contractMarketplaceAddress: string, chainId: number, account: string) => {
    try {
        if (items.length) {
            const formatTime = "YYYY-MM-DD HH:mm:ss";
            const nowDay = moment(new Date()).format(formatTime);
            const callsEtfInfo = [];
            const callsPriceAtTime = [];
            const callVestingMap = [];
            const callPaused = [];
            const callBlacklists = [];
            const currentTime = Math.floor(Date.now() / 1000)
            for (let index = 0; index < items.length; index++) {
                callsEtfInfo.push(
                    {
                        address: items[index].nftAddress,
                        name: 'etfInfor',
                        params: []
                    },
                );
                if (account) {
                    callBlacklists.push(
                        {
                            address: items[index].nftAddress,
                            name: 'blacklists',
                            params: [account]
                        },
                    );
                }
                callVestingMap.push(
                    {
                        address: items[index].nftAddress,
                        name: 'getMyVestList',
                        params: [contractMarketplaceAddress, items[index]?.nftId]
                    }
                )
                callsPriceAtTime.push(
                    {
                        address: items[index].nftAddress,
                        name: 'getPriceAtTime',
                        params: [currentTime]
                    }
                )
                callPaused.push(
                    {
                        address: items[index].nftAddress,
                        name: 'paused',
                        params: []
                    }
                )

            }
            const getEtfInfo = async () => {
                const resultEtfInfo = await multicall(ccqWrappedAbi, callsEtfInfo, chainId);
                return resultEtfInfo
            }
            const getEtfVestingMap = async () => {
                const resultVestingMap = await multicall(ccqWrappedAbi, callVestingMap, chainId);
                return resultVestingMap
            }
            const getPriceAtTime = async () => {
                const resultPriceAtTime = await multicall(ccqWrappedAbi, callsPriceAtTime, chainId);
                return resultPriceAtTime
            }
            const getCallPaused = async () => {
                const resultCallPaused = await multicall(ccqWrappedAbi, callPaused, chainId);
                return resultCallPaused
            }
            const getBlacklists = async () => {
                const blacklists = await multicall(ccqWrappedAbi, callBlacklists, chainId);
                return blacklists
            }
            const [requestEtfInfor, resultVestingmap, resultPriceAtTime, resultCallPaused, resultBlacklists] = await Promise.all(
                [
                    getEtfInfo(),
                    getEtfVestingMap(),
                    getPriceAtTime(),
                    getCallPaused(),
                    getBlacklists(),
                ]
            )
            const parseData = requestEtfInfor.map((item, index) => {
                const member = dataUser.find((i) => i.account?.toLowerCase() === items[index]?.seller?.toLowerCase())
                const issueDate = Number(new BigNumber(item.issueDate.toString()).toString());
                const price = Number(new BigNumber(item.price.toString()).dividedBy(1E18).toString());
                const interVestTerm = Number(new BigNumber(item.intervestTerm.toString()).toString()) === 92 ? 3 : 6;
                const expireDate = Number(new BigNumber(item.expireDate.toString()).toString());
                const resultDayNextProfit = resultVestingmap[index][0].find((data) => Number(data?.vestDate) * 1000 > Date.now())
                const parseDate = new BigNumber(resultDayNextProfit?.vestDate?.toString()).multipliedBy(1000).toString()
                const paydayNextProfit = moment(new Date(Number(parseDate))).format("DD/MM/YYYY");
                const convertdayDiff = moment(Number(parseDate)).diff(moment(Number(Date.now())), UNITDIFF)
                const interestRate = Number(new BigNumber(item.intervestTerm.toString()).toString()) === 92 ? 8 : 9.2;
                const holdDate = Number(new BigNumber(item.intervestTerm.toString()).toString()) === 92 ? 92 - convertdayDiff : 164 - convertdayDiff;
                // unitPrice = price + (price * interestRate% * holdDate / 365)
                const unitPrice = Number(new BigNumber(resultPriceAtTime[index][0].toString()).dividedBy(1E18).toString())
                const paused = resultCallPaused[index][0];
                const isBlacklists = resultBlacklists[index][0];
                const resultVestingMap = resultVestingmap[index][0].map((vestItem) => {
                    return {
                        amount: new BigNumber(vestItem?.amount?.toString()).toString(),
                        isVested: vestItem?.isVested,
                        vestDate: Number(new BigNumber(vestItem?.vestDate?.toString()).toString()),
                        intervestPayed: new BigNumber(vestItem?.intervestPayed.toString()).toString(),
                    }
                })
                return {
                    fundMember: member?.nickName,
                    logo: member?.image,
                    interVestTermRate: interestRate,
                    dayDiff: convertdayDiff + 1 > 92 ? 92 : convertdayDiff + 1,
                    ...items[index],
                    expireDate,
                    interVestTerm,
                    paydayNextProfit,
                    unitPrice,
                    issueDate,
                    name: item.name,
                    price,
                    publisher: item.publisher,
                    totalSupply: Number(new BigNumber(item.totalSupply.toString())),
                    vestingMap: resultVestingMap,
                    paused,
                    isBlacklists
                }
            });
            const getEtfType = await Promise.all(parseData.map(async (item) => {
                const resultEtfType = await getDataEtfType(item.nftAddress, chainId)
                return {
                    ...item,
                    ...resultEtfType
                }
            }));
            const filterExpireDate = getEtfType.filter(item => {
                const endDay = moment(item.expireDate * 1000).format(formatTime);
                return moment(nowDay).valueOf() < moment(endDay).valueOf() && !item.paused;
            });
            return filterExpireDate;
        }
        return [];
    } catch (error) {
        return [];
    }
}


export const fetchDataAllowance = async (contractMarketplaceAddress: string, tokenAddress: string, account: string, chainId: number) => {
    try {
        if (account) {
            const calls = [
                {
                    address: tokenAddress,
                    name: 'allowance',
                    params: [account, contractMarketplaceAddress]
                },
                {
                    address: tokenAddress,
                    name: 'balanceOf',
                    params: [account]
                }
            ];
            const token = renderToken(chainId);
            const [resultAllowance, resultBalanceOf] = await multicall(ERC20_ABI, calls, chainId);
            return {
                allowance: new BigNumber(resultAllowance[0].toString()).toString(),
                balance: new BigNumber(resultBalanceOf[0].toString()).dividedBy(new BigNumber(10).pow(token.decimals)).toString(),
            }
        }
        return {
            allowance: '0',
            balance: '0',
        }
    } catch (error) {
        return {
            allowance: '0',
            balance: '0',
        }
    }
}
