import { ChainId } from "@pancakeswap/sdk"
import contracts from "config/constants/contracts"
import { bscTokens, bscTestnetTokens, } from "@pancakeswap/tokens"
import { PAYMENT, PAYMENT_TOKEN } from "config"
import { getAddress } from "./addressHelpers"

export function renderMarketplaceAddress(chainId) {
    return getAddress(contracts?.ccqMarketplace, chainId)
}

export function renderToken(chainId: number) {
    if (chainId === ChainId.BSC_TESTNET) {
        if (PAYMENT_TOKEN.VND === PAYMENT) return bscTestnetTokens.vndc
        if (PAYMENT_TOKEN.USD === PAYMENT) return bscTestnetTokens.usdt
    }
    if (chainId === ChainId.BSC) {
        if (PAYMENT_TOKEN.VND === PAYMENT) return bscTokens.VNDC
        if (PAYMENT_TOKEN.USD === PAYMENT) return bscTokens.usdt
    }
    return bscTestnetTokens.vndc
}