import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, PlayIcon, Skeleton, Text, useModal } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { CustomTooltip } from "components/CustomTooltip";
import { UNIT_DATE, formatNumberPayment } from "config";
import React, { useMemo } from "react";
import { ListAssestItem, VestingItem } from "state/asset/type";
import styled from "styled-components";
import { convertDate, getDateBetweenTwoDates } from "utils/converDate";
import DetailModal from "./ModalDetail";
import ProfitReceivedModal from "./ProfitReceivedModal";
import ReceivedAll from "./ReceivedAll";
import SellModal from "./SellModal";

interface Props {
    item: ListAssestItem;
    windowSize: number;
    account: string;
    chainId: number;
    vestingMap: VestingItem[],
    currentTime: number,
    indexRow: number
}
const Row: React.FC<React.PropsWithChildren<Props>> = ({ item, windowSize, account, chainId, vestingMap, currentTime, indexRow }) => {
    const { t } = useTranslation()
    const yieldsReceived = useMemo(() => {
        return new BigNumber(vestingMap[0]?.vestAmount).multipliedBy(item?.nftBalance).toString()
    }, [item?.nftBalance, vestingMap])
    const [onProfitReceivedModal] = useModal(
        <ProfitReceivedModal
            chainId={chainId}
            account={account}
            price={item?.denominations}
            profit={`${Number(item?.profit)}%`}
            currentyield={item?.vestingMap[0]?.vestAmount}
            origininvest={Number(item?.denominations) * Number(item?.nftBalance)}
            interestPayDate={item?.vestingMap[0]?.nextInterestPaymentDate}
            dayleft={getDateBetweenTwoDates(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)}
            windowSize={windowSize}
            title={t("Fund Certificate Information")}
            dataUser={item}
        />,
        true,
        true,
        `modal_profitreceivedmodal_${indexRow}`
    );
    const [onReceivedAll] = useModal(
        <ReceivedAll
            chainId={chainId}
            account={account}
            price={item?.denominations}
            profit={`${Number(item?.profit)}%`}
            currentyield={item?.vestingMap[0]?.vestAmount}
            origininvest={Number(item?.denominations) * Number(item?.nftBalance)}
            interestPayDate={item?.vestingMap[0]?.nextInterestPaymentDate}
            dayleft={getDateBetweenTwoDates(item?.vestingMap[0]?.nextInterestPaymentDate * 1000)}
            windowSize={windowSize}
            title="Thông tin Chứng chỉ Quỹ"
            dataUser={item}
        />,
        true,
        true,
        `modal_receivedAll_${item?.nftAddress}_${indexRow}`
    );
    const [onSellModal] = useModal(
        <SellModal
            item={item}
            windowSize={windowSize}
            title={t("Fund Certificate Information")}
            account={account}
            chainId={chainId}
        />,
        true,
        true,
        `modal_sell_${item?.nftAddress}_${indexRow}`
    );

    const totalVested = useMemo(() => {
        return item?.fullVestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [item?.fullVestingMap])

    const totalVestingMap = useMemo(() => {
        return item?.fullVestingMap?.length
    }, [item?.fullVestingMap?.length])

    const [openDetailModal] = useModal(
        <DetailModal
            item={item}
            totalVested={totalVested}
            totalVestingMap={totalVestingMap}
            yieldsReceived={yieldsReceived}
        />,
        true,
        true,
        `modal_detals_${item?.nftAddress}_${indexRow}`
    );
    const totalPrice = useMemo(() => {
        return Number(item.denominations) * Number(item?.nftBalance);
    }, [item])
    const isTimeCanClaim = currentTime > vestingMap[0]?.nextInterestPaymentDate * 1000;

    return (
        <>
            {windowSize > 768 ? (
                <WrapperTable>
                    <WrapperTableContent>
                        <WrapItem>
                            <Text>{item?.nftBalance}</Text>
                            <Text>
                                {formatNumberPayment(item?.denominations)}
                            </Text>
                            <Text>{item?.intervestTerm} {t("months")}</Text>
                            <Text>{totalVested}/{totalVestingMap}</Text>
                            <Text> {Number(item?.profit)}%</Text>
                            <Flex justifyContent="center">
                                {(item?.isExpire && vestingMap?.length === 0) ?
                                    <Text fontSize="14px" color="#02111B">{convertDate(item?.expireDate * 1000)}</Text>
                                    :
                                    <Text fontSize="14px" color="#02111B">{convertDate(vestingMap[0]?.nextInterestPaymentDate * 1000)}</Text>
                                }
                            </Flex>
                            <Flex>
                                {(item?.isExpire && vestingMap?.length === 0) ?
                                    <Flex>
                                        <Text fontSize="14px" color="#02111B">0 {t(UNIT_DATE)}</Text>
                                    </Flex>
                                    :
                                    <Flex>
                                        <Text>{vestingMap[0]?.nextInterestPaymentDate && getDateBetweenTwoDates(vestingMap[0]?.nextInterestPaymentDate * 1000)}</Text>
                                        <Text ml="5px">{t(UNIT_DATE)}</Text>
                                    </Flex>
                                }
                            </Flex>
                            {(item?.isExpire && vestingMap?.length === 0) ?
                                <Text>
                                    0
                                </Text>
                                :
                                <>
                                    {new BigNumber(vestingMap[0]?.vestAmount).isNaN() ?
                                        <Flex justifyContent="center"><Skeleton width="42px" /></Flex>
                                        :
                                        <Text>
                                            {formatNumberPayment(yieldsReceived)}
                                        </Text>
                                    }
                                </>
                            }
                            <Text>
                                {formatNumberPayment(totalPrice)}
                            </Text>
                            <WraperBtnSell>
                                {vestingMap?.length > 0 ?
                                    <>
                                        {currentTime > Number(vestingMap[0]?.nextInterestPaymentDate) * 1000 ? (

                                            <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                                                <CustomTooltip
                                                    trigger={["hover"]}
                                                    color="red"
                                                    title={item?.isBlacklists ? t("Feature locked, please contact admin!") : ""}
                                                >
                                                    <CsButtonReceiProfit
                                                        disabled={!isTimeCanClaim || item?.isBlacklists}
                                                        onClick={onProfitReceivedModal}
                                                    >
                                                        {t("Get yield")}
                                                    </CsButtonReceiProfit>
                                                </CustomTooltip>
                                            </Flex>
                                        ) : (
                                            <CustomTooltip
                                                trigger={["hover"]}
                                                color="red"
                                                title={item?.isBlacklists ? t("Feature locked, please contact admin!") : ""}
                                            >
                                                <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                                                    <CsButton disabled={item?.isBlacklists || item?.issueDate * 1000 > Date.now()} onClick={onSellModal}>{t("Sell")}</CsButton>
                                                </Flex>
                                            </CustomTooltip>
                                        )}
                                    </>
                                    :
                                    <>
                                        {item?.isExpire &&
                                            <CustomTooltip
                                                trigger={["hover"]}
                                                color="red"
                                                title={item?.isBlacklists ? t("Feature locked, please contact admin!") : ""}
                                            >
                                                <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                                                    <CsButton disabled={item?.isBlacklists} onClick={onReceivedAll}>{t("Expire")}</CsButton>
                                                </Flex>
                                            </CustomTooltip>
                                        }
                                    </>
                                }
                            </WraperBtnSell>
                        </WrapItem>
                    </WrapperTableContent>
                </WrapperTable>
            ) : (
                <WrapperTableMobile>
                    <WrapItemMobile>
                        <Flex
                            width="100%"
                            justifyContent="space-between"
                            mt="0.5rem"
                            alignItems="center"
                            flexWrap="wrap"
                        >
                            <Flex width="25%">
                                <PlayIcon width="20px" style={{ cursor: "pointer" }} onClick={openDetailModal} />
                                <Text fontSize="12px" color="textSubtle">
                                    {t("Quantity")}:
                                </Text>
                                <Text>&nbsp;{item?.nftBalance}</Text>
                            </Flex>
                            <Flex width="25%" justifyContent="center">
                                <CsTextMonth>{item?.intervestTerm} {item?.intervestTerm > 1 ? t("months") : t("month")}</CsTextMonth>
                            </Flex>
                            <Flex width="25%" justifyContent="center">
                                {(item?.isExpire && vestingMap?.length === 0) ?
                                    <Text>
                                        0 {t(UNIT_DATE)}
                                    </Text>
                                    :
                                    <>
                                        {new BigNumber(vestingMap[0]?.nextInterestPaymentDate * 1000).isNaN() ?
                                            <Skeleton width="42px" />
                                            :
                                            <Text>{getDateBetweenTwoDates(vestingMap[0]?.nextInterestPaymentDate * 1000)} {t(UNIT_DATE)}</Text>
                                        }
                                    </>
                                }
                            </Flex>
                            {(item?.isExpire && vestingMap?.length === 0) ?
                                <Flex width="25%">
                                    <Text>
                                        0 {t(UNIT_DATE)}
                                    </Text>
                                </Flex>
                                :
                                <>
                                    {new BigNumber(vestingMap[0]?.nextInterestPaymentDate * 1000).isNaN() ?
                                        <Flex width="25%">
                                            <Skeleton width="42px" />
                                        </Flex>
                                        :
                                        <Text width="25%">
                                            {formatNumberPayment(item?.denominations)}
                                        </Text>
                                    }
                                </>
                            }
                        </Flex>
                        <div className="grid-cols-4	grid">
                            <div className="flex gap-1 ml-2">
                                <Text fontSize="12px" color="textSubtle">
                                    {t("Yield")}:
                                </Text>
                                <Text>&nbsp;
                                    {(item?.isExpire && vestingMap?.length === 0) ?
                                        `0`
                                        :
                                        formatNumberPayment(yieldsReceived)
                                    }
                                </Text>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-[#02111B] text-[12px] font-semibold">{formatNumberPayment(totalPrice)}</p>
                            </div>
                            <div className="flex items-center justify-center">
                                <p className="text-[#02111B] text-[12px] font-semibold">{totalVested}/{totalVestingMap}</p>
                            </div>
                            <div className="flex items-center justify-end">
                                {vestingMap?.length > 0 ?
                                    <>
                                        {currentTime > Number(vestingMap[0]?.nextInterestPaymentDate) * 1000 ? (
                                            <CustomTooltip
                                                trigger={["hover"]}
                                                color="red"
                                                title={item?.isBlacklists ? t("Feature locked, please contact admin!") : ""}
                                            >
                                                <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                                                    <CsButtonReceiProfit
                                                        disabled={!isTimeCanClaim || item?.isBlacklists || item?.issueDate * 1000 > Date.now()}
                                                        onClick={onProfitReceivedModal}
                                                    >
                                                        {t("Get yield")}
                                                    </CsButtonReceiProfit>
                                                </Flex>
                                            </CustomTooltip>
                                        ) : (
                                            <CustomTooltip
                                                trigger={["hover"]}
                                                color="red"
                                                title={item?.isBlacklists ? t("Feature locked, please contact admin!") : ""}
                                            >
                                                <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                                                    <CsButton disabled={item?.isBlacklists || item?.issueDate * 1000 > Date.now()} onClick={onSellModal}>{t("Sell")}</CsButton>
                                                </Flex>
                                            </CustomTooltip>
                                        )}
                                    </>
                                    :
                                    <>
                                        {item?.isExpire &&
                                            <CustomTooltip
                                                trigger={["hover"]}
                                                color="red"
                                                title={item?.isBlacklists ? t("Feature locked, please contact admin!") : ""}
                                            >
                                                <Flex justifyContent="center" style={{ cursor: 'pointer' }}>
                                                    <CsButton disabled={item?.isBlacklists} onClick={onReceivedAll}>Đáo hạn</CsButton>
                                                </Flex>
                                            </CustomTooltip>
                                        }
                                    </>
                                }
                            </div>
                        </div>
                    </WrapItemMobile>
                </WrapperTableMobile>
            )}
        </>
    );
};

export default Row;

const CsTextMonth = styled(Text)`
    font-size: 10px;
    font-weight: 500;
    padding: 3px 15px;
    border-radius: 4px;
    letter-spacing: 0.04em;
    text-align: center;
    color: ${({ theme }) => theme.colors.textSubtle};
    border: 1px solid ${({ theme }) => theme.colors.background};
    @media screen and (max-width: 400px){
        padding: 3px 8px;
    }
`;

const WrapItemMobile = styled.div`
    margin-top: 16px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.white};
    border: 1px solid ${({ theme }) => theme.colors.background};
    ${Text} {
        margin: 8px 0;
        font-size: 12px;
        font-weight: 600;
        &:last-child {
            text-align: right;
        }
    }
`;

const WrapperTableMobile = styled(Flex)`
    width: 100%;
    flex-direction: column;
`;

const WrapperTable = styled.div`
    width: 100%;
`;

const WrapperTableContent = styled(Flex)`
    flex-direction: column;
`;

const WrapItem = styled(Flex)`
    border-radius: 20px;
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    padding: 20px;
    place-items: center;
    margin-top: 24px;
    border: 1px solid ${({ theme }) => theme.colors.textSubtle};
    ${Text} {
        text-align: center;
        font-size: 14px;
    }
`;

const CsButton = styled(Button)`
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    min-width: 90px;
    border-radius: 4px;
    background: transparent;
    color: ${({ theme }) => theme.colors.primary};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    transition: 0.5s;
    &:hover {
        background: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.white};
        border: 1px solid transparent;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 36px;
        padding: 0;
    }
    @media screen and (max-width: 500px) {
        min-width: 70px;
    }
`;
const CsButtonReceiProfit = styled(Button)`
    height: 40px;
    font-size: 12px;
    font-weight: 600;
    min-width: 90px;
    border-radius: 4px;
    background: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    border: 1px solid transparent;
    transition: 0.5s;
    &:hover {
        background: transparent;
        color: ${({ theme }) => theme.colors.primary};
        border: 1px solid ${({ theme }) => theme.colors.primary};
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        height: 36px;
        padding: 0;
    }
    @media screen and (max-width: 500px) {
        min-width: 70px;
    }
`
const WraperBtnSell = styled(Flex)`
    justify-content: flex-end;
    /* width: 15%; */
    height: 40px;
    @media screen and (max-width: 768px) {
        width: 25%;
    }
`;
