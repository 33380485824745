import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, IconNotification } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import { CustomTooltip } from "components/CustomTooltip";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import React from "react";
import { GetDataAsset, UseCoreAsset } from "state/asset";
import styled from "styled-components";
import { renderToken } from "utils/renderAddress";
import TabsAsset from "./TabsAsset";
import TabsHistory from "./TabsHistory";
import TabsOrder from "./TabsOrder";

const FooterTabs = () => {
    const { t } = useTranslation()
    const [tabActive, setTabActive] = React.useState<number>(0);
    const windowSize = GetWindowSize();

    const handleChangeTabs = (index: number) => setTabActive(index);
    const { listUserBalance } = GetDataAsset();
    const countInterest = listUserBalance.reduce((acc, pre) => {
        return acc + pre.countInterest;
    }, 0);

    const countInterestMiss = listUserBalance.reduce((acc, pre) => {
        return acc + pre.countInterestMiss;
    }, 0);
    const { chainId, account } = useActiveWeb3React();
    const token = renderToken(chainId);
    UseCoreAsset(token.address, account, chainId);

    const infoCountInterest = React.useMemo(() => {
        if (countInterest !== 0 && countInterestMiss !== 0)
            return t("You have %interest% Fund Certificates about to receive interest and %interestMiss% Fund Certificates that needs to receive interest", { interest: countInterest, interestMiss: countInterestMiss });
        if (countInterest !== 0)
            return t("You have %interest% Fund Certificates about to receive interest", { interest: countInterest });
        if (countInterestMiss !== 0)
            return t("You have %interestMiss% Fund Certificates that needs to receive interest", { interestMiss: countInterestMiss })
        return "";
    }, [countInterest, countInterestMiss, t]);

    return (
        <WrapperFooterContainer>
            <ContainerSubTab>
                <CsButton isActive={tabActive === 0 ? !false : false} onClick={() => handleChangeTabs(0)}>
                    {t("ORDER BOOK")}
                </CsButton>
                <CsButton isActive={tabActive === 1 ? !false : false} onClick={() => handleChangeTabs(1)}>
                    {t("ASSET")}
                    {(countInterest !== 0) || (countInterestMiss !== 0) ?
                        <CsNotification>
                            <CustomTooltip color="red"
                                title={infoCountInterest}
                            >
                                <div style={{ cursor: 'pointer' }}> <IconNotification /></div>
                            </CustomTooltip>
                        </CsNotification>
                        : ''
                    }
                </CsButton>
                <CsButton isActive={tabActive === 2 ? !false : false} onClick={() => handleChangeTabs(2)}>
                    {t("SEARCH")}
                </CsButton>
            </ContainerSubTab>
            <CsFooterContent>
                {tabActive === 0 && <TabsOrder windowSize={windowSize} />}
                {tabActive === 1 && <TabsAsset windowSize={windowSize} />}
                {tabActive === 2 && <TabsHistory windowSize={windowSize} />}
            </CsFooterContent>
        </WrapperFooterContainer>
    );
};

export default FooterTabs;



const CsNotification = styled.div`
    position: relative;
    left: 10px;
    z-index: 100;
`

const WrapperFooterContainer = styled(Flex)`
    background: ${({ theme }) => theme.colors.white};
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
    padding: 0 16px 3rem;
    width: 100%;
    min-height: 100vh;
    justify-content: flex-start;
    @media screen and (max-width: 1000px) {
        min-height: auto;
        margin-top: 1rem;
        padding: 0 16px 3rem;
    }
    @media screen and (max-width: 600px) {
        margin-top: 1rem;
        padding: 0 10px 3rem;
    }
`;

const CsFooterContent = styled(Flex)`
    width: 100%;
    display: flex;
    flex-flow: column;
    max-width: 1280px;
    justify-content: flex-start;
    align-items: center;
    min-height: 100vh;
`;

const ContainerSubTab = styled(Flex)`
    max-width: 1140px;
    grid-template-columns: repeat(3, 1fr);
    width: 100% !important;
    display: grid;
    height: 84px;
    padding: 8px;
    background-color: ${({ theme }) => theme.colors.background};
    border-radius: 8px;
    align-items: center;
    margin-top: 3.375rem;
    gap: 10px;
    @media screen and (max-width: 600px) {
        height: 64px;
        gap: 5px;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 1.563rem;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        height: 64px;
        margin-top: 1.563rem;
    }
`;
const CsButton = styled(Button) <{ isActive: boolean }>`
    height: 64px;
    border-radius: 8px;
    background-color: ${({ theme, isActive }) => (isActive ? theme.colors.primary : "transparent")};
    color: ${({ theme, isActive }) => (isActive ? theme.colors.white : theme.colors.primary)};
    font-size: 22px;
    font-weight: 700;
    transition: 0.5s;
    @media screen and (max-width: 600px) {
        font-size: 14px;
        height: 48px;
        padding-left: 0px;
        padding-right: 0px;
    }
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 16px;
        height: 48px;
    }
`;
