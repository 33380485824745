import { useTranslation } from "@pancakeswap/localization";
import { AutoRenewIcon, Box, Button, Flex, HelpIconCcqIcon, HelpIconCcqIcon2, OpenNewIconFundgo, Text } from "@pancakeswap/uikit";
import BigNumber from "bignumber.js";
import { PAYMENT, UNIT_DATE, formatNumberPayment } from "config";
import React, { useMemo } from "react";
import { ListAssestItem } from "state/asset/type";
import styled from "styled-components";
import { getBlockExploreLink } from "utils";
import { convertDate } from "utils/converDate";

import { CustomTooltip } from "components/CustomTooltip";
import { ExchangeToken } from "components/ExchangeToken";
import { GetPriceAtTime } from "../hook/fetchData";
import { useApproveRedeem } from "../hook/useApproveRedeem";
import { useRedeem } from "../hook/useRedeem";
import {
    CsFlexHeader,
    CsFlexLeft,
    CsModal,
    CsTextModalLabel,
    CsTextModalValue,
    CsTextModalValuePrice,
    CsTextVndc,
    CustomLink,
    WrapItemMobile
} from "../styles";

interface ModalProps {
    title: React.ReactNode;
    hideCloseButton?: boolean;
    onDismiss?: () => void;
    bodyPadding?: string;
    headerBackground?: string;
    windowSize: number;
    price: string;
    profit: string;
    currentyield: string;
    origininvest: number;
    interestPayDate: number;
    dayleft: number;
    account: string;
    chainId: number;
    dataUser: ListAssestItem,
}

const ReceivedAll: React.FC<React.PropsWithChildren<ModalProps>> = ({
    price,
    profit,
    currentyield,
    origininvest,
    interestPayDate,
    dayleft,
    windowSize,
    title,
    account,
    chainId,
    dataUser,
    onDismiss,
    ...props
}) => {
    const { t } = useTranslation();
    function sAccount(dataAddress) {
        if (dataAddress?.length > 0) {
            return `${dataAddress.substring(0, 4)}...${dataAddress.substring(dataAddress.length - 4)}`;
        }
        return "";
    }
    function handleOpenLink() {
        window.open(getBlockExploreLink(account, "address", chainId));
    }
    const actuallyReceived = useMemo(() => {
        return new BigNumber(dataUser?.nftBalance).multipliedBy(dataUser?.denominations).toString()
    }, [dataUser?.denominations, dataUser?.nftBalance])
    const { handleApproveRedeem, requestedApproveRedeem, pendingApproveRedeem } = useApproveRedeem(dataUser?.nftAddress, account, chainId)
    const { handleRedeem, requestedRedeem, pendingRedeem } = useRedeem(dataUser?.nftAddress, dataUser?.buys, account, chainId, onDismiss)

    const currentTime = Math.floor(Date.now() / 1000)
    const { priceAtTime } = GetPriceAtTime(dataUser?.nftAddress, currentTime, chainId)

    const totalPriceAtTime = useMemo(() => {
        return new BigNumber(priceAtTime?.priceAtTime).multipliedBy(dataUser?.nftBalance).toString()
    }, [dataUser?.nftBalance, priceAtTime?.priceAtTime])

    const totalVested = useMemo(() => {
        return dataUser?.fullVestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [dataUser?.fullVestingMap])
    const totalVestingMap = useMemo(() => {
        return dataUser?.fullVestingMap?.length
    }, [dataUser?.fullVestingMap?.length])

    return (
        <CsModal title={title} onDismiss={onDismiss} {...props}>
            <Flex width="100%" flexDirection="column" alignItems="center" mt="1.5rem">
                <TextTitle>{t("Fund Certificate Information")}</TextTitle>
            </Flex>
            <Box>
                {windowSize > 1000 ? (
                    <>
                        <WrapperItemHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Face value")}</Text>
                                <CustomTooltip title={`${t("Face value")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Yield")}</Text>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Period received")}</Text>
                                <CustomTooltip title={t("Period received/total period")}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Current yield")}</Text>
                                <CustomTooltip title={`${t("Current yield")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <CsFlexHeader>
                                <Text mr='0.2rem'>{t("Invest")}</Text>
                                <CustomTooltip title={`$t("Investment principal after receiving interest")} (${PAYMENT})`}>
                                    <div style={{ cursor: 'pointer' }}><HelpIconCcqIcon /></div>
                                </CustomTooltip>
                            </CsFlexHeader>
                            <Text width="14.2%">{t("Date due")}</Text>
                            <Text width="14.2%">{t("Remaining days")}</Text>
                        </WrapperItemHeader>
                        <Container>
                            <WrapperCard>
                                <Text width="14.2%">
                                    {formatNumberPayment(price)}
                                </Text>
                                <Text width="14.2%">{profit}</Text>
                                <Text width="14.2%">{totalVested}/{totalVestingMap}</Text>
                                <Text width="14.2%">
                                    {(dataUser?.isExpire && dataUser?.vestingMap?.length === 0) ?
                                        `0`
                                        :
                                        formatNumberPayment(currentyield)
                                    }
                                </Text>
                                <Text width="14.2%">
                                    {formatNumberPayment(totalPriceAtTime)}
                                </Text>
                                <Text width="14.2%">
                                    {convertDate(dataUser?.expireDate * 1000)}
                                </Text>
                                <Text width="14.2%">
                                    {(dataUser?.isExpire && dataUser?.vestingMap?.length === 0) ?
                                        `0`
                                        :
                                        `${dayleft} ${t(UNIT_DATE)}`
                                    }
                                </Text>
                            </WrapperCard>
                        </Container>
                    </>
                ) : (
                    <>
                        <WrapItemMobile>
                            <Flex width="100%" justifyContent="space-between" alignItems="center" flexWrap="wrap">

                                <CsFlexHeaderMb minWidth="25%" justifyContent="flex-start">
                                    <CustomTooltip title={t("Face value")}>
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text fontSize="12px" color="textSubtle">
                                                MG:
                                            </Text>
                                            <Text>
                                                &nbsp;
                                                {formatNumberPayment(price)}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </CustomTooltip>
                                </CsFlexHeaderMb>


                                <CsFlexHeaderMb minWidth="25%" justifyContent="center">
                                    <CustomTooltip title={t("Period received/total period")}>
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text>
                                                {totalVested}/{totalVestingMap}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </CustomTooltip>
                                </CsFlexHeaderMb>
                                <CsFlexHeaderMb minWidth="25%" justifyContent="center">
                                    <CustomTooltip title={t("Next yield payment date")}>
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {(dataUser?.isExpire && dataUser?.vestingMap?.length === 0) ?
                                                    convertDate(dataUser?.expireDate * 1000)
                                                    :
                                                    convertDate(interestPayDate * 1000)
                                                }
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </CustomTooltip>
                                </CsFlexHeaderMb>

                            </Flex>
                            <Flex
                                width="100%"
                                justifyContent="space-between"
                                mt="1rem"
                                alignItems="center"
                                flexWrap="wrap"
                            >
                                <CsFlexHeaderMb minWidth="30%" justifyContent="flex-start">
                                    <CustomTooltip title={t("Remaining days")}>
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {(dataUser?.isExpire && dataUser?.vestingMap?.length === 0) ?
                                                    `0`
                                                    :
                                                    `${dayleft} ${t(UNIT_DATE)}`
                                                }
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </CustomTooltip>
                                </CsFlexHeaderMb>
                                <CsFlexHeaderMb minWidth="33%" justifyContent="center">
                                    <CustomTooltip title={`${t("Investment principal after receiving interest")} (${PAYMENT})`}>
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {formatNumberPayment(totalPriceAtTime)}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </CustomTooltip>
                                </CsFlexHeaderMb>

                                <CsFlexHeaderMb width="21%" justifyContent="flex-end">
                                    <CustomTooltip title={t("Yield")}>
                                        <Flex style={{ cursor: 'pointer', gap: '2px' }}>
                                            <Text ml="4px">
                                                {profit}
                                            </Text>
                                            <HelpIconCcqIcon2 />
                                        </Flex>
                                    </CustomTooltip>
                                </CsFlexHeaderMb>

                            </Flex>
                        </WrapItemMobile>
                    </>
                )}
            </Box>

            <CsFlex>
                <CsBox>
                    <Text color="text" fontWeight="700">
                        {t("Transaction information")}
                    </Text>
                    <Flex justifyContent="space-between" width="100%" mt={["1rem", , "1.5rem"]}>
                        <Flex alignItems="center" justifyContent="space-between" width="40%">
                            <CsTextModalLabel>{t("Beneficiary wallet address")}</CsTextModalLabel>
                        </Flex>
                        <CustomLink style={{ textAlign: "right" }} onClick={handleOpenLink}>
                            <Text fontSize="14px" fontWeight="700">
                                {sAccount(account)}
                            </Text>
                            <OpenNewIconFundgo />
                        </CustomLink>
                    </Flex>
                </CsBox>

                {/* Right */}
                <CsBox>
                    <CsFlexLeft>
                        <CsTextModalLabel fontWeight="500">{t("Transaction date")}</CsTextModalLabel>
                        <Flex alignItems="center">
                            <CsTextModalValue>{convertDate(Date.now())}</CsTextModalValue>
                        </Flex>
                    </CsFlexLeft>
                    <Flex mt={["1rem", , "1.5rem"]} width="100%" alignItems="center" justifyContent="space-between">
                        <CsTextModalLabel fontWeight="500">{t("Original investment")} ({PAYMENT})</CsTextModalLabel>
                        <Flex flexDirection="column" alignItems="flex-end">
                            <Flex alignItems="center">
                                <CsTextModalValuePrice>
                                    {formatNumberPayment(origininvest)}
                                </CsTextModalValuePrice>
                                <CsTextVndc fontWeight="700" style={{ marginLeft: "5px" }}>{PAYMENT}</CsTextVndc>
                            </Flex>
                            <ExchangeToken price={formatNumberPayment(origininvest)} />
                        </Flex>
                    </Flex>
                    <Flex justifyContent="space-between" width="100%" mt={["1rem", , "1.5rem"]}>
                        <Flex alignItems="center" justifyContent="space-between" width="40%">
                            <CsTextModalLabel>{t("Actually received")}</CsTextModalLabel>
                        </Flex>
                        <CsFlexReceived justifyContent="flex-end">
                            <Flex flexDirection="column" alignItems="flex-end">
                                <Flex>
                                    <CsTextModalValuePrice>
                                        {formatNumberPayment(actuallyReceived)}
                                    </CsTextModalValuePrice>
                                    <CsTextVndc fontWeight="700" style={{ marginLeft: "5px" }}>{PAYMENT}</CsTextVndc>
                                </Flex>
                                {/* {new BigNumber(actuallyReceived).isNaN() ?
                                    ""
                                    :
                                    Number(actuallyReceived) !== 0 && <Flex mt="4px">
                                        <CsUnit style={{ color: "#8C8D8E" }}>
                                            {` (${("Equivalent")} ${formatNumberPayment(actuallyReceived)} ${t("point")} ${PAYMENT})`}
                                        </CsUnit>
                                    </Flex>
                                } */}

                            </Flex>
                        </CsFlexReceived>
                    </Flex>
                </CsBox>
            </CsFlex>

            <Flex width="100%" justifyContent="center" mt={["2rem", , "2rem"]}>
                {dataUser?.isApprovedForAll ?
                    <CsButtonConfirm
                        onClick={handleRedeem}
                        disabled={pendingRedeem || requestedRedeem || dataUser?.paused}
                        endIcon={pendingRedeem ? <AutoRenewIcon spin color="textSubtle" /> : "null"}
                    >
                        {requestedRedeem ?
                            t("Successful expiration")
                            :
                            t("Expire")
                        }
                    </CsButtonConfirm>
                    :
                    <>
                        {requestedApproveRedeem ?
                            <CsButtonConfirm
                                onClick={handleRedeem}
                                disabled={pendingRedeem || requestedRedeem || dataUser?.paused === true}
                                endIcon={pendingRedeem ? <AutoRenewIcon spin color="textSubtle" /> : "null"}
                            >
                                {requestedRedeem ?
                                    t("Successful expiration")
                                    :
                                    t("Expire")
                                }
                            </CsButtonConfirm>
                            :
                            <CsButtonConfirm
                                onClick={handleApproveRedeem}
                                disabled={pendingApproveRedeem || requestedApproveRedeem || dataUser?.paused === true}
                                endIcon={pendingApproveRedeem ? <AutoRenewIcon spin color="textSubtle" /> : ""}
                            >
                                {t("Approve")}
                            </CsButtonConfirm>
                        }
                    </>
                }
            </Flex>
            {dataUser?.paused === true &&
                <Flex width="100%" justifyContent="center" mt={["1rem", , , "1rem"]}>
                    <Text fontSize={["12px", , , "14px"]} color="red">{t("Go Global Franchise Fund is temporarily locked, please come back later!")}</Text>
                </Flex>
            }
        </CsModal>
    );
};

const CsImgCoin = styled.img`
    width: 24px;
    height: 24px;
    object-fit: contain;
    display: none;
    @media screen and (max-width: 768px) {
        display: block;
        width: 20px;
        height: 20px;
    }
`;
const TextTitle = styled(Text)`
    font-size: 34px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.text};
    @media screen and (max-width: 1000px) {
        font-size: 24px;
    }
`;

const CsBox = styled(Box)`
    width: 45%;
    @media screen and (max-width: 1000px) {
        width: 100%;
    }
`;
const CsText = styled(Text)`
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    color: ${({ theme }) => theme.colors.text};
    &:first-child {
        text-align: left;
    }
`;

const CsFlexReceived = styled(Flex)`
    width: 280px;
    border-radius: 4px;
    align-items: center;
    color: ${({ theme }) => theme.colors.text};
`;
const CsButtonConfirm = styled(Button)`
    height: 60px;
    font-size: 20px;
    font-weight: 700;
    min-width: 260px;
    transition: 0.5s;
    @media screen and (max-width: 1000px) {
        border-radius: 4px;
        min-width: 165px;
        font-size: 14px;
        height: 40px;
    }
`;
const CsLink = styled.div<{ disabled?: boolean }>`
    text-decoration: underline;
    font-size: 20px;
    font-weight: 700;
    line-height: 40px;
    color: ${({ theme }) => theme.colors.primaryBright};
    @media screen and (min-width: 601px) and (max-width: 1000px) {
        font-size: 18px;
    }
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }
    cursor: ${({ disabled }) => disabled ? "not-allowed" : "pointer"};
`;

const CsFlex = styled(Flex)`
    justify-content: space-between;
    margin-top: 3rem;
    @media screen and (max-width: 1000px) {
        flex-direction: column;
        margin-top: 2rem;
    }
`;

const WrapperCard = styled(Flex)`
    width: 100%;
    height: 48px;
    position: relative;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.background};
    &:hover {
        background: #f5f5f58a;
    }
    ${Text} {
        text-align: center;
        font-size: 14px;
    }
`;
const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

const WrapperItemHeader = styled(Flex)`
    position: relative;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
    border-radius: 20px;
    margin: 24px 0 24px 0;
    align-items: center;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
`;

const CsFlexHeaderMb = styled(Flex)`
    max-width: 100%;
    align-items: center;
    /* justify-content: center; */
`
const CsUnit = styled.span`
    font-size: 12px;
    @media screen and (max-width: 600px) {
        font-size: 10px;
    }
`
export default ReceivedAll;
