import { useTranslation } from "@pancakeswap/localization";
import { Button, Flex, IconSearchNft, Input, MinusIcon, PlusIcon } from "@pancakeswap/uikit";
import { Tooltip } from "antd";
import { TooltipTypeNft } from "components/TooltipTypeNft";
import { UNIT_DATE, formatNumberPayment } from "config";
import { useModal } from "hooks/use-modal";
import React, { useMemo } from "react";
import { Items } from "state/MarketPlace/type";
import styled from "styled-components";
import { CsItemSmall } from "views/MarketPlace/style";

interface PropsItem {
    index: number;
    buyAmount: number;
    listItem: Items;
    handleChangeAmountBuy: (amount, id, nftAddress, buys) => void;
    handleCancel: (id) => void;
}

const ItemBuy: React.FC<PropsItem> = ({ buyAmount, listItem, handleChangeAmountBuy, handleCancel }) => {
    const { t } = useTranslation();
    const { toggleModal, ModalTypeEnum } = useModal();
    const handleChangeInput = (e) => {
        const { value } = e.target;
        if (/^\d+$/.test(value) || value === "") {
            let convertNumber = value;
            if (Number(convertNumber) < 1) {
                convertNumber = '';
            } else if (convertNumber > Number(listItem?.amount)) {
                convertNumber = listItem?.amount;
            }
            handleChangeAmountBuy(Number(convertNumber), listItem.id, listItem.nftAddress, listItem.buys);
        }
    };

    const totalVested = useMemo(() => {
        return listItem?.vestingMap?.filter((itemVest) => itemVest?.isVested === !false)?.length
    }, [listItem?.vestingMap])

    const totalVestingMap = useMemo(() => {
        return listItem?.vestingMap?.length
    }, [listItem?.vestingMap])

    return (
        <Container>
            <WrapperCard>
                <CsItemSmall>
                    <p
                        aria-hidden="true"
                        className="absolute left-0 bottom-0 cursor-pointer"
                        onClick={() => {
                            toggleModal({
                                type: ModalTypeEnum.MODAL_SEARCH_CONTRACT,
                                title: "",
                                data: {
                                    contract: listItem.nftAddress.toLowerCase()
                                }
                            })
                        }}
                    >
                        <IconSearchNft />
                    </p>
                    {formatNumberPayment(listItem?.amount)}
                </CsItemSmall>
                <CsItemSmall className="line">
                    {formatNumberPayment(listItem?.price)}
                </CsItemSmall>
                <CsItemSmall>{totalVested}/{totalVestingMap}</CsItemSmall>
                <CsItemSmall>{`${listItem?.interVestTerm} ${t("months")}`}</CsItemSmall>
                <CsItemSmall style={{ display: "flex", justifyContent: "center" }}>
                    {listItem?.paydayNextProfit}
                </CsItemSmall>
                <CsItemSmall>{`${listItem.dayDiff} ${t(UNIT_DATE)}`}</CsItemSmall>
                <CsItemSmall className="line">{listItem?.interVestTermRate}%</CsItemSmall>
                <CsItemSmall>
                    {formatNumberPayment(listItem?.unitPrice)}
                </CsItemSmall>
                <CsItemSmall>
                    <WrapCount>
                        <ButtonQuanlity
                            disabled={Number(buyAmount) <= 1}
                            onClick={() => handleChangeAmountBuy(Number(buyAmount) - 1, listItem.id, listItem.nftAddress, listItem.buys)}
                        >
                            <MinusIcon />
                        </ButtonQuanlity>
                        <CustomInput
                            type="text"
                            scale="lg"
                            inputMode="numeric"
                            value={buyAmount}
                            placeholder="0"
                            onChange={handleChangeInput}
                        />
                        <ButtonQuanlity
                            onClick={() => handleChangeAmountBuy(Number(buyAmount) + 1, listItem.id, listItem.nftAddress, listItem.buys)}
                            disabled={Number(buyAmount) >= Number(listItem?.amount)}
                        >
                            <PlusIcon />
                        </ButtonQuanlity>
                    </WrapCount>
                </CsItemSmall>
                <CsItemSmall>
                    <CsButtonCancel onClick={() => handleCancel(listItem.id)}>{t("Cancel")}</CsButtonCancel>
                </CsItemSmall>
            </WrapperCard>
        </Container>
    );
};

export default ItemBuy;

const CustomInput = styled(Input)`
    background: none;
    color: ${({ theme }) => theme.colors.white};
    padding: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    border: none;
    box-shadow: none;
    font-size: 16px;
    border-radius: 0;
    &::placeholder {
        color: ${({ theme }) => theme.colors.white};
    }
    @media screen and (max-width: 1000px) {
        font-size: 14px;
    }
`;

const ButtonQuanlity = styled(Button)`
    border: 1px solid transparent;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 4px;
    box-shadow: none;
    padding: 4px;
    height: 24px;
    width: 24px;
`;

const WrapCount = styled(Flex)`
    align-items: center;
    background: ${({ theme }) => theme.colors.success3};
    border-radius: 4px;
    width: 90px;
    height: 34px;
    padding: 4px;
`;

const CsButtonCancel = styled(Button)`
    border: 1px solid ${({ theme }) => theme.colors.red};
    color: ${({ theme }) => theme.colors.red};
    background: transparent;
    border-radius: 4px;
    min-width: 90px;
    height: 40px;
`;

const Container = styled.div<{ isHaving?: boolean; background?: string }>`
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center top;
`;

const WrapperCard = styled(Flex)`
    width: 100%;
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    background: rgba(255, 255, 255, 0.62);
    border-bottom: 1px solid rgba(197, 214, 232, 0.30);
    &:hover{
        background: rgba(197, 214, 232, 0.30);
    }
    ${CsItemSmall}{
        align-items: center;
        padding: 18px 0;
    }
`;
