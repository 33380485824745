
export function renderStatus(status) {
    if (status === 1) {
        return "Success"
    } if (status === 2) {
        return "Waiting"
    } if (status === 3) {
        return "Cancelled"
    }
    return "Waiting"
}