import { useTranslation } from "@pancakeswap/localization";
import { Flex, HelpIconCcqIcon, Text } from "@pancakeswap/uikit";
import GetWindowSize from "@pancakeswap/uikit/src/util/getWindowSize";
import { CustomTooltip } from "components/CustomTooltip";
import PageFullWidth from "components/Layout/PageFullWidth";
import SubHeader from "components/Layout/SubHeader";
import { PAYMENT, PRICE_CCQ } from "config";
import useActiveWeb3React from "hooks/useActiveWeb3React";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "state";
import { GetDataMarketplace, UseCoreMarketplace } from "state/MarketPlace";
import { fetchCartItems } from "state/MarketPlace/actions";
import styled from "styled-components";
import { renderMarketplaceAddress, renderToken } from "utils/renderAddress";
import Cart from "./Cart";
import Ccq from "./Ccq";
import FooterTabs from "./FooterTabs";
import { Footer } from "./components/Footer";
import { FundMember } from "./components/FundMember";
import HeaderSort from "./components/HeaderSort";
import { CsItem, CsItemSmall } from "./style";

const MarketPlace = () => {
    const { t } = useTranslation()

    const windowSize = GetWindowSize();
    const { chainId, account } = useActiveWeb3React();
    const marketplaceAddress = renderMarketplaceAddress(chainId);
    const token = renderToken(chainId);
    UseCoreMarketplace(marketplaceAddress, token.address, chainId, account);
    const { denominations, paymentPeriod, listCart, listItem, dataUser, nextInterestPaymentDate, currentYield, searchFundMember, isShowCCQ } = GetDataMarketplace();
    const sortUnitPrice = [...listItem]?.sort((a, b) => b?.unitPrice - a?.unitPrice)
    const sortByDenominations = useMemo(() => {
        const data = [...sortUnitPrice];
        switch (denominations) {
            case PRICE_CCQ:
                return data.filter((item) => Number(item.price) === PRICE_CCQ);
            default:
                return data;
        }
    }, [denominations, sortUnitPrice]);
    const sortByPaymentPeriod = useMemo(() => {
        const data = [...sortByDenominations];
        switch (paymentPeriod) {
            case 3: // 3 month
                return data.filter((item) => Number(item?.interVestTerm) === 3);
            case 6: //  6 month
                return data.filter((item) => Number(item?.interVestTerm) === 6);
            default:
                return data;
        }
    }, [paymentPeriod, sortByDenominations]);

    const sortByNextInterestPaymentDate = useMemo(() => {
        const data = [...sortByPaymentPeriod];
        switch (nextInterestPaymentDate) {
            case 4:
                return data.filter((item) => item?.dayDiff >= 60);
            case 3:
                return data.filter(
                    (item) =>
                        item?.dayDiff >= 30 &&
                        item?.dayDiff <= 60
                );
            case 2:
                return data.filter(
                    (item) =>
                        item?.dayDiff >= 10 &&
                        item?.dayDiff <= 30
                );
            case 1:
                return data.filter((item) => item?.dayDiff <= 10);
            default:
                return data;
        }
    }, [nextInterestPaymentDate, sortByPaymentPeriod]);

    const sortByCurrentYield = useMemo(() => {
        const data = [...sortByNextInterestPaymentDate];
        switch (currentYield) {
            case 9.2: // 9.2% => 3 months
                return data.filter((item) => Number(item?.interVestTermRate) === 9.2);
            case 8: // 8.2% =>  6 months
                return data.filter((item) => Number(item?.interVestTermRate) === 8);
            default:
                return data;
        }
    }, [currentYield, sortByNextInterestPaymentDate]);
    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        dispatch(fetchCartItems({ listCart: [] }))
    }, [dispatch, searchFundMember])

    const searchDataFundMember = useMemo(() => {
        if (searchFundMember && sortByCurrentYield.length) {
            return sortByCurrentYield.filter((item) => item.publisher.toLowerCase() === searchFundMember.toLowerCase());
        }
        return sortByCurrentYield;
    }, [sortByCurrentYield, searchFundMember]);

    return (
        <CsPageFullWidth>
            <SubHeader listItem={searchDataFundMember} dataUser={dataUser} account={account} />
            <Flex maxWidth="1280px" width="100%" flexDirection="column" mt={["1rem", , , "4rem"]} height="auto">
                <FundMember />
                <HeaderSort />
                {windowSize > 1000 && isShowCCQ && (
                    <>
                        <WrapperTable>
                            <TableMainTitle>
                                <Text className="line" width="20%" bold fontSize="20px" textAlign="center">{t("Fund Certificate information")}</Text>
                                <Text className="line" width="50.5%" bold fontSize="20px">{t("Yield")}</Text>
                                <Text width="30.5%" bold fontSize="20px">{t("Transaction information")}</Text>
                            </TableMainTitle>
                            <WrapperItemCCQ>
                                <CsItemSmall>
                                    <Text mr='0.3rem'>{t("Quantity")}</Text>
                                    <CustomTooltip title={t("Quantity")}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>{t("Face value")}</Text>
                                    <CustomTooltip title={`${t("Face value")} (${PAYMENT})`}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>{t("Period received")}</Text>
                                    <CustomTooltip title={t("Period received/total period")}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>{t("Payment period")}</Text>
                                    <CustomTooltip title={t("Yield payment period")}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>{t("Payment date")}</Text>
                                    <CustomTooltip title={t("Next yield payment date")}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>{t("Remaining days")}</Text>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>{t("Remaining yield")}</Text>
                                    <CustomTooltip title={t("Remaining day yield")}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                                <CsItemSmall >
                                    <Text mr='0.3rem'>{t("Unit price")}</Text>
                                    <CustomTooltip title={`${t("Unit price")} (${PAYMENT})`}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                                <CsItemSmall>
                                    <Text ml="4px" mr='0.3rem'>{t("Purchase")}</Text>
                                    <CustomTooltip title={t("Purchase Quantity")}>
                                        <div style={{ cursor: 'pointer' }}> <HelpIconCcqIcon /> </div>
                                    </CustomTooltip>
                                </CsItemSmall>
                            </WrapperItemCCQ>
                        </WrapperTable>
                    </>
                )}
                <Cart listItem={listItem} windowSize={windowSize} listCart={listCart} />
                {
                    isShowCCQ &&
                    <>
                        {
                            searchDataFundMember.length ?
                                <Ccq listItem={searchDataFundMember} windowSize={windowSize} listCart={listCart} />
                                : <Flex justifyContent="center">
                                    <Text fontSize={16} fontWeight={600}>Không có dữ liệu</Text>
                                </Flex>
                        }
                    </>
                }
            </Flex>
            <FooterTabs />
            <Footer />
        </CsPageFullWidth>
    );
};
export default MarketPlace;

const WrapperTable = styled.div`
    padding-top: 4rem;
    padding: 0px 6px 0px 6px;
`;
const TableMainTitle = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    background: rgba(212, 238, 255, 0.34);
    ${Text}{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 70px;
    }
    .line{
        &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 2px;
        height: 100%;
        background: ${({ theme }) => theme.colors.border2};
        }
    }

`;
export const WrapperItemCCQ = styled(Flex)`
    position: relative;
    flex-direction: row;
    align-items: center;
    height: 100%;
    border-radius: 20px;
    margin: 36px 0 26px 0;
    display: grid;
    grid-template-columns: repeat(10, minmax(0, 1fr));
    place-items: center;
    ${Text} {
        text-align: center;
        font-weight: 700;
        font-size: 13px;
        line-height: 19px;
        letter-spacing: 0.04em;
        color: ${({ theme }) => theme.colors.textSubtle};
    }
    @media screen and (max-width: 600px) {
        padding: 2rem 25px;
    }
    ${CsItem}{
        line-height: 21px;
        color: ${({ theme }) => theme.colors.textSubtle};
    }

`;

export const CsPageFullWidth = styled(PageFullWidth)`
`;
